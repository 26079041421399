import { useState, useEffect } from 'react';

import { Radio, Box, Group, Text } from '@mantine/core';

import { IconPaperclip } from '@tabler/icons-react';

import { Button } from '../../../../shared/components/UI/Button/Button';

import type { ProposalVersion } from '../../../../shared/entities/BidResponse';

type VersionListProps = {
  selectedProposalVersionId: number;
  proposalVersions: ProposalVersion[];
  setSelectedProposalVersion: (proposalVersionId: number) => void;
};
export const VersionList = ({
  proposalVersions,
  selectedProposalVersionId,
  setSelectedProposalVersion,
}: VersionListProps) => {
  const [value, setValue] = useState<string>(String(selectedProposalVersionId));

  useEffect(() => {
    setValue(String(selectedProposalVersionId));
  }, [selectedProposalVersionId]);

  // tricks to avoid flashing a new tab by navigating to the fileUrl
  const handleDownload = (fileURL: string) => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = '';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSelectVersion = (versionId: number) => {
    setValue(String(versionId));
    setSelectedProposalVersion(versionId);
  };

  return (
    <Radio.Group w="100%" value={value} onChange={setValue} name="selectVersion">
      {proposalVersions.map(version => {
        return (
          <Box key={version.id} onClick={() => handleSelectVersion(version.id)}>
            <Radio
              value={String(version.id)}
              label={
                <Group noWrap spacing="04">
                  <Text variant="sm" fw="500" c="gray.6">
                    V{version.version}
                  </Text>
                  {version.proposalDocs.map(doc => (
                    <Group
                      key={doc.id}
                      noWrap
                      spacing="01"
                      p="02"
                      c="gray.4"
                      bg="white"
                      sx={theme => ({
                        border: `1px solid ${theme.colors.gray[1]}`,
                        borderRadius: theme.radius.md,
                      })}
                    >
                      <IconPaperclip />
                      <Text variant="sm" fw="500" c="gray.5" maw="180px" truncate>
                        {doc.originalName}
                      </Text>
                      <Button variant="white" size="xs" onClick={() => handleDownload(doc.fileURL)}>
                        Télécharger
                      </Button>
                    </Group>
                  ))}
                </Group>
              }
              p="02"
              sx={theme => ({
                borderRadius: theme.radius.md,
                ':hover': {
                  background: theme.colors.gray[0],
                  cursor: 'pointer',
                },
              })}
              styles={{
                inner: {
                  alignSelf: 'center',
                },
              }}
            />
          </Box>
        );
      })}
    </Radio.Group>
  );
};
