import { Group, Stack, Text } from '@mantine/core';

import Markdown from 'react-markdown';

import { SourcesContent } from '../../../../shared/components/SourcesContent';
import type { DataPointSource } from '../../../../shared/entities/DataPointSource';
import type { AiRelevanceFeedbackStatus } from '../../../../shared/entities/InstantAnalysisAnswer';
import { AiRelevanceFeedback } from '../AiRelevanceFeedback';
import { AnswerLeftBorder } from './AnswerLeftBorder';

type LlmAnswerProps = {
  answer: string;
  feedback: AiRelevanceFeedbackStatus | null;
  handleAiRelevanceFeedback: (feedback: AiRelevanceFeedbackStatus) => void;
  sources: DataPointSource[];
};
export const LlmAnswer = ({
  answer,
  feedback,
  handleAiRelevanceFeedback,
  sources,
}: LlmAnswerProps) => {
  return (
    <Stack spacing="md">
      <Stack spacing="02">
        <Text variant="xs" fw={500} c="gray.6">
          Réponse
        </Text>
        <Group spacing="04" noWrap h="100%" sx={{ alignItems: 'stretch' }}>
          <AnswerLeftBorder />
          <Stack spacing="02">
            <Text variant="md" fw={400} c="gray.8">
              <Markdown>{answer}</Markdown>
            </Text>
            <AiRelevanceFeedback feedback={feedback} handleFeedback={handleAiRelevanceFeedback} />
          </Stack>
        </Group>
      </Stack>
      <SourcesContent sources={sources} />
    </Stack>
  );
};
