import { Stack, Text, Timeline } from '@mantine/core';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';

import { formatDate } from '../../../../shared/utils/dates';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function TimelineCard() {
  const { tender } = useNoticeContext();
  const timeLineLabels: TimeLineLabelProps[] = [
    { label: 'Date de publication', date: tender.publicationDate },
    { label: "Limite de remise de l'offre", date: tender.responseDeadline },
  ];

  const today = new Date();
  const numberOfDaysBeforeDeadline =
    tender.responseDeadline && new Date(tender.responseDeadline).getTime() - today.getTime();
  let deadlineTitle = '';
  if (numberOfDaysBeforeDeadline && numberOfDaysBeforeDeadline > 0) {
    const numberOfDays = Math.floor(numberOfDaysBeforeDeadline / (1000 * 3600 * 24));
    deadlineTitle = `, J-${numberOfDays} avant dépot`;
  }
  return (
    <BrandedCard title={`Calendrier` + deadlineTitle} w="100%">
      <Stack spacing="05">
        <Timeline lineWidth={2} color="green">
          {timeLineLabels.map(data => (
            <Timeline.Item key={data.label}>
              <TimeLineLabel label={data.label} date={data.date} key={data.label} />
            </Timeline.Item>
          ))}
        </Timeline>
      </Stack>
    </BrandedCard>
  );
}

type TimeLineLabelProps = {
  label: string;
  date?: string;
};
const TimeLineLabel = ({ label, date }: TimeLineLabelProps) => {
  return (
    <Stack spacing={5}>
      <Text variant="sm" fw="500" color="dark.9">
        {label}
      </Text>
      <Text variant="xs" color="dark.9">
        {formatDate(date)}
      </Text>
    </Stack>
  );
};
