import { Stack, Box } from '@mantine/core';

import type { CriteriaWithChildren } from '../../utils/createCriteriaTree';
import { CriteriaWithMarker } from './CriteriaWithMarker';

type CriteriaTreeProps = {
  criteria: CriteriaWithChildren[];
};
export const CriteriaTree = ({ criteria }: CriteriaTreeProps) => {
  return (
    <Stack spacing="02">
      {criteria.map(c => {
        const color = c.color?.color;
        if (!color) {
          return null;
        }

        return (
          <Box key={c.id}>
            <CriteriaWithMarker title={c.title} weight={c.weight} color={color} shade={5} />
            {c.subCriteria.map(sub => (
              <CriteriaWithMarker
                key={sub.id}
                title={sub.title}
                weight={sub.weight}
                color={color}
                shade={sub.color?.shade}
                p={'03'}
              />
            ))}
          </Box>
        );
      })}
    </Stack>
  );
};
