import { notifications } from '@mantine/notifications';

import { IconCheck, IconX } from '@tabler/icons-react';

import type { DecisionRenewalStatus } from '../../entities/Interaction';
import { DecisionStatus } from '../../entities/Interaction';

export const displayDecisionNotification = (status: DecisionStatus | DecisionRenewalStatus) => {
  switch (status) {
    case DecisionStatus.TO_ANALYZE:
      notifications.show({
        title: 'Opportunité retenue',
        message: 'Ajoutée au Pipeline',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.REJECTED:
      notifications.show({
        title: 'Opportunité rejetée',
        message: 'Ajoutée aux Opportunités rejetés',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconX size="18" />,
      });
      break;
    case DecisionStatus.NOGO:
      notifications.show({
        title: 'Opportunité rejetée',
        message: 'Marquée comme No go',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconX size="18" />,
      });
      break;
    case DecisionStatus.GO:
      notifications.show({
        title: 'Opportunité retenue',
        message: 'Marquée comme Go',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.ANSWERED:
      notifications.show({
        title: 'Réponse envoyée',
        message: 'Marquée comme Répondu',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.WIN:
      notifications.show({
        title: 'Opportunité gagnée',
        message: 'Marquée comme Gagnée',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.LOSS:
      notifications.show({
        title: 'Opportunité perdue',
        message: 'Marquée comme perdue',
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconCheck size="18" />,
      });
      break;
  }
};
