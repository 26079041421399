import type { RichTextJson } from '../../../entities/Comment';
import { magellanClient } from '../../../infra/axios';
import type { DeleteCommentResponseDTO } from './dto';

export async function createThread(
  tenderId: number,
  content: RichTextJson,
  iaAnswerId?: number,
): Promise<Comment> {
  const response = await magellanClient.post<Comment>(`/comments`, {
    tenderId,
    content,
    instantAnalysisAnswerId: iaAnswerId,
  });

  return response.data;
}

export async function createComment(
  tenderId: number,
  threadId: number,
  content: RichTextJson,
  iaAnswerId?: number,
): Promise<Comment> {
  const response = await magellanClient.post<Comment>(`/comments`, {
    tenderId,
    threadId,
    content,
    instantAnalysisAnswerId: iaAnswerId,
  });

  return response.data;
}
export async function deleteComment(commentId: number): Promise<DeleteCommentResponseDTO> {
  const response = await magellanClient.delete<DeleteCommentResponseDTO>(`/comments/${commentId}`);

  return response.data;
}

export async function updateComment(commentId: number, content: RichTextJson): Promise<Comment> {
  const response = await magellanClient.put<Comment>(`/comments/${commentId}`, {
    content,
  });

  return response.data;
}
