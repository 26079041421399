import { useNavigate } from 'react-router-dom';

import { Text, Group, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconArrowLeft } from '@tabler/icons-react';

type BackNavButtonProps = {
  isFormDirty: boolean;
  isFormValid: boolean;
  onSubmit: () => void;
};

export const BackNavButton = ({ isFormDirty, isFormValid, onSubmit }: BackNavButtonProps) => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const openModal = () =>
    modals.openContextModal({
      modal: 'streamDiscard',
      size: 'lg',
      innerProps: {
        isFormValid,
        onDiscard: () => navigate(-1),
        onSubmit: () => {
          onSubmit();
          navigate(-1);
        },
      },
    });

  return (
    <Group
      noWrap
      spacing="xxs"
      onClick={() => {
        if (isFormDirty) {
          openModal();
        } else {
          navigate(-1);
        }
      }}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <IconArrowLeft size="16px" color={theme.colors.gray[9]} stroke="2px" />
      <Text variant="sm" fw={500} color="gray.9">
        Retour
      </Text>
    </Group>
  );
};
