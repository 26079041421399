import { Stack, Title, Text, TextInput, Group } from '@mantine/core';
import { useForm } from '@mantine/form';
import type { ContextModalProps } from '@mantine/modals';

import { Button } from '../UI/Button/Button';

import { updateQuestionsSet } from '../../api/magellan/instantAnalysis/questionSets';
import { createQuestion, deleteQuestion } from '../../api/magellan/instantAnalysis/questions';
import type { InstantAnalysisQuestion } from '../../entities/InstantAnalysisQuestion';
import type { QuestionsSet } from '../../entities/QuestionsSet';
import { QuestionsInfiniteInput } from './questionsSetsInputs/QuestionInfiniteInput';

type UpdateQuestionSetFormValues = {
  title: string;
  questions: InstantAnalysisQuestion[];
};

type UpdateQuestionsSetsModalProps = {
  questionsSet: QuestionsSet;
  onSubmit: (questionSet: QuestionsSet) => void;
};

export function UpdateQuestionsSetsModal({
  id,
  context,
  innerProps,
}: ContextModalProps<UpdateQuestionsSetsModalProps>) {
  const { questionsSet, onSubmit } = innerProps;
  const form = useForm<UpdateQuestionSetFormValues>({
    initialValues: {
      title: questionsSet.title,
      questions: questionsSet.questions,
    },
    validate: {
      title: value => (value ? undefined : 'Veuillez saisir un nom'),
      questions: value => (value.length > 0 ? undefined : 'Veuillez saisir au moins une question'),
    },
  });

  const handleSubmit = async (
    values: UpdateQuestionSetFormValues,
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    // stoping the propagation of the event to avoid triggering a submit on the parent form.
    event.stopPropagation();
    const updatedQuestionsSet = await updateQuestionsSet(values.title, questionsSet.id);
    onSubmit(updatedQuestionsSet);
    context.closeModal(id);
  };

  const onAddQuestion = async (question: string, questionsSetId: number) => {
    const newQuestion = await createQuestion(question, questionsSetId);
    const newQuestions = [...form.values.questions, newQuestion];
    form.setFieldValue('questions', newQuestions);
    onSubmit({ ...questionsSet, questions: newQuestions });
  };

  const onDeleteQuestion = async (question: string) => {
    const questionToDelete = form.values.questions.find(q => q.question === question);
    if (questionToDelete) {
      await deleteQuestion(questionToDelete.id);
      const newQuestions = form.values.questions.filter(q => q.question !== question);
      form.setFieldValue('questions', newQuestions);
      onSubmit({ ...questionsSet, questions: newQuestions });
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack py={16} px={21} spacing="md">
        <Stack spacing={4}>
          <Title order={4}>Mettre à jour le jeu de question</Title>
          <Text variant="sm" fw="400" c="gray.6">
            Posez les questions qui vous aideront à analyser l'appel d'offres.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <Text variant="sm" fw={700}>
            Nom du jeu de question
          </Text>
          <TextInput
            maw={200}
            placeholder="Audit énergétique..."
            color="gray.3"
            {...form.getInputProps('title')}
            styles={theme => ({
              input: {
                padding: theme.spacing.sm,
                paddingTop: theme.spacing.lg,
                paddingBottom: theme.spacing.lg,
                '::placeholder': {
                  color: theme.colors.gray[3],
                },
                color: theme.colors.gray[9],
                border: `1px solid ${theme.colors.gray[1]}`,
                borderRadius: theme.radius.md,
              },
            })}
          />
        </Stack>
        <QuestionsInfiniteInput
          {...form.getInputProps('questions')}
          onAddQuestion={question => onAddQuestion(question, questionsSet.id)}
          onDeleteQuestion={onDeleteQuestion}
        />
        <Group position="right" mt="lg">
          <Button
            variant="subtle"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            Fermer
          </Button>
          <Button type="submit">Mettre à jour le jeu de questions</Button>
        </Group>
      </Stack>
    </form>
  );
}
