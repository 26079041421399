import { Badge, Text } from '@mantine/core';

import type { DecisionRenewalStatus } from '../../../entities/Interaction';
import { DecisionStatus } from '../../../entities/Interaction';

interface DecisionBadgeProps {
  decision?: DecisionStatus | DecisionRenewalStatus;
}

export default function DecisionBadge({ decision }: DecisionBadgeProps) {
  if (!decision) {
    return null;
  }

  let color: string;
  let label: string;

  switch (decision) {
    case DecisionStatus.REJECTED: {
      color = 'red.2';
      label = 'Rejeté';
      break;
    }
    case DecisionStatus.TO_ANALYZE: {
      color = 'primary.2';
      label = 'À analyser';
      break;
    }

    case DecisionStatus.GO: {
      color = 'teal.2';
      label = 'Go';
      break;
    }
    case DecisionStatus.NOGO: {
      color = 'red.2';
      label = 'No go';
      break;
    }

    case DecisionStatus.ANSWERED: {
      color = 'primary.2';
      label = 'Répondu';
      break;
    }
    case DecisionStatus.WIN: {
      color = 'teal.2';
      label = 'Gagné';
      break;
    }
    case DecisionStatus.LOSS: {
      color = 'red.2';
      label = 'Perdu';
      break;
    }
    case DecisionStatus.PENDING: {
      color = 'gray.2';
      label = 'En attente';
      break;
    }
    default: {
      throw new Error(`Unknown status: ${decision}`);
    }
  }

  return (
    <Badge size="md" radius="lg" p="2px 10px 2px 10px" bg={color} w="fit-content">
      <Text variant="xs" fw="600" color="gray.9" sx={{ textTransform: 'capitalize' }}>
        {label}
      </Text>
    </Badge>
  );
}
