import { useEffect, useState } from 'react';

import { Badge, Box, Group, Image, Paper, Stack, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';

import {
  IconCheck,
  IconExclamationCircle,
  IconExternalLink,
  IconFileZip,
  IconPlayerPlayFilled,
  IconPlus,
} from '@tabler/icons-react';

import { Button } from '../../../shared/components/UI/Button/Button';

import { getAllQuestionsSets } from '../../../shared/api/magellan/instantAnalysis/questionSets';
import type { NotAnalyzableReason } from '../../../shared/api/magellan/tender/dto';
import { DceRequestStatus } from '../../../shared/entities/DceRequestStatus';
import type { QuestionsSet } from '../../../shared/entities/QuestionsSet';
import { TenderStatus } from '../../../shared/entities/Tender';
import InstantAnalysisImg from '../assets/instant-analysis-preview.svg';
import { SelectQuestionsSetsModalContainer } from './SelectQuestionsSetsModalContainer';

const userGuideUrl =
  'https://tengo-cc.notion.site/Guide-d-utilisation-de-l-Analyse-Instantan-e-83d4952a49b04f5e8e89bb87f03d631e';

type TriggerCardProps = {
  tenderStatus?: TenderStatus;
  triggerInstantAnalysis: (questionsSetsIds: number[]) => Promise<void>;
  isAnalysable?: { isAnalysable: boolean; error?: NotAnalyzableReason };
  dceRequestStatus: DceRequestStatus;
  sendDCERequest: () => void;
};

export default function TriggerCard({ isAnalysable, ...contentProps }: TriggerCardProps) {
  const [questionsSets, setQuestionsSets] = useState<QuestionsSet[]>([]);

  const isSmallScreen = useMediaQuery('(max-width: 1440px)');

  useEffect(() => {
    const fetchQuestionsSets = async () => {
      const questionsSets = await getAllQuestionsSets();
      setQuestionsSets(questionsSets);
    };

    fetchQuestionsSets();
  }, []);

  return (
    <Stack spacing="05">
      {isAnalysable?.error && <WarningCard error={isAnalysable.error} />}
      <Box
        p="05"
        mb="05"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.md,
          boxShadow: theme.shadows.xs,
        })}
      >
        <Group
          p="06"
          pr="10"
          spacing="10"
          noWrap={!isSmallScreen}
          mx={isSmallScreen ? 'auto' : 0}
          sx={theme => ({
            borderRadius: theme.radius.md,
            background: theme.other.gradients.backgroundMedium,
            justifyContent: isSmallScreen ? 'center' : 'start',
          })}
        >
          <Image src={InstantAnalysisImg} width={540} height={230} radius="lg" />
          <Content
            {...contentProps}
            questionsSets={questionsSets}
            setQuestionsSets={setQuestionsSets}
            isAnalysable={isAnalysable}
          />
        </Group>
      </Box>
    </Stack>
  );
}

type ContentProps = {
  isAnalysable?: { isAnalysable: boolean; error?: NotAnalyzableReason };
  tenderStatus?: TenderStatus;
  dceRequestStatus: DceRequestStatus;
  sendDCERequest: () => void;
  questionsSets: QuestionsSet[];
  setQuestionsSets: (questionsSets: QuestionsSet[]) => void;
  triggerInstantAnalysis: (questionsSetsIds: number[]) => Promise<void>;
};

const Content = ({
  isAnalysable,
  tenderStatus,
  dceRequestStatus,
  questionsSets,
  sendDCERequest,
  setQuestionsSets,
  triggerInstantAnalysis,
}: ContentProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Stack spacing="04">
      <SelectQuestionsSetsModalContainer
        questionsSets={questionsSets}
        setQuestionsSets={setQuestionsSets}
        triggerInstantAnalysis={triggerInstantAnalysis}
        opened={opened}
        close={close}
      />
      <Stack spacing="02" maw="600px">
        <Title order={4}>Décidez plus vite avec l'analyse instantanée</Title>
        <Text variant="sm" fw={400} c="gray.6">
          Tengo utilise l'intelligence artificielle pour extraire les réponses à vos questions des
          documents d'appel d'offres. Gagnez du temps et prenez des décisions aiguillées avec une
          longueur d'avance sur la concurrence.
        </Text>
      </Stack>
      {!isAnalysable?.isAnalysable && isAnalysable?.error !== 'NO_SUPPORTED_DOCUMENTS' ? (
        <RequestDocuments
          tenderStatus={tenderStatus}
          dceRequestStatus={dceRequestStatus}
          requestDocuments={sendDCERequest}
        />
      ) : (
        <TriggerAnalysisButtonGroup
          questionsSets={questionsSets}
          isAnalysable={isAnalysable}
          onClick={open}
        />
      )}
    </Stack>
  );
};
type RequestDocumentsProps = {
  tenderStatus?: TenderStatus;
  dceRequestStatus: DceRequestStatus;
  requestDocuments: () => void;
};

const RequestDocuments = ({
  tenderStatus,
  dceRequestStatus,
  requestDocuments,
}: RequestDocumentsProps) => {
  const closedTenderComponent = (
    <Text variant="sm" fw={400} c="gray.4">
      Cet appel d'offre étant
      <Badge size="md" radius="lg" p="2px 10px 2px 10px" bg="blue.1" ml="xxs">
        <Text variant="xs" fw="600" color="dark.9">
          Fermé
        </Text>
      </Badge>
      , les documents ont été retirés de la plateforme Profil Acheteur et ne sont désormais plus
      accessibles.
    </Text>
  );

  const requestComponent = (
    <Stack spacing="04">
      <Group noWrap spacing="04" c="gray.6">
        <IconExclamationCircle size={32} stroke={1} />
        <Stack spacing={0}>
          <Text variant="sm" fw="500" c="gray.9">
            Nous n'avons pas pu récupérer les documents nécessaires à l'analyse.
          </Text>
          <Text variant="sm" fw="400" c="gray.9">
            Cependant, vous pouvez demander à Tengo de les chercher pour vous.
          </Text>
        </Stack>
      </Group>
      <Group spacing="02">
        <Button
          disabled={dceRequestStatus === DceRequestStatus.IN_SEARCH}
          variant="filled"
          maw="250px"
          radius="md"
          leftIcon={<IconFileZip size={16} />}
          onClick={requestDocuments}
        >
          Demander les documents
        </Button>
        <Button
          variant="subtle"
          rightIcon={<IconExternalLink size={16} stroke={1.5} />}
          component="a"
          href={userGuideUrl}
          target="_blank"
        >
          Découvrez notre guide utilisateur
        </Button>
      </Group>
    </Stack>
  );

  const doccumentComponent =
    tenderStatus === TenderStatus.CLOSED ? closedTenderComponent : requestComponent;

  const searchingComponent = (
    <Stack spacing="xl">
      <Text variant="sm" fw="500" color="dark.9">
        Nous avons bien reçu votre demande.
        <br />
        Nous vous informerons dès que nous aurons récupéré tous les documents nécessaires pour
        l'analyse de cet appel d'offres.
      </Text>
      <Button disabled variant="filled" maw="250px" radius="md" rightIcon={<IconCheck size={16} />}>
        Documents demandés
      </Button>
    </Stack>
  );
  return dceRequestStatus === DceRequestStatus.IN_SEARCH ? searchingComponent : doccumentComponent;
};

type AnalysisButtonGroupProps = {
  questionsSets: QuestionsSet[];
  isAnalysable?: { isAnalysable: boolean; error?: NotAnalyzableReason };
  onClick: () => void;
};

const TriggerAnalysisButtonGroup = ({
  questionsSets,
  isAnalysable,
  onClick: openModal,
}: AnalysisButtonGroupProps) => {
  return (
    <Group spacing="02">
      <Button
        leftIcon={
          !questionsSets.length ? <IconPlus size={16} /> : <IconPlayerPlayFilled size={16} />
        }
        disabled={!isAnalysable?.isAnalysable}
        onClick={openModal}
      >
        {questionsSets.length > 0 ? "Lancer l'analyse" : 'Créer un jeu de questions'}
      </Button>
      <Button
        variant="subtle"
        rightIcon={<IconExternalLink size={16} stroke={1.5} />}
        component="a"
        href={userGuideUrl}
        target="_blank"
      >
        Découvrez notre guide utilisateur
      </Button>
    </Group>
  );
};

const WarningCard = ({ error }: { error: NotAnalyzableReason }) => {
  let title = '';
  let message = '';
  if (error === 'NO_DOCUMENTS') {
    title = 'Absence de documents';
    message =
      "Analyse impossible faute de documents. N'hésitez pas à faire une demande de documents.";
  } else if (error === 'NO_SUPPORTED_DOCUMENTS') {
    title = 'Absence de documents supportés';
    message =
      "Nous n'analysons que les documents PDF et docx pour le moment, et cet appel d'offres n'en contient pas.";
  } else {
    throw new Error('Unknown analysis error');
  }

  return (
    <Paper p="16px 12px" radius={8} bg="red.0" shadow="md">
      <Group noWrap align="flex-start" c="red.9" spacing="md">
        <IconExclamationCircle size={16} style={{ marginTop: '2px', marginLeft: '2px' }} />
        <Stack spacing="xxs">
          <Text variant="sm" fw="700">
            {title}
          </Text>
          <Text variant="sm" fw="400" color="dark.9">
            {message}
          </Text>
        </Stack>
      </Group>
    </Paper>
  );
};
