import { useQuery } from '@tanstack/react-query';

import type { TenderWithListData } from '../../../app/pages/Pipeline.page';
import { getTendersWithInteraction } from '../../../shared/api/magellan/interaction';
import { displayDecisionNotification } from '../../../shared/components/notifications/decisionNotifications';
import { DecisionStatus } from '../../../shared/entities/Interaction';
import { useUpsertDecision } from '../../../shared/hooks/useUpsertDecision.hook';
import { queryClient } from '../../../shared/infra/queryClient';
import type { DecisionStatusTab } from './useTabs.hook';

export const exitAnimationDuration = 300;

export function useTenderDecisionList(
  activeTabStatus: DecisionStatusTab,
  updateDecisionCounts: (status: DecisionStatus) => void,
) {
  const upsertDecision = useUpsertDecision();
  const onTenderDecision = async (tenderId: number, status: DecisionStatus, reason?: string) => {
    upsertDecision(tenderId, { type: 'DecisionStatus', value: status }, reason);
    updateDecisionCounts(status);
    triggerExitTransition(tenderId, status);
    setTimeout(() => {
      removeTenderFromList(tenderId);
    }, exitAnimationDuration);
    displayDecisionNotification(status);
  };

  const triggerExitTransition = (tenderId: number, status: DecisionStatus) => {
    const currentData = queryClient.getQueryData<TenderWithListData[]>([
      getTendersWithInteraction.name,
      activeTabStatus,
    ]);
    if (!currentData) {
      return;
    }
    const newTendersArray = [...currentData];
    const index = newTendersArray.findIndex(tender => tender.id === tenderId);
    newTendersArray[index].mounted = false;
    switch (status) {
      case DecisionStatus.NOGO:
      case DecisionStatus.LOSS:
        newTendersArray[index].transitionStyle = 'slide-right';
        break;
      default:
        newTendersArray[index].transitionStyle = 'slide-left';
    }
    queryClient.setQueryData<TenderWithListData[]>(
      [getTendersWithInteraction.name, activeTabStatus],
      newTendersArray,
    );
  };

  const removeTenderFromList = (tenderId: number) => {
    const queryKey = [getTendersWithInteraction.name, activeTabStatus];
    queryClient.setQueryData<TenderWithListData[]>(queryKey, tenders => {
      return tenders?.filter(tender => tender.id !== tenderId);
    });
  };

  const onNoteChange = (newNotes: string, tenderId: number) => {
    const queryKey = [getTendersWithInteraction.name, activeTabStatus];
    queryClient.setQueryData<TenderWithListData[]>(queryKey, tenders => {
      return tenders?.map(tender => {
        if (tender.id === tenderId) {
          return {
            ...tender,
            interaction: {
              ...tender.interaction,
              note: newNotes,
              instantAnalysisStatus: tender.interaction?.instantAnalysisStatus || 'WAITING',
            },
          };
        }
        return tender;
      });
    });
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [getTendersWithInteraction.name, activeTabStatus],
    queryFn: () => getTendersWithInteraction(activeTabStatus),
  });

  if (isError) {
    throw error;
  }

  const tenders = data || [];
  return { tenders, isLoading, onTenderDecision, onNoteChange };
}
