import { useState } from 'react';

import { Group, Menu, NavLink, Text, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconSettings, IconStar, IconStarOff, IconTrash, IconCopy } from '@tabler/icons-react';
import type { NavigateFunction } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '../../../../shared/components/UI/Loader/Loader';

import type Stream from '../../../../shared/entities/Stream';
import { useStreamsContext } from '../../contexts/Streams.provider';
import { useFavorites } from '../forms/hooks/useFavorites.hook';
import { useStreamsActions } from '../forms/hooks/useStreamsActions.hook';

type SideBarNavLinkProps = {
  streamWithTenderCount: Stream;
  navigate: NavigateFunction;
  isActive: boolean;
};

export const SideBarNavLink = ({
  streamWithTenderCount,
  navigate,
  isActive,
}: SideBarNavLinkProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();

  const label = (
    <Group
      position="apart"
      dir="rowReverse"
      spacing={2}
      noWrap
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Text w={180} truncate>
        {streamWithTenderCount.name}
      </Text>
      <SideBarStreamMenu isParentHovered={isHovered} streamId={streamWithTenderCount.id} />
      {streamWithTenderCount.pendingDecisionCount && (
        <Text variant="sm" fw={600} c="primary.6">
          {streamWithTenderCount.pendingDecisionCount >= 100
            ? '+99'
            : streamWithTenderCount.pendingDecisionCount}
        </Text>
      )}
    </Group>
  );

  return (
    <NavLink
      label={label}
      noWrap
      active={isActive}
      onClick={() => navigate(`/flux/${streamWithTenderCount.id}${location.search}`)}
      fz="14px"
      fw={isActive ? '600' : '400'}
      px="sm"
      py="xxs"
      styles={theme => ({
        label: {
          color: theme.colors.dark[7],
        },
        root: {
          '&[data-active]': {
            background: theme.colors.primary[1],
          },
        },
      })}
      sx={theme => ({
        borderRadius: theme.radius.md,
      })}
    />
  );
};

type SideBarStreamMenuProps = {
  isParentHovered: boolean;
  streamId: number;
};

const SideBarStreamMenu = ({ isParentHovered, streamId }: SideBarStreamMenuProps) => {
  const { deleteStream, duplicateStream } = useStreamsActions();
  const { isFavorite, toggleFavorite } = useFavorites();
  const [opened, setOpened] = useState(false);
  const streamsContext = useStreamsContext();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const openModal = () =>
    modals.openContextModal({
      modal: 'streamDelete',
      innerProps: { onDelete: () => deleteStream(streamId) },
    });

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(`/flux/${streamId}/edit`);
  };

  const handleDuplicateClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    await duplicateStream(streamId);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    openModal();
  };

  if (!isParentHovered && !opened) {
    return null;
  }

  const addToFavoriteItem = (
    <Menu.Item
      c="gray.9"
      icon={<IconStar size={14} stroke={1.5} color={theme.colors.gray[6]} />}
      onClick={e => {
        e.stopPropagation();
        toggleFavorite(streamId);
      }}
    >
      Ajouter aux favoris
    </Menu.Item>
  );

  const removeToFavoriteItem = (
    <Menu.Item
      c="gray.9"
      icon={<IconStarOff size={14} stroke={1.5} color={theme.colors.gray[6]} />}
      onClick={e => {
        e.stopPropagation();
        toggleFavorite(streamId);
      }}
    >
      Retirer des favoris
    </Menu.Item>
  );

  return (
    <Menu
      shadow="md"
      width={200}
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      withinPortal
    >
      <Menu.Target>
        <IconSettings
          size={16}
          stroke={1.5}
          color={theme.colors.gray[6]}
          onClick={e => e.stopPropagation()}
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          c="gray.9"
          icon={<IconSettings size={14} stroke={1.5} color={theme.colors.gray[6]} />}
          onClick={handleEditClick}
        >
          Éditer le flux
        </Menu.Item>
        <Menu.Item
          c="gray.9"
          icon={<IconCopy size={14} stroke={1.5} color={theme.colors.gray[6]} />}
          onClick={handleDuplicateClick}
        >
          Dupliquer
        </Menu.Item>
        {isFavorite(streamId) ? removeToFavoriteItem : addToFavoriteItem}
        <Menu.Item
          c="red.6"
          icon={<IconTrash size={14} stroke={1.5} />}
          onClick={handleDeleteClick}
        >
          Supprimer
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
