import type { MantineTheme } from '@mantine/core';
import { Box, Group, Menu, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';

import {
  IconArrowRight,
  IconDotsVertical,
  IconSend,
  IconTrophy,
  IconTrophyOff,
  IconX,
} from '@tabler/icons-react';

import type { TengoModals } from '../../../../../shared/contexts/Modals.provider';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import type { ActiveDecisionStatus } from '../utils/columnDefinitions';

type DecisionActionButtonProps = {
  tenderId: number;
  status: ActiveDecisionStatus;
  onTenderDecision: (tenderId: number, status: DecisionStatus, reason?: string) => void;
};

export function DecisionActionButton({
  tenderId,
  status,
  onTenderDecision,
}: DecisionActionButtonProps) {
  const theme = useMantineTheme();
  const onDecision = (status: DecisionStatus, reason?: string) =>
    onTenderDecision(tenderId, status, reason);
  const actionButtons = getDecisionActionButtonSettings(theme, onDecision)[status];

  if (actionButtons.length === 0) {
    return;
  }
  return (
    <Menu shadow="md" withinPortal position="bottom-end">
      <Menu.Target>
        <Box
          w="06"
          h="06"
          p="02"
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          sx={theme => ({
            ':hover': {
              cursor: 'pointer',
              background: theme.colors.gray[2],
              borderRadius: theme.radius.sm,
            },
          })}
        >
          <IconDotsVertical size={16} />
        </Box>
      </Menu.Target>

      <Menu.Dropdown onMouseDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()}>
        {actionButtons.map((actionButton, index) => (
          <Menu.Item
            key={index}
            onMouseDown={e => e.stopPropagation()}
            onClick={actionButton.onClick}
          >
            <Group>
              {actionButton.icon}
              {actionButton.label}
            </Group>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

type DecisionActionButton = {
  icon: React.ReactElement;
  label: string;
  onClick?: () => void;
};

type DecisionActionButtonInit = {
  [key in ActiveDecisionStatus]: DecisionActionButton[];
};

const getDecisionActionButtonSettings: (
  theme: MantineTheme,
  onTenderDecision: (status: DecisionStatus, reason?: string) => void,
) => DecisionActionButtonInit = (theme, onTenderDecision) => {
  const openModal = (nextStatus: DecisionStatus, modalToOpen: TengoModals) =>
    modals.openContextModal({
      modal: modalToOpen,
      innerProps: {
        onDecision: onTenderDecision,
        nextStatus,
      },
    });

  return {
    [DecisionStatus.TO_ANALYZE]: [
      {
        icon: <IconArrowRight size={16} color={theme.colors.primary[7]} />,
        label: 'Placer en Go',
        onClick: () => onTenderDecision(DecisionStatus.GO),
      },
      {
        icon: <IconX size={16} color={theme.colors.red[6]} />,
        label: 'Placer en No go',
        onClick: () => openModal(DecisionStatus.NOGO, 'nogoReason'),
      },
    ],
    [DecisionStatus.GO]: [
      {
        icon: <IconSend size={16} color={theme.colors.primary[7]} />,
        label: 'Placer en offre déposée',
        onClick: () => onTenderDecision(DecisionStatus.ANSWERED),
      },
    ],
    [DecisionStatus.NOGO]: [],
    [DecisionStatus.ANSWERED]: [
      {
        icon: <IconTrophy size={16} color={theme.colors.primary[7]} />,
        label: 'Placer en gagné',
        onClick: () => openModal(DecisionStatus.WIN, 'winLossReason'),
      },
      {
        icon: <IconTrophyOff size={16} color={theme.colors.red[6]} />,
        label: 'Placer en perdu',
        onClick: () => openModal(DecisionStatus.LOSS, 'winLossReason'),
      },
    ],
    [DecisionStatus.WIN]: [],
    [DecisionStatus.LOSS]: [],
  };
};
