import { useEffect, useState } from 'react';

import type { AvatarProps } from '@mantine/core';

import { useMutation } from '@tanstack/react-query';

import BackupImg from '../../../assets/UI/classical-building.png';

import { deleteBuyerLogoURL } from '../../../api/magellan/buyer';
import { Avatar } from '../Avatar/Avatar';

type SizeParam = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type BuyerLogoProps = {
  logoURL: string | undefined;
  buyerId: number | undefined;
  size?: SizeParam;
  radius?: SizeParam;
} & AvatarProps;

export function BuyerLogo({
  logoURL: initialLogoUrl,
  buyerId,
  size,
  radius,
  ...rest
}: BuyerLogoProps) {
  const [logoURL, setLogoURL] = useState<string | null>(initialLogoUrl || null);
  const mutationKey = [deleteBuyerLogoURL.name, buyerId];
  const { src: _src, variant: _variant, ...restWithoutSrc } = rest;

  // Error in image means the logo is not available
  // To avoid future errors we cleanup the logoURL in magellan's DB
  const deleteBuyerLogoURLMutation = useMutation({
    mutationFn: ({ id }: { id: number }) => deleteBuyerLogoURL(id),
    mutationKey,
  });

  useEffect(() => {
    setLogoURL(initialLogoUrl || null);
  }, [initialLogoUrl]);

  const handleLogoError = () => {
    console.error('Error loading logo', initialLogoUrl, buyerId);
    initialLogoUrl && buyerId && deleteBuyerLogoURLMutation.mutate({ id: buyerId });
    setLogoURL(null);
  };

  return (
    <Avatar
      size={size}
      radius={radius}
      onError={handleLogoError}
      src={logoURL || BackupImg}
      {...restWithoutSrc}
    />
  );
}
