import { ThemeIcon, Text, Tooltip } from '@mantine/core';

import { IconQuestionMark } from '@tabler/icons-react';

type ToolTipProps = {
  content: string;
  icon?: React.ReactNode;
};

export default function TooltipWithIcon({
  content,
  icon = <IconQuestionMark size={16} stroke={2} />,
}: ToolTipProps) {
  return (
    <Tooltip
      label={
        <Text variant="xs" fw="600">
          {content}
        </Text>
      }
      position="right"
      width={340}
      withArrow
      arrowSize={6}
      color="primary.6"
      multiline
    >
      <ThemeIcon variant="outline" radius="xl" color="primary.6" size={16}>
        {icon}
      </ThemeIcon>
    </Tooltip>
  );
}
