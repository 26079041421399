import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { Box, MultiSelect, Text } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import { getDepartments } from '../../../../../shared/api/magellan/departments';
import type { FrenchDepartment } from '../../../../../shared/entities/FrenchDepartment';
import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';

type DepartmentMultiSelectProps = {
  form: UseFormReturnType<StreamFormValues, (values: StreamFormValues) => StreamFormValues>;
};

export default function DepartmentMultiSelect({ form }: DepartmentMultiSelectProps) {
  const [departments, setDepartments] = useState<BuyerItemProps[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleValueChanges = (values: string[]) => {
    setValues(values);
    form.setValues({ ...form.values, locations: values });
  };

  useEffect(() => {
    const setInitialValues = async (initialLocations: string[]) => {
      setValues(initialLocations);
    };
    if (form.values.locations?.length) {
      setInitialValues(form.values.locations);
    }
  }, [form.values.locations]);

  useEffect(() => {
    const fetchDepartments = async () => {
      const rawDepartments: FrenchDepartment[] = await getDepartments();
      const formatedDepartments = rawDepartments.map(department => ({
        ...department,
        label: `${department.code} - ${department.name}`,
        value: department.code,
      }));
      setDepartments(formatedDepartments);
    };

    fetchDepartments();
  }, []);

  return (
    <MultiSelect
      placeholder="37 - Indre et Loire, 75 - Paris..."
      radius="md"
      searchable
      value={values}
      onChange={handleValueChanges}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      nothingFound="Aucun département trouvé"
      data={departments}
      itemComponent={SelectDepartmentItem}
      maxDropdownHeight={160}
      filter={(value, selected, item) => {
        return (
          !selected &&
          (item.code.toLowerCase().includes(value.toLowerCase().trim()) ||
            item.name.toLowerCase().includes(value.toLowerCase().trim()))
        );
      }}
    />
  );
}

interface BuyerItemProps extends ComponentPropsWithoutRef<'div'> {
  code: string;
  name: string;
  value: string;
  label: string;
}

const SelectDepartmentItem = forwardRef<HTMLDivElement, BuyerItemProps>(
  (props: BuyerItemProps, ref) => {
    const { name, code, ...others } = props;
    return (
      <Box ref={ref} {...others}>
        <Text truncate>{`${code} - ${name}`}</Text>
      </Box>
    );
  },
);
