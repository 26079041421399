import { createContext, useContext } from 'react';

import type Buyer from '../../../shared/entities/Buyer';
import type Tender from '../../../shared/entities/Tender';

type NoticeContextType = {
  tender: Tender & { buyer: Buyer & { numberOfContacts: number } };
};

const NoticeContext = createContext<NoticeContextType | undefined>(undefined);

type NoticeProviderProps = {
  tender: Tender & { buyer: Buyer & { numberOfContacts: number } };
  children: React.ReactNode;
};

export function NoticeProvider({ tender, children }: NoticeProviderProps) {
  return <NoticeContext.Provider value={{ tender }}>{children}</NoticeContext.Provider>;
}

export const useNoticeContext = () => {
  const context = useContext(NoticeContext);
  if (!context) {
    throw new Error('useNoticeContext must be used within a NoticeProvider');
  }
  return context;
};
