import { Stack, Text, Title, Group, Accordion } from '@mantine/core';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import type { UseFormReturnType } from '@mantine/form';

import { IconBarrierBlock, IconBox, IconChevronRight, IconHotelService } from '@tabler/icons-react';

import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';

import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import { BuyerMultiselect } from './BuyerMultiselect';
import CPVMultiselect from './CPVMultiselect';
import CheckboxCard from './CheckboxCard';
import CheckboxCardGroup from './CheckboxCardGroup';
import DepartmentMultiSelect from './DepartmentMultiSelect';
import { RenewalSwitch } from './RenewalSwitch';
import TooltipWithIcon from './TooltipWithIcon';

type AdvancedFieldsProps = {
  form: UseFormReturnType<StreamFormValues, (values: StreamFormValues) => StreamFormValues>;
  isOpened?: boolean;
};

export const AdvancedFields = ({ form, isOpened = false }: AdvancedFieldsProps) => {
  return (
    <Accordion
      chevron={<IconChevronRight stroke="2px" />}
      defaultValue={isOpened ? 'advanced-filters' : undefined}
      styles={{
        chevron: {
          '&[data-rotate]': {
            transform: 'rotate(90deg)',
          },
        },
        content: {
          paddingLeft: '0px',
        },
      }}
    >
      <Accordion.Item value="advanced-filters" sx={{ borderBottom: 'none' }}>
        <Accordion.Control
          pl={0}
          mr={0}
          w="322px"
          sx={{
            ':hover': {
              background: 'white',
            },
            '.mantine-Accordion-chevro': {
              fontSize: '12px',
            },
          }}
        >
          <Title order={4}>Faire une recherche avancée</Title>
        </Accordion.Control>
        <Accordion.Panel pl={0}>
          <Stack spacing={32}>
            {/* status */}
            <Stack spacing="md">
              <Text variant="md" fw="600" c="gray.9">
                Statut de l'appel d'offre
              </Text>
              <CheckboxCardGroup {...form.getInputProps('status')}>
                <CheckboxCard
                  value="OPEN"
                  icon={<TenderStatusBadge status={TenderStatus.OPEN} />}
                  description={
                    "Découvrez les appels d'offres ouverts à la participation, directement dans votre flux."
                  }
                  disabled={form.getTransformedValues().isRenewal}
                />
                <CheckboxCard
                  value="CLOSED"
                  icon={<TenderStatusBadge status={TenderStatus.CLOSED} />}
                  description={
                    "Accédez à des appels d'offres clôturés afin de les examiner et d'en tirer des analyses pertinentes."
                  }
                  bottomAction={
                    <RenewalSwitch
                      status={form.getTransformedValues().status}
                      setStatus={value => form.setFieldValue('status', value)}
                      {...form.getInputProps('isRenewal')}
                    />
                  }
                />
              </CheckboxCardGroup>
            </Stack>

            {/* Category */}
            <Stack spacing="md">
              <Group w="100%">
                <Title order={5}>Catégorie de marché</Title>
                <TooltipWithIcon content="Le code de la commande publique distingue 3 types de marchés : travaux, fournitures et services. Les règles de procédure (déroulé de l'appel d'offres, montant minimal, ..) et de publicité (avis à émettre) sont différentes suivant les catégories." />
              </Group>
              <CheckboxCardGroup {...form.getInputProps('categories')}>
                <CheckboxCard
                  value="WORKS"
                  label="Travaux"
                  icon={<IconBarrierBlock size={16} stroke={1} />}
                  description={'Concerne la réalisation de travaux.'}
                />
                <CheckboxCard
                  value="SUPPLIES"
                  label="Fournitures"
                  icon={<IconBox size={16} stroke={1} />}
                  description={"Concerne l'acquisition de biens, de produits ou de matériels."}
                />
                <CheckboxCard
                  value="SERVICES"
                  label="Services"
                  icon={<IconHotelService size={16} stroke={1} />}
                  description={'Concerne les prestations de services intellectuels ou techniques.'}
                />
              </CheckboxCardGroup>
            </Stack>
            {/* Location */}
            <Stack spacing="md">
              <Group w="100%">
                <Title order={5}>Localisation (départements)</Title>
                <TooltipWithIcon content="Recherchez par département en renseignant les deux premiers chiffres d'un ou plusieurs départements" />
              </Group>
              <DepartmentMultiSelect form={form} />
            </Stack>
            {/* date range */}
            <Stack spacing="md">
              <Text variant="md" fw="600" c="gray.9">
                Dates limites
              </Text>
              <DatesProvider settings={{ locale: 'fr' }}>
                <Group spacing="xl">
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label="Après cette date de publication"
                    placeholder="JJ/MM/AAAA"
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('afterPublicationDate')}
                  />
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label="Avant cette date de publication"
                    placeholder="JJ/MM/AAAA"
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('beforePublicationDate')}
                  />
                </Group>
                <Group spacing="xl">
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label="Après cette date de remise de l'offre"
                    placeholder="JJ/MM/AAAA"
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('afterResponseDeadlineDate')}
                  />
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label="Avant cette date de remise de l'offre"
                    placeholder="JJ/MM/AAAA"
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('beforeResponseDeadlineDate')}
                  />
                </Group>
              </DatesProvider>
            </Stack>
            {/* geography & cpv */}
            <CPVMultiselect form={form} />
            {/* company name */}
            <Title order={3}>A propos de l'acheteur</Title>
            <BuyerMultiselect form={form} />
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
