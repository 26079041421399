import { useEffect } from 'react';

import { Group, Switch, Text } from '@mantine/core';

import { IconInfoCircle, IconRepeat } from '@tabler/icons-react';

import { Tooltip } from '../../../../../shared/components/UI/Tooltip/Tooltip';

import { TenderStatus } from '../../../../../shared/entities/Tender';

type RenewalStatusProps = {
  value: boolean;
  onChange: (val: boolean | undefined) => void;
  status: string[];
  setStatus: (status: string[]) => void;
};

export function RenewalSwitch({ value, onChange, status, setStatus }: RenewalStatusProps) {
  const hasClosedStatus = status.find(s => s === TenderStatus.CLOSED);
  useEffect(() => {
    if (value) {
      setStatus(status.filter(s => s !== TenderStatus.OPEN));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!hasClosedStatus && value !== undefined) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClosedStatus]);

  const shade = hasClosedStatus ? 5 : 2;
  return (
    <Group
      noWrap
      position="apart"
      onClick={event => {
        event.stopPropagation();
        hasClosedStatus && onChange(value ? undefined : true);
      }}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Group noWrap c={`gray.${shade}`} spacing="02">
        <IconRepeat size={20} />
        <Text variant="sm" fw="400" c={`gray.${shade + 1}`}>
          Renouvellements uniquement
        </Text>
        <Tooltip
          width={350}
          content="Nous détectons les renouvellements potentiels grâce à la date de fin de marché (12 mois avant et 6 mois après)."
        >
          <IconInfoCircle size={16} />
        </Tooltip>
      </Group>
      <Switch
        checked={value ? value : false}
        disabled={!hasClosedStatus}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </Group>
  );
}
