import type { StreamSection } from '../../../entities/StreamSection';
import { magellanClient } from '../../../infra/axios';
import type { CreateStreamSectionResponseDTO } from './dto';

export async function getStreamSections(): Promise<StreamSection[]> {
  const response = await magellanClient.get<StreamSection[]>(`/streamSections`);
  return response.data;
}

export async function createStreamSection(title: string): Promise<CreateStreamSectionResponseDTO> {
  const response = await magellanClient.post<CreateStreamSectionResponseDTO>(`/streamSections`, {
    title,
  });
  return response.data;
}

export async function updateStreamSection(
  id: number,
  title: string,
): Promise<CreateStreamSectionResponseDTO> {
  const response = await magellanClient.put<CreateStreamSectionResponseDTO>(`/streamSections/${id}`, {
    title,
  });
  return response.data;
}

export async function deleteStreamSection(id: number): Promise<void> {
  await magellanClient.delete(`/streamSections/${id}`);
}
