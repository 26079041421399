import { Stack, Group, Button, Text, Box, Divider } from '@mantine/core';

import { IconExternalLink } from '@tabler/icons-react';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';
import { BuyerLogo } from '../../../../shared/components/UI/BuyerLogo/BuyerLogo';
import { ContactCard } from '../../../../shared/components/UI/ContactCard/ContactCard';

import type { BuyerContact } from '../../../../shared/entities/Buyer';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function BuyerCard() {
  const { tender } = useNoticeContext();
  const { buyer, buyerContact } = tender;
  const { id: buyerId, originalName: buyerName, logoURL, domainURL, numberOfContacts } = buyer;

  return (
    <BrandedCard title="Acheteur" w="100%">
      <Stack spacing="05">
        <Header {...{ buyerId, buyerName, logoURL, domainURL, numberOfContacts }} />
        <Contact buyerContact={buyerContact} />
      </Stack>
    </BrandedCard>
  );
}

type HeaderProps = {
  buyerId: string;
  buyerName: string;
  logoURL?: string;
  domainURL?: string;
  numberOfContacts: number;
};

const Header = ({ buyerId, buyerName, logoURL, domainURL, numberOfContacts }: HeaderProps) => {
  return (
    <Group noWrap position="apart" align="flex-start">
      <Group noWrap spacing="04" align="flex-start">
        <BuyerLogo
          buyerId={Number(buyerId)}
          logoURL={logoURL}
          radius="md"
          styles={{
            root: {
              width: '48px!important',
              height: '48px!important',
            },
          }}
        />
        <Stack spacing="01">
          <Group noWrap spacing="02">
            <Text variant="md" fw="500" color="gray.9" lineClamp={1}>
              {buyerName}
            </Text>
            {domainURL && (
              <Group
                noWrap
                spacing="01"
                c="primary.6"
                sx={theme => ({
                  cursor: 'pointer',
                  ':hover': { color: theme.colors.primary[7], textDecoration: 'underline' },
                })}
                onClick={() => {
                  let url = domainURL;
                  if (!url) {
                    return;
                  }
                  if (!url.startsWith('http') || !url.startsWith('www')) {
                    url = `https://${domainURL}`;
                  }
                  window.open(url, '_blank');
                }}
              >
                <Box w="01" h="01" bg="gray.2" sx={{ borderRadius: '100%' }} />
                <IconExternalLink size="16" />
                <Text variant="sm" fw="400" lineClamp={1}>
                  Voir le site internet
                </Text>
              </Group>
            )}
          </Group>
          <Text
            variant="sm"
            fw="400"
            c="gray.6"
          >{`${numberOfContacts} contact${numberOfContacts > 1 ? 's' : ''}`}</Text>
        </Stack>
      </Group>
      <Button
        radius="md"
        variant="light"
        color="primary"
        size="sm"
        onClick={() => window.open(`/buyer/${buyerId}`, '_blank')}
      >
        Voir la page acheteur
      </Button>
    </Group>
  );
};

type ContactProps = {
  buyerContact?: BuyerContact;
};

const Contact = ({ buyerContact }: ContactProps) => {
  if (
    !buyerContact?.interlocutor &&
    !buyerContact?.contact &&
    !buyerContact?.email &&
    !buyerContact?.phone
  ) {
    return null;
  }

  return (
    <Stack spacing="05">
      <Divider orientation="horizontal" color="gray.1" />
      <ContactCard
        name={buyerContact.contact || buyerContact.interlocutor || ''}
        email={buyerContact.email || ''}
        phone={buyerContact.phone || ''}
        location={buyerContact.location}
      />
    </Stack>
  );
};
