import { ActionIcon, Group, Text, ThemeIcon } from '@mantine/core';

import { IconX } from '@tabler/icons-react';

import type { TableFilter } from '../../../../../app/pages/Pipeline.page';
import type { TableColumn, TableColumnWithFilter } from '../utils/columnDefinitions';
import type { FilterConfig } from './PipelineFilters';

type FilterTagsProps = {
  filters: TableFilter[];
  filterConfig: Record<TableColumnWithFilter, FilterConfig>;
  handleRemoveFilter: (columnId: TableColumnWithFilter) => void;
};

export function FilterTags({ filters, filterConfig, handleRemoveFilter }: FilterTagsProps) {
  const tags = filters.map(filter => {
    const columnId = filter.id;
    const config = filterConfig[columnId];
    const icon = config.icon;
    const filterLabel = config.filterLabel;
    const valueLabels = filter.value.map(value => {
      const option = config.options.find(option => option.value === value);
      return option?.valueLabel || value;
    });
    return { columnId, icon, filterLabel, valueLabels };
  });

  return (
    <Group>
      {tags.map(tag => (
        <FilterTag
          key={`${tag.columnId}-${tag.valueLabels[0]}`}
          icon={tag.icon}
          filterLabel={tag.filterLabel}
          valueLabels={tag.valueLabels}
          onClick={() => handleRemoveFilter(tag.columnId)}
          columnId={tag.columnId}
        />
      ))}
    </Group>
  );
}

type FilterTagProps = {
  columnId: TableColumn;
  icon: React.ReactElement;
  filterLabel: string;
  valueLabels: string[];
  onClick: (value: string) => void;
};

const FilterTag = ({
  columnId,
  icon,
  filterLabel,
  valueLabels,
  onClick: removeFilter,
}: FilterTagProps) => {
  return (
    <Group
      noWrap
      spacing="01"
      px="02"
      py="00"
      maw={400}
      sx={theme => ({
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.primary[1],
      })}
    >
      <ThemeIcon variant="light" size="sm" bg="primary.1">
        {icon}
      </ThemeIcon>
      <Text variant="sm" fw="500">
        {filterLabel}
      </Text>
      <Text variant="sm" fw="400" truncate>
        {valueLabels.join(', ')}
      </Text>
      <ActionIcon variant="transparent" size={16} onClick={() => removeFilter(columnId)}>
        <IconX size={16} />
      </ActionIcon>
    </Group>
  );
};
