import type { MRT_ColumnDef } from 'mantine-react-table';

import type { Cpv } from '../../../../../shared/entities/Cpv';
import type Tender from '../../../../../shared/entities/Tender';
import { formatDateAsText } from '../../../../../shared/utils/dates';
import type { ActionCellData } from './cells/ActionCell';
import { ActionCell } from './cells/ActionCell';
import { AmountCell, type AmountCellData } from './cells/AmountCell';
import { DecisionCell } from './cells/DecisionCell';
import { OpportunityCell } from './cells/OpportunityCell';
import type { StatusCellData } from './cells/StatusCell';
import { StatusCell } from './cells/StatusCell';

export enum TableColumn {
  OPPORTUNITY = 'opportunity',
  STATUS = 'status',
  DATES = 'dates',
  AMOUNT = 'amount',
  DURATION = 'duration',
  DECISION_STATUS = 'decisionStatus',
  ACTIONS = 'actions',
}

const fixedWidthProps = (width?: number) => ({
  size: width,
  maxSize: width,
  minSize: width,
  mantineTableBodyCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
  mantineTableHeadCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
});

export type OpportunityCellData = {
  tenderTitle: string;
  cpvs: Cpv[];
};

export const oppotunityColumn: MRT_ColumnDef<Tender> = {
  header: 'Opportunité',
  id: TableColumn.OPPORTUNITY,
  accessorFn: tender => ({
    tenderTitle: tender.title,
    cpvs: tender.cpvs,
  }),
  Cell: ({ cell }) => <OpportunityCell cell={cell} />,
};

export const statusColumn: MRT_ColumnDef<Tender> = {
  header: 'Status',
  id: TableColumn.STATUS,
  accessorFn: tender =>
    ({ status: tender.status, isRenewal: !!tender.markedAsRenewalAt }) as StatusCellData,
  enableColumnFilter: false,
  Cell: StatusCell,
  ...fixedWidthProps(140),
};

export const datesColumn: MRT_ColumnDef<Tender> = {
  header: 'Date',
  id: TableColumn.DATES,
  accessorFn: tender =>
    `${formatDateAsText(tender.publicationDate, true)} - ${formatDateAsText(tender.responseDeadline)}`,
  ...fixedWidthProps(200),
};

export const amountColumn: MRT_ColumnDef<Tender> = {
  header: 'Montant',
  id: TableColumn.AMOUNT,
  accessorFn: tender =>
    ({
      amount: tender.estimatedValueInEur,
      isLlmEnriched: tender.isEstimatedValueInEurLLMEnriched,
    }) as AmountCellData,
  Cell: AmountCell,
  ...fixedWidthProps(120),
};

export const durationColumn: MRT_ColumnDef<Tender> = {
  header: 'Durée',
  id: TableColumn.DURATION,
  accessorFn: tender => (tender.durationInMonth ? `${tender.durationInMonth} mois` : '-'),
  ...fixedWidthProps(80),
};

export const decisionStatusColumn: MRT_ColumnDef<Tender> = {
  header: 'Décision',
  id: TableColumn.DECISION_STATUS,
  accessorFn: tender =>
    tender.markedAsRenewalAt
      ? tender.interaction?.decisionRenewalStatus
      : tender.interaction?.decisionStatus,
  Cell: DecisionCell,
  ...fixedWidthProps(120),
};

export const getActionsColumn = (buyerInfiniteQueryKey: string): MRT_ColumnDef<Tender> => ({
  header: '',
  id: TableColumn.ACTIONS,
  accessorFn: tender =>
    ({
      tenderId: tender.id,
      decisionStatus: tender.interaction?.decisionStatus,
      renewalStatus: tender.interaction?.decisionRenewalStatus,
      buyerInfiniteQueryKey,
      isRenewal: !!tender.markedAsRenewalAt,
    }) as ActionCellData,
  Cell: ActionCell,
  ...fixedWidthProps(50),
});
