import { Modal } from '@mantine/core';

import { SelectQuestionsSetsModal } from '../../../shared/components/modals/SelectQuestionsSetsModal';
import type { QuestionsSet } from '../../../shared/entities/QuestionsSet';

type SelectQuestionsSetsModalContainerProps = {
  questionsSets: QuestionsSet[];
  triggerInstantAnalysis: (questionsSetsIds: number[]) => Promise<void>;
  setQuestionsSets: (questionsSets: QuestionsSet[]) => void;
  opened: boolean;
  close: () => void;
};

export function SelectQuestionsSetsModalContainer({
  questionsSets,
  triggerInstantAnalysis,
  setQuestionsSets,
  opened,
  close,
}: SelectQuestionsSetsModalContainerProps) {
  return (
    <Modal opened={opened} onClose={close} size="xl" withCloseButton={false} yOffset="20vh">
      <SelectQuestionsSetsModal
        onValidation={triggerInstantAnalysis}
        questionsSets={questionsSets}
        setQuestionsSets={setQuestionsSets}
        onClose={close}
      />
    </Modal>
  );
}
