import { requestDCE } from '../../../../shared/api/magellan/tender';
import { DceRequestStatus } from '../../../../shared/entities/DceRequestStatus';
import { useQuestionsSetsAnalysis } from '../../../../shared/hooks/useQuestionsSetsAnalysis.hook';
import AnswerCard from '../../../instantAnalysis/components/AnswersCard';
import TriggerCard from '../../../instantAnalysis/components/TriggerCard';
import { useNoticeContext } from '../../contexts/Notice.provider';
import { useIsAnalysable } from '../../hooks/useIsAnalysable.hook';

type InstantAnalysisTabProps = {
  dceRequestStatus: DceRequestStatus;
  setDceRequestStatus: (dceRequestStatus: DceRequestStatus) => void;
};

export default function InstantAnalysisTab({
  dceRequestStatus,
  setDceRequestStatus,
}: InstantAnalysisTabProps) {
  const { tender } = useNoticeContext();
  const questionsSetAnalysis = useQuestionsSetsAnalysis(tender.id);
  const isAnalysable = useIsAnalysable(tender.id);

  const sendDCERequest = () => {
    if (tender.id) {
      requestDCE(tender.id);
      setDceRequestStatus(DceRequestStatus.IN_SEARCH);
    }
  };

  switch (questionsSetAnalysis.status) {
    case 'WAITING':
      return (
        <TriggerCard
          tenderStatus={tender.status}
          isAnalysable={isAnalysable}
          triggerInstantAnalysis={questionsSetAnalysis.triggerQuestionsSetInstantAnalysis}
          dceRequestStatus={dceRequestStatus}
          sendDCERequest={sendDCERequest}
        />
      );
    case 'RUNNING':
    case 'DONE':
      return (
        questionsSetAnalysis.answers && (
          <AnswerCard
            llmStatus={questionsSetAnalysis.status}
            answers={questionsSetAnalysis.answers}
            triggerInstantAnalysis={questionsSetAnalysis.triggerQuestionsSetInstantAnalysis}
            tenderId={tender.id}
            onRetry={questionsSetAnalysis.retry}
          />
        )
      );
    case 'FAILED':
      throw new Error('Instant analysis failed');
  }
}
