import { useState } from 'react';

import {
  Box,
  Center,
  HoverCard,
  useMantineTheme,
  Text,
  Group,
  Stack,
  type MantineTheme,
} from '@mantine/core';

import { IconArrowRight, IconLoader, IconPlayerPlay } from '@tabler/icons-react';

import { useUrlHash } from '../../../../../shared/contexts/UrlHash.provider';
import { AssessmentStatus } from '../../../../../shared/entities/AssessmentStatus';
import type {
  AssessmentRecap,
  InstantAnalysisStatus,
} from '../../../../../shared/entities/InstantAnalysisAnswer';
import { UrlHashBuilder } from '../../../../../shared/utils/navigation/UrlHashBuilder';
import { AssessmentBadge } from '../../../../instantAnalysis/components/AssessmentBadge';
import { AssessmentCircularGraph } from '../../../../instantAnalysis/components/AssessmentCircularGraph';

type AssessmentRecapPreviewProps = {
  assessmentRecap: AssessmentRecap;
  instantAnalysisStatus: InstantAnalysisStatus;
  instantAnalysisURL: string;
  isMissingDocuments: boolean;
};
export function AssessmentRecapPreview({
  assessmentRecap,
  instantAnalysisStatus,
  instantAnalysisURL,
  isMissingDocuments,
}: AssessmentRecapPreviewProps) {
  const { redirectTo } = useUrlHash();
  const theme = useMantineTheme();
  const [isHovered, setHovered] = useState(false);

  const numberOfAssessedPoints =
    assessmentRecap.blockingPoint +
    assessmentRecap.attentionPoint +
    assessmentRecap.positivePoint +
    assessmentRecap.neutralPoint;

  const icon: React.ReactElement | null = getIcon(
    theme,
    numberOfAssessedPoints,
    instantAnalysisStatus,
    isHovered,
    isMissingDocuments,
  );

  const launchGradient = {
    background: `linear-gradient(120deg, #FFFFFF 0%, #DAEEFF 100%)`,
    hover: `linear-gradient(120deg, #DAEEFF 0%, #FFFFFF 100%)`,
  };

  const missingDocGradient = {
    background: `linear-gradient(310.87deg, #EDEDF1 10.88%, #FFFFFF 87.06%)`,
    hover: `linear-gradient(135.95deg, #EDEDF1 2.33%, #FFFFFF 96.11%)`,
  };

  const placeholder = (
    <Box
      m="auto"
      w="32px"
      h="32px"
      p="2px"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: `100%`,
        background: 'white',
      })}
    >
      <Box
        sx={{
          borderRadius: `100%`,
          background: isMissingDocuments
            ? missingDocGradient.background
            : launchGradient.background,
          ':hover': {
            background: isMissingDocuments ? missingDocGradient.hover : launchGradient.hover,
          },
        }}
        w="26px"
        h="26px"
        p="4px"
      >
        <Box
          h="20px"
          w="20px"
          ml="-1px"
          mt="-1px"
          sx={{
            borderRadius: `100%`,
            background: `white`,
          }}
        >
          <Center h="100%" w="100%">
            {icon}
          </Center>
        </Box>
      </Box>
    </Box>
  );

  const iconWrapper = (
    <Box
      h="18px"
      w="18px"
      ml="-1px"
      sx={{
        borderRadius: `100%`,
        background: `white`,
      }}
      c="primary.6"
    >
      <Box
        h="18px"
        w="18px"
        sx={{
          borderRadius: `100%`,
          background: `white`,
        }}
        color="primary.6"
      >
        <Center h="100%" w="100%">
          {icon}
        </Center>
      </Box>
    </Box>
  );

  const content =
    instantAnalysisStatus === 'WAITING' ? (
      placeholder
    ) : (
      <AssessmentCircularGraph assessmentRecap={assessmentRecap} size="xs" icon={iconWrapper} />
    );

  return (
    <HoverCard withinPortal position="right-start">
      <HoverCard.Target>
        <Box
          m="auto"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
            redirectTo(
              instantAnalysisURL,
              new UrlHashBuilder().addLocationData().build(),
              undefined,
              e,
            );
          }}
          sx={{
            borderRadius: `100%`,
            boxShadow: theme.shadows.xs,
            ':hover': {
              cursor: 'pointer',
              boxShadow: theme.shadows.sm,
            },
          }}
        >
          {content}
        </Box>
      </HoverCard.Target>
      <HoverCard.Dropdown
        bg="gray.9"
        sx={theme => ({
          borderRadius: theme.radius.sm,
          padding: theme.spacing['02'],
          border: `1px solid ${theme.colors.gray[8]}`,
          boxShadow: theme.shadows.regular,
        })}
      >
        <HoverContent
          assessmentRecap={assessmentRecap}
          instantAnalysisStatus={instantAnalysisStatus}
          isMissingDocuments={isMissingDocuments}
        />
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function getIcon(
  theme: MantineTheme,
  numberOfAssessedPoints: number,
  instantAnalysisStatus: string,
  isHovered: boolean,
  isMissingDocuments: boolean,
) {
  const isReadyButNotStarted = numberOfAssessedPoints === 0 && instantAnalysisStatus === 'DONE';
  const isStarted = numberOfAssessedPoints > 0 && instantAnalysisStatus === 'DONE';

  if (isReadyButNotStarted) {
    return (
      <IconArrowRight size={12} color={isHovered ? theme.colors.gray[6] : theme.colors.gray[4]} />
    );
  } else if (isStarted) {
    return <IconArrowRight size={12} color={isHovered ? theme.colors.gray[6] : `white`} />;
  }

  if (isMissingDocuments) {
    return isHovered ? (
      <IconArrowRight size={12} color={theme.colors.gray[6]} />
    ) : (
      <IconLoader size={12} color={theme.colors.gray[2]} />
    );
  }
  return (
    <IconPlayerPlay
      size={12}
      color={theme.colors.primary[7]}
      style={{ fill: theme.colors.primary[7] }}
    />
  );
}

type HoverContentProps = {
  assessmentRecap: AssessmentRecap;
  instantAnalysisStatus: InstantAnalysisStatus;
  isMissingDocuments: boolean;
};
const HoverContent = ({
  assessmentRecap,
  isMissingDocuments,
  instantAnalysisStatus,
}: HoverContentProps) => {
  const numberOfAssessedPoints =
    assessmentRecap.blockingPoint +
    assessmentRecap.attentionPoint +
    assessmentRecap.positivePoint +
    assessmentRecap.neutralPoint;

  if (isMissingDocuments) {
    return (
      <Text variant="xs" fw={500} c="white">
        En attente des documents pour analyser
      </Text>
    );
  }
  if (instantAnalysisStatus === 'WAITING') {
    return (
      <Text variant="xs" fw={500} c="white">
        Lancer une analyse
      </Text>
    );
  }
  if (numberOfAssessedPoints === 0 && instantAnalysisStatus === 'DONE') {
    return (
      <Stack>
        <Text variant="xs" fw={500} c="white">
          Commencez à analyser
        </Text>
        <AssessmentLine status={AssessmentStatus.UNASSESSED} amount={assessmentRecap.unAssessed} />
      </Stack>
    );
  }

  return (
    <Stack spacing="01">
      <Text variant="xs" fw={500} c="white">
        {assessmentRecap.unAssessed > 0 ? 'Continuez votre analyse' : 'Analyse terminée'}
      </Text>
      <AssessmentLine status={AssessmentStatus.UNASSESSED} amount={assessmentRecap.unAssessed} />
      <AssessmentLine
        status={AssessmentStatus.POSITIVE_POINT}
        amount={assessmentRecap.positivePoint}
      />
      <AssessmentLine
        status={AssessmentStatus.NEUTRAL_POINT}
        amount={assessmentRecap.neutralPoint}
      />
      <AssessmentLine
        status={AssessmentStatus.ATTENTION_POINT}
        amount={assessmentRecap.attentionPoint}
      />
      <AssessmentLine
        status={AssessmentStatus.BLOCKING_POINT}
        amount={assessmentRecap.blockingPoint}
      />
    </Stack>
  );
};

type AssessmentLineProps = {
  amount: number;
  status: AssessmentStatus;
};

const AssessmentLine = ({ amount, status }: AssessmentLineProps) => {
  let label = 'points à traiter';
  switch (status) {
    case AssessmentStatus.BLOCKING_POINT:
      label = 'points bloquants';
      break;
    case AssessmentStatus.ATTENTION_POINT:
      label = "points d'attention";
      break;
    case AssessmentStatus.POSITIVE_POINT:
      label = 'points positifs';
      break;
    case AssessmentStatus.NEUTRAL_POINT:
      label = 'points neutres';
      break;
  }
  if (!amount) {
    return;
  }

  return (
    <Group>
      <AssessmentBadge assessment={status} isSelected />
      <Text variant="xs" fw={500} c="white">
        {amount} {label}
      </Text>
    </Group>
  );
};
