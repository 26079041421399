import React from 'react';

import type { MantineTheme, Sx } from '@mantine/core';
import { Stack, Stepper, useMantineTheme, Text, Group } from '@mantine/core';

import {
  IconArrowRight,
  IconTrophy,
  IconRocket,
  IconThumbUp,
  IconX,
  IconTrophyOff,
  IconThumbDown,
  IconPencil,
} from '@tabler/icons-react';

import { DecisionStatus } from '../../../../shared/entities/Interaction';

export type ActiveStatuses =
  | DecisionStatus.PENDING
  | DecisionStatus.TO_ANALYZE
  | DecisionStatus.GO
  | DecisionStatus.ANSWERED;

type DecisionStep = {
  decisionsStatus: ActiveStatuses;
  editStatus: DecisionStatus[];
  label: string;
  getSubtitle: (currentStatus: DecisionStatus) => React.ReactNode;
};

type DecisionStepperProps = {
  currentStep: number;
  decisionStatus: DecisionStatus;
  onEditDecision: (decisionStatus: ActiveStatuses) => void;
};

export function DecisionStepper({
  currentStep,
  decisionStatus,
  onEditDecision,
}: DecisionStepperProps) {
  const theme = useMantineTheme();
  const decisionSteps: DecisionStep[] = [
    {
      decisionsStatus: DecisionStatus.PENDING,
      editStatus: [DecisionStatus.REJECTED, DecisionStatus.TO_ANALYZE],
      label: 'Évaluation',
      getSubtitle: currentStatus => {
        switch (currentStatus) {
          case DecisionStatus.PENDING:
            return 'Cet A.O est-il pertinent ?';
          case DecisionStatus.REJECTED:
            return <EditSubtitle subtitle="Rejeté" />;
          default:
            return <EditSubtitle subtitle="À analyser" />;
        }
      },
    },
    {
      decisionsStatus: DecisionStatus.TO_ANALYZE,
      editStatus: [DecisionStatus.GO, DecisionStatus.NOGO],
      label: 'Décisions',
      getSubtitle: currentStatus => {
        switch (currentStatus) {
          case DecisionStatus.PENDING:
          case DecisionStatus.REJECTED:
            return '';
          case DecisionStatus.TO_ANALYZE:
            return 'Analysez et décidez';
          case DecisionStatus.NOGO:
            return <EditSubtitle subtitle="No go" />;
          default:
            return <EditSubtitle subtitle="Go" />;
        }
      },
    },
    {
      decisionsStatus: DecisionStatus.GO,
      editStatus: [DecisionStatus.ANSWERED],
      label: 'Réponse',
      getSubtitle: currentStatus => {
        switch (currentStatus) {
          case DecisionStatus.PENDING:
          case DecisionStatus.REJECTED:
          case DecisionStatus.TO_ANALYZE:
            return '';
          case DecisionStatus.GO:
            return 'Répondez, déposez';
          default:
            return <EditSubtitle subtitle="Offre déposée" />;
        }
      },
    },
    {
      decisionsStatus: DecisionStatus.ANSWERED,
      editStatus: [DecisionStatus.WIN, DecisionStatus.LOSS],
      label: 'Résultats',
      getSubtitle: currentStatus => {
        switch (currentStatus) {
          case DecisionStatus.LOSS:
            return (
              <Text variant="xs" fw={400}>
                Perdu
              </Text>
            );
          case DecisionStatus.WIN:
            return (
              <Text variant="xs" fw={400}>
                Gagné
              </Text>
            );
          case DecisionStatus.ANSWERED:
            return 'Gagné ou perdu ?';
          default:
            return '';
        }
      },
    },
  ];
  const isPrematureEndStep =
    decisionStatus === DecisionStatus.REJECTED || decisionStatus === DecisionStatus.NOGO;

  const nextStyle = {
    '.mantine-Stepper-stepIcon': {
      background: theme.colors.gray[1],
      borderColor: theme.colors.gray[1],
      color: isPrematureEndStep ? theme.colors.gray[3] : theme.colors.gray[6],
    },
    ':hover': {
      cursor: 'default',
    },
  };

  return (
    <Stepper
      size="xs"
      active={currentStep}
      iconSize={32}
      w="65%"
      maw={680}
      onStepClick={(step: number) => {
        if (step >= currentStep && !isPrematureEndStep) {
          return;
        }
        onEditDecision(decisionSteps[step].decisionsStatus);
      }}
      styles={theme => ({
        separator: {
          marginLeft: theme.spacing['03'],
          marginRight: theme.spacing['03'],
          background: 'transparent',
          borderTop: `2px dotted ${theme.colors.gray[3]}`,
        },
        separatorActive: {
          marginLeft: theme.spacing['03'],
          marginRight: theme.spacing['03'],
          background: 'transparent',
          borderTop: `2px dotted ${theme.colors.gray[5]}`,
        },
      })}
    >
      {decisionSteps.map((step, index) => {
        const { icon, color } = getIconData(step.editStatus, decisionStatus);
        const isEditMod = step.decisionsStatus !== decisionStatus;
        const isActiveStep = index === currentStep;
        const isNextStep = index > currentStep;
        const showNumberIcon = isNextStep || (isActiveStep && !isEditMod);
        const iconStyle = getIconStyle(theme, color, showNumberIcon);
        const stepStyle = {
          ...iconStyle,
          borderRadius: theme.radius.md,
          ':hover':
            !isActiveStep || isPrematureEndStep
              ? {
                  cursor: 'pointer',
                  background: theme.colors.gray[0],
                }
              : { cursor: 'default' },
        };

        return (
          <Stepper.Step
            key={step.label}
            px="02"
            py="01"
            label={
              <LabelComponent
                label={step.label}
                subtitle={step.getSubtitle(decisionStatus)}
                isNextStep={isNextStep}
                isPrematureEndStep={isPrematureEndStep}
              />
            }
            icon={showNumberIcon ? undefined : icon}
            completedIcon={icon}
            sx={isNextStep ? nextStyle : stepStyle}
          />
        );
      })}
    </Stepper>
  );
}

type StatusIconMap = {
  [key in DecisionStatus]: {
    icon?: JSX.Element;
    color: 'red' | 'primary';
  };
};

const getIconData = (currentStepStatuses: DecisionStatus[], currentStatus: DecisionStatus) => {
  let decisionsStatus = currentStepStatuses.find(status => status === currentStatus);
  // If the current status is not in the list of statuses for the current step,
  // we filter premature end statuses because this means the step is completed.
  if (!decisionsStatus) {
    const filteredStatuses = currentStepStatuses.filter(
      status => status !== DecisionStatus.REJECTED && status !== DecisionStatus.NOGO,
    );
    decisionsStatus = filteredStatuses[0];
  }

  const mappings: StatusIconMap = {
    [DecisionStatus.PENDING]: { icon: undefined, color: 'primary' },
    [DecisionStatus.REJECTED]: { icon: <IconThumbDown size={20} />, color: 'red' },
    [DecisionStatus.TO_ANALYZE]: { icon: <IconThumbUp size={20} />, color: 'primary' },
    [DecisionStatus.GO]: { icon: <IconArrowRight size={20} />, color: 'primary' },
    [DecisionStatus.NOGO]: { icon: <IconX size={20} />, color: 'red' },
    [DecisionStatus.ANSWERED]: { icon: <IconRocket size={20} />, color: 'primary' },
    [DecisionStatus.LOSS]: { icon: <IconTrophyOff size={20} />, color: 'red' },
    [DecisionStatus.WIN]: { icon: <IconTrophy size={20} />, color: 'primary' },
  };
  return mappings[decisionsStatus];
};

const getIconStyle = (theme: MantineTheme, color: string, isNumberStep: boolean): Sx => {
  const shade = color === 'red' ? 6 : 8;
  return {
    '.mantine-Stepper-stepIcon': {
      background: isNumberStep ? theme.other.gradients.primary : theme.colors[color][1],
      border: 'none',
      color: isNumberStep ? 'white' : theme.colors[color][shade],
    },
    '.mantine-Stepper-stepCompletedIcon': {
      color: theme.colors[color][shade],
    },
  };
};

type LabelComponentProps = {
  label: string;
  subtitle: React.ReactNode;
  isNextStep: boolean;
  isPrematureEndStep: boolean;
};

const LabelComponent = ({
  label,
  subtitle,
  isNextStep,
  isPrematureEndStep,
}: LabelComponentProps) => {
  const nextStepColor = isPrematureEndStep ? 'gray.4' : 'gray.7';
  return (
    <Stack spacing="01">
      <Text variant="xs" fw={500} c={isNextStep ? nextStepColor : 'gray.9'}>
        {label}
      </Text>
      {!isNextStep && (
        <Text variant="xs" fw={400} c="gray.5">
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};

type EditSubtitleProps = {
  subtitle: string;
};

const EditSubtitle = ({ subtitle }: EditSubtitleProps) => {
  return (
    <Group spacing="00" c="gray.5">
      <Text variant="xs" fw={400}>
        {subtitle}
      </Text>
      <IconPencil size={12} />
    </Group>
  );
};
