import type { ReactElement } from 'react';
import { useCallback } from 'react';

import { Box, Group } from '@mantine/core';
import { ActionIcon, useMantineTheme } from '@mantine/core';

import { IconArrowUp, IconAt } from '@tabler/icons-react';
import type { Editor } from '@tiptap/react';

import {
  RichTextEditor,
  type RichTextEditorVariant,
  type RightSectionProps,
} from '../../../../../shared/components/UI/RichTextEditor/RichTextEditor';

import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useUsers } from '../../../../../shared/hooks/useUsers.hook';

type CommentInputProps = {
  avatar?: ReactElement;
  saveComment: (content: RichTextJson) => void;
  placeholder: string;
  variant?: RichTextEditorVariant;
  initialContent?: RichTextJson;
  showBorderOnFocus?: boolean;
};

export function CommentInput({
  avatar,
  saveComment,
  variant = 'default',
  initialContent,
  showBorderOnFocus,
}: CommentInputProps) {
  const { users } = useUsers();

  const handleSave = useCallback(
    (content: RichTextJson) => {
      saveComment(content);
    },
    [saveComment],
  );

  return (
    <Group noWrap w="100%" h="fit-content" spacing="01" align="flex-start" grow>
      <Box pt="03" sx={{ flexGrow: 0 }}>
        {avatar}
      </Box>
      <RichTextEditor
        variant={variant}
        placeholder={variant === 'default' ? 'Ajouter un commentaire' : 'Répondre...'}
        RightSection={RightSection}
        initialContent={initialContent}
        onSubmit={handleSave}
        users={users}
        showBorderOnFocus={showBorderOnFocus}
      />
    </Group>
  );
}

const RightSection = ({ editor, variant, handleSave }: RightSectionProps) => {
  const theme = useMantineTheme();
  const rightSectionBackgroundColor = editor?.isFocused
    ? theme.other.gradients.primary
    : theme.colors.gray[1];
  if (!editor) {
    return null;
  }

  const handleSubmit = (editor: Editor) => {
    if (editor) {
      handleSave && handleSave(editor.getJSON());
      editor.commands.clearContent();
    }
  };

  const handleMention = () => {
    if (editor) {
      editor.commands.insertContent('@');
    }
  };

  return (
    <Group noWrap spacing="00" maw="fit-content" p="00">
      {variant !== 'subtle' && (
        <ActionIcon c="gray.6" size="sm" onClick={handleMention}>
          <IconAt size={16} />
        </ActionIcon>
      )}
      <ActionIcon
        pt="01"
        size="sm"
        c={variant === 'subtle' ? theme.colors.primary[6] : 'white'}
        sx={theme => ({
          borderRadius: theme.radius.md,
          background: variant === 'subtle' ? 'none' : rightSectionBackgroundColor,
          ':disabled': {
            background: variant === 'subtle' ? 'none' : theme.colors.gray[1],
            color: variant === 'subtle' ? theme.colors.gray[5] : theme.colors.gray[3],
            border: 'none',
          },
        })}
        onClick={() => handleSubmit(editor)}
        disabled={editor?.getText().trim().length === 0}
      >
        <IconArrowUp size={16} />
      </ActionIcon>
    </Group>
  );
};
