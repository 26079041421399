import { useEffect, useState } from 'react';

import { Stack, Group, Box, Container, Center, Title, Text, Button } from '@mantine/core';
import type { ContextModalProps } from '@mantine/modals';
import { modals } from '@mantine/modals';

import { LogoWithText } from '../../../shared/components/UI/Logo/LogoWithText';
import { LogoWithoutText } from '../../../shared/components/UI/Logo/LogoWithoutText';

import { setOnboardingFlag } from '../../../shared/api/magellan/users';
import Animation01 from '../assets/tengo_welcome_01.webm';
import Animation02 from '../assets/tengo_welcome_02.webm';
import Animation03 from '../assets/tengo_welcome_03.webm';

type OnboardingModalProps = {
  step: number;
};

export const OnboardingModal = ({ innerProps }: ContextModalProps<OnboardingModalProps>) => {
  const { step } = innerProps;
  const [currentStep, setCurrentStep] = useState<number>(step);

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  const stepsComponents: React.ReactNode[] = [
    WelcomeStep(),
    OpportunityStep(),
    InstantAnalysisStep(),
    WinrateStep(),
  ];

  const goToNextStep = () => {
    setCurrentStep(s => s + 1);
  };

  const endOnboarding = () => {
    // no need to await and block the user
    setOnboardingFlag(true);
    modals.closeAll();
  };

  const skipButton = (
    <Text
      variant="sm"
      fw={400}
      c="gray.4"
      onClick={endOnboarding}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      Passer les explications
    </Text>
  );

  const forwardButton = (
    <Button mt="xl" radius="md" bg="blue.5" px="md" fw={600} onClick={goToNextStep}>
      Continuer
    </Button>
  );

  const endButton = (
    <Button mt="xl" radius="md" bg="blue.5" px="md" fw={600} onClick={endOnboarding}>
      Ouvrir Tengo
    </Button>
  );

  const cta = currentStep === stepsComponents.length - 1 ? endButton : forwardButton;
  return (
    <Container h="90vh" maw="100vw" p="md">
      <Center>
        <Stack w="100%" mah="80vh" align="center">
          <Container h="80vh">{stepsComponents[currentStep]}</Container>
          <Stack>
            {currentStep === 0 ? skipButton : cta}
            <Stepper step={currentStep} totalSteps={stepsComponents.length} />
          </Stack>
        </Stack>
      </Center>
    </Container>
  );
};

type StepperProps = {
  step: number;
  totalSteps: number;
};

const Stepper = ({ step, totalSteps }: StepperProps) => {
  const steps = [];
  for (let i = 0; i < totalSteps; i++) {
    steps.push(<Step position={i} step={step} key={i} />);
  }
  return (
    <Group position="center" spacing="md">
      {steps}
    </Group>
  );
};

type StepProps = {
  position: number;
  step: number;
};

const Step = ({ position, step }: StepProps) => {
  const color = position <= step ? 'blue.3' : 'blue.1';
  return <Box w="xs" h="xs" bg={color} sx={theme => ({ borderRadius: `${theme.radius.xl}` })} />;
};

const WelcomeStep = () => {
  return (
    <Stack spacing="xl" pt={240} align="center">
      <LogoWithoutText />
      <Stack spacing="xxs">
        <Title order={1} c="gray.9">
          Bienvenue sur Tengo
        </Title>
        <Text variant="sm" fw={400} color="gray.4" align="center">
          Entrez dans le monde des opportunités
        </Text>
      </Stack>
      <Button
        mt="xl"
        radius="md"
        bg="blue.5"
        px="md"
        fw={600}
        onClick={() => {
          modals.closeAll();
          modals.openContextModal({
            modal: 'onboarding',
            title: <LogoWithText />,
            fullScreen: true,
            withCloseButton: true,
            innerProps: { step: 1 },
          });
        }}
      >
        Commencer
      </Button>
    </Stack>
  );
};

const OpportunityStep = () => {
  return (
    <Stack spacing="80px" align="center">
      <Stack spacing="xxs">
        <Title order={1} c="gray.9" align="center">
          Détection des opportunités
        </Title>
        <Text variant="sm" fw={400} color="gray.4" align="center">
          Tengo centralise les opportunités d'appels d'offres accessibles grâce à vos flux
          personnalisés.
        </Text>
      </Stack>
      <VideoPlayer src={Animation01} />
    </Stack>
  );
};

const InstantAnalysisStep = () => {
  return (
    <Stack spacing="80px" align="center">
      <Stack spacing="xxs">
        <Title order={1} c="gray.9" align="center">
          Analyse instantanée
        </Title>
        <Text variant="sm" fw={400} color="gray.4" align="center">
          Bénéficiez de l'analyse appuyée par l'Intelligence Artificielle pour vous positionner très
          rapidement.
        </Text>
      </Stack>
      <VideoPlayer src={Animation02} />
    </Stack>
  );
};

const WinrateStep = () => {
  return (
    <Stack spacing="80px" align="center">
      <Stack spacing="xxs">
        <Title order={1} c="gray.9" align="center">
          Gagnez enfin plus d'appels d'offres
        </Title>
        <Text variant="sm" fw={400} color="gray.4" align="center">
          Suivez vos appels d'offres efficacement en équipe et ne ratez aucune opportunité.
        </Text>
      </Stack>
      <VideoPlayer src={Animation03} loop />
    </Stack>
  );
};

type VideoPlayerProps = { src: string; autoPlay?: boolean; controls?: boolean; loop?: boolean };

const VideoPlayer = ({
  src,
  autoPlay = true,
  controls = false,
  loop = false,
}: VideoPlayerProps) => {
  return (
    <video
      src={src}
      autoPlay={autoPlay}
      controls={controls}
      style={{ width: '100%' }}
      loop={loop}
    />
  );
};
