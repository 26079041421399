import { useQuery } from '@tanstack/react-query';

import { getRenewalTendersWithInteraction } from '../../../shared/api/magellan/interaction';
import type { DecisionRenewalStatus } from '../../../shared/entities/Interaction';

export function useTenderDecisionRenewalList(activeTabStatus: DecisionRenewalStatus.TO_ANALYZE) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [getRenewalTendersWithInteraction.name, activeTabStatus],
    queryFn: () => getRenewalTendersWithInteraction(activeTabStatus),
  });

  if (isError) {
    throw error;
  }

  const renewalTenders = data || [];
  const isRenewalLoading = isLoading;

  return { renewalTenders, isRenewalLoading };
}
