import React from 'react';

import { Grid, Stack, Title, Text } from '@mantine/core';

type ContextCardsProps = {
  additionalContext?: string;
  companyRelationWithBuyer?: string;
  companyResponseStrategy?: string;
};

export const ContextCards = ({
  additionalContext,
  companyRelationWithBuyer,
  companyResponseStrategy,
}: ContextCardsProps) => {
  return (
    <Stack
      spacing="04"
      p="05"
      bg="white"
      sx={theme => ({ border: `1px solid ${theme.colors.gray[1]}`, borderRadius: theme.radius.md })}
    >
      <Title order={4}>Contexte et Directions</Title>
      <Grid>
        <ContextCard title="Contexte du client" content={additionalContext} />
        <ContextCard title="Relation avec l'acheteur" content={companyRelationWithBuyer} />
        <ContextCard
          title="Angle de réponse & points de différenciation"
          content={companyResponseStrategy}
        />
      </Grid>
    </Stack>
  );
};

type ContextCardProps = {
  title: string;
  content?: string;
};

const ContextCard = ({ title, content }: ContextCardProps) => {
  const splitedContet = content?.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
  return (
    <Grid.Col span={4}>
      <Stack
        spacing="03"
        p="05"
        bg="gray.0"
        h="100%"
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[1]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <Text variant="sm" fw="600" c="gray.9">
          {title}
        </Text>

        <Text variant="sm" fw="400" c="gray.8" pl="02">
          {splitedContet}
        </Text>
      </Stack>
    </Grid.Col>
  );
};
