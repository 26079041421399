import { Container, Stack, Group, Image, Badge, Text } from '@mantine/core';

import type { DceRequestStatus } from '../../../../../shared/entities/DceRequestStatus';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import NoDocumentIllustration from '../../../assets/no-doccuments.svg';
import { useNoticeContext } from '../../../contexts/Notice.provider';
import { RequestDCECard } from './RequestDCECard';
import { SelfSearchCard } from './SelfSearchCard';

type EmptyDocumentContainerProps = {
  dceRequestStatus?: DceRequestStatus;
  sendDCERequest: () => void;
};

export const EmptyDocumentContainer = ({
  dceRequestStatus,
  sendDCERequest,
}: EmptyDocumentContainerProps) => {
  const { tender } = useNoticeContext();
  const { buyerProfileParticipationURL, status } = tender;
  const closedTenderComponent = (
    <Text variant="sm" fw={400} c="gray.4" align="center">
      Cet appel d'offre étant
      <Badge size="md" radius="lg" p="2px 10px 2px 10px" bg="blue.1" ml="xxs">
        <Text variant="xs" fw="600" color="dark.9">
          Fermé
        </Text>
      </Badge>
      , les documents ont été retirés de la plateforme Profil Acheteur et ne sont désormais plus
      accessibles.
    </Text>
  );

  const openTenderComponent = (
    <Group w="100%" position="center">
      <RequestDCECard dceRequestStatus={dceRequestStatus} sendDCERequest={sendDCERequest} />
      {buyerProfileParticipationURL && (
        <SelfSearchCard participationURL={buyerProfileParticipationURL} />
      )}
    </Group>
  );

  return (
    <Stack w="100%" spacing="xl">
      <Stack spacing="xl" align="center">
        <Container w={120} h={120} bg="gray.0" sx={{ borderRadius: '100%' }}>
          <Image src={NoDocumentIllustration} maw="72.9px" mah="85.8px" ml="03" mt="04" />
        </Container>
        <Text variant="sm" fw="400" c="gray.4">
          Nous n'avons pas pu récupérer les documents automatiquement sur cet appel d'offres.
        </Text>
      </Stack>
      {status === TenderStatus.CLOSED ? closedTenderComponent : openTenderComponent}
    </Stack>
  );
};
