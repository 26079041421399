import { useState } from 'react';

import { Badge, Group, Title } from '@mantine/core';

import { IconPlus } from '@tabler/icons-react';

import { Button } from '../../../shared/components/UI/Button/Button';

import { CreateBidResponseModal } from './CreateBidResponseModal';

export const Header = () => {
  const [createBidResponseModalOpenned, setCreateBidResponseModalOpenned] = useState(false);
  return (
    <Group noWrap position="apart">
      <CreateBidResponseModal
        opened={createBidResponseModalOpenned}
        closeModal={() => setCreateBidResponseModalOpenned(false)}
      />
      <Group noWrap spacing="02">
        <Title order={3}>Aide à la réponse</Title>
        <Badge color="blue">BETA</Badge>
      </Group>
      <Button leftIcon={<IconPlus />} onClick={() => setCreateBidResponseModalOpenned(true)}>
        Ajouter un appel d'offres
      </Button>
    </Group>
  );
};
