import { Box, Text } from '@mantine/core';

import { IconWand } from '@tabler/icons-react';
import { useNavigate, useLocation } from 'react-router-dom';

import { NoticeTabValues } from '../../../../../app/pages/Notice.page';
import { useSidePanelActions } from '../../../contexts/PannelAction.provider';

type InstantAnalysisPreviewCardProps = {
  question: string;
  instantAnalysisAnswerId: number;
};

export const InstantAnalysisPreviewCard = ({
  question,
  instantAnalysisAnswerId,
}: InstantAnalysisPreviewCardProps) => {
  const { closeSidePanel } = useSidePanelActions();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const searchParams = new URLSearchParams(location.search);
    const analysisTab = NoticeTabValues.ANALYZE;

    searchParams.set('tab', analysisTab);
    searchParams.set('scrollTo', instantAnalysisAnswerId.toString());
    const newUrl = `${location.pathname}?${searchParams.toString()}${location.hash}`;
    closeSidePanel();
    navigate(newUrl);
  };

  return (
    <Box
      p="2px"
      sx={theme => ({
        position: 'relative',
        display: 'inline-block',
        '::before': {
          zIndex: 1,
          content: '""',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          background: theme.other.gradients.aiLight,
          borderRadius: '10px',
        },
      })}
    >
      <Box
        p="01"
        sx={theme => ({
          ':hover': {
            cursor: 'pointer',
            boxShadow: theme.shadows.lg,
          },
          position: 'relative',
          background: 'white',
          zIndex: 2,
          borderRadius: theme.radius.md,
          boxShadow: theme.shadows.xs,
        })}
        onClick={handleClick}
      >
        <Text
          px="03"
          py="02"
          variant="xs"
          fw={500}
          c="violet.8"
          sx={theme => ({
            background: `linear-gradient(264.41deg, rgba(204, 93, 232, 0.04) 7.47%, rgba(107, 97, 230, 0.04) 47.85%, rgba(26, 101, 229, 0.04) 98.22%);
`,
            borderRadius: theme.radius.md,
          })}
        >
          <IconWand size={16} style={{ marginRight: '4px' }} />
          <span
            style={{
              verticalAlign: 'top',
            }}
          >
            {question}
          </span>
        </Text>
      </Box>
    </Box>
  );
};
