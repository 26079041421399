import { useState } from 'react';

import {
  Accordion,
  Box,
  Group,
  Menu,
  Stack,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconChevronRight, IconPencil, IconSettings, IconTrash } from '@tabler/icons-react';
import type { NavigateFunction } from 'react-router-dom';

import { Loader } from '../../../../shared/components/UI/Loader/Loader';

import { updateStreamSection } from '../../../../shared/api/magellan/streamSections';
import type Stream from '../../../../shared/entities/Stream';
import { useStreamsContext } from '../../contexts/Streams.provider';
import { useStreamsActions } from '../forms/hooks/useStreamsActions.hook';
import { SideBarNavLink } from './SideBarNavLink';

type StreamSectionAccordionProps = {
  title: string;
  streamSectionId?: number;
  streams: Stream[];
  navigate: NavigateFunction;
  chevron?: React.ReactNode;
  fullChevronRotation?: boolean;
};

export const StreamSectionAccordion = ({
  title: initialTitle,
  streamSectionId,
  streams,
  navigate,
  chevron = <IconChevronRight size={16} />,
  fullChevronRotation = false,
}: StreamSectionAccordionProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [opened, setOpened] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  return (
    <Accordion
      chevronPosition="left"
      chevron={chevron}
      defaultValue={initialTitle}
      px="xxs"
      styles={{
        control: {
          borderRadius: '8px',
        },
        label: {
          padding: '4px',
        },
        content: {
          padding: '4px 0px 0px 0px',
        },
        chevron: {
          marginRight: '4px',
          '&[data-rotate]': fullChevronRotation
            ? { transform: 'rotate(360deg)' }
            : { transform: 'rotate(90deg)' },
        },
      }}
    >
      <Accordion.Item value={initialTitle} sx={{ borderBottom: 'none', borderRadius: '8px' }}>
        <Accordion.Control p={0}>
          <Menu
            opened={opened}
            position="bottom-start"
            withinPortal
            onChange={change => !change && setOpened(false)}
          >
            <Menu.Target>
              <Group
                noWrap
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                pr={4}
              >
                <Title order={6} c="gray.7" maw={176} sx={{ whiteSpace: 'nowrap' }} truncate>
                  {title}
                </Title>
                <Box h="1px" sx={theme => ({ background: theme.colors.gray[2], flexGrow: 1 })} />
                {streamSectionId && (
                  <StreamSectionMenu
                    isParentHovered={isHovered}
                    sectionId={streamSectionId}
                    openRenameMenu={() => setOpened(true)}
                  />
                )}
              </Group>
            </Menu.Target>
            <Menu.Dropdown onClick={e => e.stopPropagation()}>
              <TextInput
                onFocus={e => {
                  const length = e.target.value.length;
                  e.target.setSelectionRange(0, length);
                }}
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                onBlur={() => {
                  streamSectionId && updateStreamSection(streamSectionId, title);
                  setOpened(false);
                }}
                onKeyDown={e => {
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpened(false);
                  }
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpened(false);
                    streamSectionId && updateStreamSection(streamSectionId, title);
                  }
                }}
              />
            </Menu.Dropdown>
          </Menu>
        </Accordion.Control>
        <Accordion.Panel>
          <Stack spacing="4px">
            {streams.map(stream => (
              <SideBarNavLink
                streamWithTenderCount={stream}
                navigate={navigate}
                isActive={location.pathname === `/flux/${stream.id}`}
                key={stream.id}
              />
            ))}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

type StreamSectionMenuProps = {
  isParentHovered: boolean;
  sectionId: number;
  openRenameMenu: () => void;
};

const StreamSectionMenu = ({
  isParentHovered,
  sectionId,
  openRenameMenu,
}: StreamSectionMenuProps) => {
  const { deleteSection } = useStreamsActions();
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const streamsContext = useStreamsContext();

  if (streamsContext.status === 'loading') {
    return <Loader title="Chargement..." />;
  }

  const openModal = () =>
    modals.openContextModal({
      modal: 'streamDelete',
      innerProps: { onDelete: () => deleteSection(sectionId) },
    });

  const handleRenameClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    openRenameMenu();
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    openModal();
  };

  if (!isParentHovered && !opened) {
    return null;
  }

  return (
    <Menu
      shadow="md"
      width={200}
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      withinPortal
    >
      <Menu.Target>
        <IconSettings
          size={16}
          stroke={1.5}
          color={theme.colors.gray[6]}
          onClick={e => e.stopPropagation()}
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          c="dark.7"
          icon={<IconPencil size={14} stroke={1.5} />}
          onClick={handleRenameClick}
        >
          Renommer
        </Menu.Item>
        <Menu.Item
          c="red.6"
          icon={<IconTrash size={14} stroke={1.5} />}
          onClick={handleDeleteClick}
        >
          Supprimer
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
