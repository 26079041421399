import { Text, Group, Divider } from '@mantine/core';

type CommentUnfoldButtonProps = {
  number: number;
  unfold: () => void;
};

export function CommentUnfoldButton({ number, unfold }: CommentUnfoldButtonProps) {
  return (
    <Group
      spacing="02"
      h="fit-content"
      onClick={unfold}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Divider
        orientation="vertical"
        mih="20px"
        my="auto"
        mx="11.5px"
        color="gray.1"
        sx={theme => ({ borderRadius: theme.radius.xl })}
      />
      <Text variant="sm" fw="500" c="gray.5">
        {`Voir ${number} commentaires`}
      </Text>
    </Group>
  );
}
