import { Box } from '@mantine/core';

type AnswerLeftBorderProps = {
  variant?: 'llm' | 'user';
};
export const AnswerLeftBorder = ({ variant = 'llm' }: AnswerLeftBorderProps) => {
  const innerGradient =
    variant === 'llm'
      ? 'linear-gradient(264.41deg, #CC5DE8 7.47%, #6B61E6 47.85%, #1A65E5 98.22%)'
      : 'linear-gradient(264.41deg, #7CC2FB 7.47%, #4EA7F7 47.85%, #1951C6 98.22%)';

  const outerGradient =
    'linear-gradient(264.41deg, rgba(204, 93, 232, 0.1) 7.47%, rgba(107, 97, 230, 0.1) 47.85%, rgba(26, 101, 229, 0.1) 98.22%)';

  return (
    <Box
      miw="6px"
      bg={outerGradient}
      py="3px"
      sx={theme => ({
        display: 'flex',
        borderRadius: theme.radius.md,
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Box
        miw="2px"
        h="100%"
        bg={innerGradient}
        sx={theme => ({
          borderRadius: theme.radius.lg,
        })}
      />
    </Box>
  );
};
