import { Stack } from '@mantine/core';

import type { DecisionStatus } from '../../../shared/entities/Interaction';
import type { DecisionStatusTab } from '../hooks/useTabs.hook';
import { ButtonTabsGroup } from './ButtonTabsGroup';

type DecisionListHeaderProps = {
  activeTabStatus?: DecisionStatusTab;
  onTabChange?: (status: DecisionStatusTab) => void;
  decisionCounts?: { [K in DecisionStatus]: number };
};

export function Header({ decisionCounts, activeTabStatus, onTabChange }: DecisionListHeaderProps) {
  return (
    <Stack
      p="05"
      spacing="05"
      sx={theme => ({
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
      })}
    >
      <ButtonTabsGroup
        decisionCounts={decisionCounts}
        activeTabStatus={activeTabStatus}
        onTabChange={onTabChange}
      />
    </Stack>
  );
}
