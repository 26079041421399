import type { MantineColor } from '@mantine/core';
import { Box, Group, Text } from '@mantine/core';
import type { MantineShade } from 'mantine-react-table';

type CriteriaProps = {
  title: string;
  weight: number;
  color: MantineColor;
  shade?: MantineShade;
  p?: string;
  maw?: string;
};

export const CriteriaWithMarker = ({ title, weight, color, shade, p, maw }: CriteriaProps) => {
  if (!shade) {
    return null;
  }
  return (
    <Group noWrap spacing="02" maw={maw} w="100%">
      <Marker color={color} shade={shade} p={p} />
      <Text variant="sm" fw={400} c="gray.6" truncate>
        {title}
      </Text>
      <Box
        h="12px"
        sx={theme => ({
          flexGrow: 1,
          borderBottom: p ? undefined : `2px dotted ${theme.colors.gray[3]}`,
        })}
      />
      <Text variant="sm" fw={p ? 400 : 500} c={p ? 'gray.6' : 'gray.9'}>
        {weight}%
      </Text>
    </Group>
  );
};

type MarkerProps = {
  color: MantineColor;
  shade: MantineShade;
  p?: string;
};

const Marker = ({ color, shade, p }: MarkerProps) => {
  return (
    <Box p={p}>
      <Box
        w="10px"
        h="10px"
        bg={`${color}.${shade}`}
        sx={theme => ({
          rotate: '45deg',
          borderRadius: theme.radius.xs,
          border: `1px solid ${theme.colors[color][shade + 1]}`,
          boxShadow:
            '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;',
        })}
      />
    </Box>
  );
};
