import { Stack } from '@mantine/core';

import type { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import { useOnTheFlyQuestionsSetsAnalysis } from '../../../shared/hooks/useOnTheFlyQuestionAnalysis.hook';
import { OnTheFlyQuestionInput } from './OnTheFlyQuestionInput';
import { QuestionsSetAccordion } from './QuestionsSetAccordion';

type OnTheFlyQuestionsProps = {
  tenderId: number;
  filter: AssessmentStatus | null;
};

export function OnTheFlyQuestions({ tenderId, filter }: OnTheFlyQuestionsProps) {
  const questionsSetAnalysis = useOnTheFlyQuestionsSetsAnalysis(tenderId);

  return (
    <Stack px="05">
      {questionsSetAnalysis.answers.length > 0 && (
        <QuestionsSetAccordion
          filter={filter}
          questionsSetTitle="Questions libres"
          initialAnswers={questionsSetAnalysis.answers}
        />
      )}

      <OnTheFlyQuestionInput
        triggerOnTheFlyQuestionAnalysis={questionsSetAnalysis.triggerOnTheFlyQuestionAnalysis}
      />
    </Stack>
  );
}
