import { Button, type MantineTheme, Text, ThemeIcon } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconMessageExclamation } from '@tabler/icons-react';

import { Counter } from '../../../../shared/components/UI/Counter/Counter';

import type { requirementEvaluation } from '../../../../shared/entities/BidResponse';
import type { FilterButtonProps } from './GridFilters';
import { MISSING_EVALUATION } from './SimulationTab';

export const FilterMissingElementsButton = ({
  onClick,
  selected = false,
  requirementEvaluation,
}: FilterButtonProps) => {
  const { hovered, ref } = useHover<HTMLButtonElement>();
  const filterMissingElements = (e: requirementEvaluation) =>
    e.retrievedAnswerFromProposal === MISSING_EVALUATION;
  const count = requirementEvaluation.filter(filterMissingElements).length;

  return (
    <Button
      ref={ref}
      variant="outline"
      radius="md"
      c="gray.9"
      disabled={!onClick}
      leftIcon={
        <ThemeIcon c="red.5" bg="transparent" size="sm">
          <IconMessageExclamation />
        </ThemeIcon>
      }
      rightIcon={
        count && (
          <Counter
            c="gray.9"
            variant="subtle"
            value={count}
            styles={(theme: MantineTheme) => ({
              root: {
                backgroundColor: `${hovered || selected ? theme.colors.primary[2] : theme.colors.gray[1]}!important`,
                color: theme.colors.gray[9],
              },
            })}
          />
        )
      }
      onClick={() => onClick && onClick(selected ? null : filterMissingElements, 'missing element')}
      styles={{
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      sx={(theme: MantineTheme) => ({
        alignContent: 'flex-start',
        border: selected
          ? `2px solid ${theme.colors.primary[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: selected ? theme.colors.primary[0] : 'white',
        ':hover': {
          backgroundColor: theme.colors.primary[0],
        },
      })}
    >
      <Text variant="sm" fw="500">
        Élements de réponse manquants
      </Text>
    </Button>
  );
};
