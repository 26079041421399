import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import type { MantineTheme } from '@mantine/core';
import { Stack, ScrollArea, Box, Text } from '@mantine/core';

import type { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';

import type { User } from '../../../entities/User';
import { UserWithAvatar } from '../UserWithAvatar/UserWithAvatar';

export type SuggestionListRef = {
  onKeyDown: NonNullable<ReturnType<NonNullable<SuggestionOptions<User>['render']>>['onKeyDown']>;
};

interface MentionNodeAttrs {
  id: string | null;
  label?: string | null;
}

type SuggestionListProps = SuggestionProps<User>;

// based on the suggestion made in this thread : https://github.com/ueberdosis/tiptap/discussions/2274
// and addapted for our use case
export const SuggestionList = forwardRef<SuggestionListRef, SuggestionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selectedIndex]);

  const selectItem = (index: number) => {
    if (index >= props.items.length) {
      return;
    }

    const suggestion = props.items[index];

    // Set all of the attributes of our Mention node based on the suggestion
    // data. The fields of `suggestion` will depend on whatever data you
    // return from your `items` function in your "suggestion" options handler.
    // Our suggestion handler returns a User (which we've
    // indicated via SuggestionProps<User>). We are passing an
    // object of the `MentionNodeAttrs` shape when calling `command` (utilized
    // by the Mention extension to create a Mention Node).
    const mentionItem: MentionNodeAttrs = {
      id: suggestion.id,
      label: `${suggestion.firstName} ${suggestion.lastName}`,
    };
    // @ts-expect-error there is currently a bug in the Tiptap SuggestionProps
    // type where if you specify the suggestion type (like
    // `SuggestionProps<User>`), it will incorrectly require that
    // type variable for `command`'s argument as well (whereas instead the
    // type of that argument should be the Mention Node attributes). This
    // should be fixed once https://github.com/ueberdosis/tiptap/pull/4136 is
    // merged and we can add a separate type arg to `SuggestionProps` to
    // specify the type of the commanded selected item.
    props.command(mentionItem);
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        event.stopPropagation();
        return true;
      }

      return false;
    },
  }));

  if (props.items.length === 0) {
    return <EmptySuggestionList />;
  }

  return (
    <ScrollArea.Autosize
      mah="320px"
      bg="white"
      w="244px"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[1]}`,
        boxShadow: theme.shadows.md,
      })}
    >
      <Stack spacing="02" p="02">
        {props.items.map((item, index) => {
          return (
            <UserWithAvatar
              bg={index === selectedIndex ? 'gray.1' : 'transparent'}
              sx={(theme: MantineTheme) => ({
                borderRadius: theme.radius.sm,
                ':hover': {
                  cursor: 'pointer',
                  background: theme.colors.gray[0],
                },
              })}
              p="02"
              key={item.id}
              ref={(el: HTMLDivElement) => (itemRefs.current[index] = el)}
              onClick={() => selectItem(index)}
              avatarColor={item.avatarColor}
              firstName={item.firstName}
              lastName={item.lastName}
              isDisabled={!!item.disabledAt}
            />
          );
        })}
      </Stack>
    </ScrollArea.Autosize>
  );
});

const EmptySuggestionList = () => (
  <Box
    mah="320px"
    bg="white"
    w="244px"
    sx={theme => ({
      borderRadius: theme.radius.md,
      border: `1px solid ${theme.colors.gray[1]}`,
      boxShadow: theme.shadows.md,
    })}
  >
    <Text variant="sm" fw={500} c="gray.9" p="02" align="center">
      Aucun utilisateur trouvé
    </Text>
  </Box>
);
