import { useEffect } from 'react';

import { Group, Stack } from '@mantine/core';

import { IconBroadcast } from '@tabler/icons-react';
import { useSuspenseQuery } from '@tanstack/react-query';

import type { MultiCheckboxOption } from '../../../../../shared/components/UI/MultiCheckbox/MultiCheckbox';

import { getStreams } from '../../../../../shared/api/magellan/stream';
import type Stream from '../../../../../shared/entities/Stream';
import type { BuyerTableFilter } from '../../TendersTabContent';
import { BuyerTableFilterInputs } from './BuyerTableFilterInputs';
import { BuyerTableFilterTags } from './BuyerTableFilterTags';
import type { TableColumn } from './columnDefinitions';

type BuyerTenderFiltersProps = {
  filters: BuyerTableFilter[];
  rowCount: number | undefined;
  onFilterChange: (filterId: BuyerTableFilterIds, value: string[]) => void;
  onFilterReset: () => void;
};

export function BuyerTableFilters({
  filters,
  rowCount,
  onFilterChange,
  onFilterReset,
}: BuyerTenderFiltersProps) {
  const { data: streamsData } = useSuspenseQuery({
    queryKey: [getStreams.name],
    queryFn: getStreams,
  });
  const filterConfig = getFilterConfig(streamsData.streams);

  useEffect(() => {
    onFilterChange(
      BuyerTableFilterIds.STREAM,
      streamsData.streams.map(stream => `${stream.id}`),
    );
  }, [onFilterChange, streamsData]);

  return (
    <Stack mx="06" pt="03" spacing="03">
      <Group spacing={0} position="apart">
        <BuyerTableFilterInputs
          filters={filters}
          filterConfig={filterConfig}
          onFilterChange={onFilterChange}
          onResetFilters={onFilterReset}
          rowCount={rowCount}
        />
      </Group>
      <BuyerTableFilterTags
        filters={filters}
        handleRemoveFilter={() => onFilterChange(BuyerTableFilterIds.STREAM, [])}
        filterConfig={filterConfig}
      />
    </Stack>
  );
}

export enum BuyerTableFilterIds {
  STREAM = 'stream',
}

export type FilterConfig = {
  filterLabel: string;
  columnId?: TableColumn;
  options: (MultiCheckboxOption & { valueLabel: string })[];
  icon: React.ReactElement;
};

const getFilterConfig = (streams: Stream[]): Record<BuyerTableFilterIds, FilterConfig> => ({
  stream: {
    filterLabel: 'Flux',
    icon: <IconBroadcast size={16} />,
    options: streams.map(stream => ({
      value: `${stream.id}`,
      label: stream.name,
      valueLabel: stream.name,
    })),
  },
});
