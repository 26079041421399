import { useEffect, useMemo, useRef, useState } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';

import { searchTenders } from '../../../../../shared/api/magellan/tender';
import type {
  DecisionRenewalStatus,
  Interaction,
} from '../../../../../shared/entities/Interaction';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import type { Filters } from '../../../../../shared/entities/StreamFilterSettings';
import type Tender from '../../../../../shared/entities/Tender';
import type { User } from '../../../../../shared/entities/User';
import type { StatusType } from '../../../../../shared/hooks/useUpsertDecision.hook';

const PAGE_SIZE = 20;
const REMAINING_TENDERS_TO_REFETCH = 8;

export type TenderWithTransition = Omit<Tender, 'interaction'> & {
  interaction?: Omit<Interaction, 'instantAnalysisStatus' | 'dceRequestStatus'>;
  mounted?: boolean;
  transitionStyle?: 'slide-left' | 'slide-right';
};

/*
 * We have a lot of known issues with infinite scrolling that are custom to our use case.
 *
 * 1. Drifting search: When a user takes decisions while scrolling, the database is updated directly. This means that there is 1 tender less in the search query results. => We have to keep an decision offset to know how many decisions have been taken and adjust the skip query parameter accordingly. This offset is kept in a hook and externalized to avoid rendering order issues where the call would be made with an outdated offset.
 * 2. Taking a decision in a given stream needs to update the remaining decision count of all the stream that are affected by the decision. Otherwise, an user could finish a stream, move to another with remaining decisions and arrive on a empty stream. => We are not solving this issue here to avoid coupling with other part of the codebase unrelated to the search. We are calling `decrementPendingDecisionCount` & `incrementPendingDecisionCount` in the `handleDecision` function of StreamListOfResultsContent.
 * 3. When a decision is taken, it needs to be reflected on both tabs (all & pending) or it would look like the decision has not been taken. => We set the garbage collection time to 0 to avoid the cache to be used when the user switch tabs.
 * 4. When the user takes all decisions, we need to refetch the stream to avoid having an empty stream screen. => we solve this issue by checking the number of remaining mounted tenders using the global variable REMAINING_TENDERS_TO_REFETCH and refetching the stream if it is below a certain threshold.
 * 5. When the user takes a decision on another tab, the tender needs to be removed from the list and the remaining tenders need to be shifted to the left. => We are using a transition style to animate the tender removal.
 *
 */
export const useSearchTenders = (filters: Filters | undefined, withDecision: boolean) => {
  const [decisionsTaken, setDecisionsTaken] = useState<number>(0);
  const [tendersWithTransition, setTendersWithTransition] = useState<TenderWithTransition[] | null>(
    null,
  );
  const [lastPage, setLastPage] = useState<number>(-1);
  const queryKey = useMemo(
    () => [searchTenders.name, filters, withDecision],
    [filters, withDecision],
  );
  const [isRefetchedData, setIsRefetchedData] = useState<boolean>(false);
  const prevIsRefetching = useRef<boolean>(false);

  // reset state on stream change
  useEffect(() => {
    setDecisionsTaken(0);
    setTendersWithTransition(null);
    setLastPage(-1);
    setIsRefetchedData(false);
  }, [filters, withDecision]);

  const queryFn = async ({ pageParam }: { pageParam: { page: number; take: number } }) => {
    const offset = pageParam.page === 0 ? 0 : decisionsTaken;
    const skip = pageParam.page * PAGE_SIZE - offset;

    if (skip < 0) {
      throw new Error('Invalid skip value, should be positive or 0');
    }

    // casting filters since it we're disabling the query when filters are undefined
    const res = await searchTenders(filters as Filters, skip, pageParam.take, withDecision);
    return { ...res, page: pageParam.page };
  };

  const { data, fetchNextPage, isRefetching, isFetching, isLoading, hasNextPage } =
    useInfiniteQuery({
      queryKey,
      queryFn,
      initialPageParam: { page: 0, take: PAGE_SIZE },
      getNextPageParam: lastPage => {
        if (lastPage.results.length < PAGE_SIZE) {
          return undefined;
        }

        return { page: lastPage.page + 1, take: 20 };
      },
      enabled: !!filters,
      gcTime: 0,
    });

  // reset state on refetch
  useEffect(() => {
    if (prevIsRefetching.current !== isRefetching) {
      if (isRefetching) {
        setDecisionsTaken(0);
        setTendersWithTransition(null);
        setLastPage(-1);
        setIsRefetchedData(false);
      } else {
        setDecisionsTaken(0);
        setTendersWithTransition(
          data?.pages.flatMap(page => page.results).map(tender => ({ ...tender, mounted: true })) ||
            null,
        );
        setLastPage(0);
        setIsRefetchedData(true);
      }
      prevIsRefetching.current = isRefetching;
    }
  }, [data?.pages, isRefetching]);

  // update tenders with transition when new data is fetched
  useEffect(() => {
    if (!data || data.pages[data.pages.length - 1].page === lastPage || isRefetchedData) {
      setIsRefetchedData(false);
      return;
    }

    setTendersWithTransition(prev => {
      if (!prev) {
        return (
          data?.pages.flatMap(page => page.results).map(tender => ({ ...tender, mounted: true })) ||
          null
        );
      }

      const newTenders = data?.pages[data.pages.length - 1].results;
      if (newTenders) {
        const newTendersWithTransition = newTenders.map(tender => ({ ...tender, mounted: true }));
        return [...prev, ...newTendersWithTransition];
      } else {
        return null;
      }
    });
    setLastPage(data?.pages[data.pages.length - 1].page);
    setIsRefetchedData(false);

    // adding filters as a dependency to reset to ensure the state is recalculated even when data?.pages is the same twice in a row (when both streams are empty for example)
  }, [data, data?.pages, filters, lastPage, isRefetchedData]);

  const updateTender = (tenderId: number, status: StatusType, currentUser: User) => {
    if (withDecision) {
      setTendersWithTransition(prev => {
        if (!prev) {
          return null;
        }
        const tenderIndex = prev.findIndex(t => t.id === tenderId);
        if (tenderIndex === -1) {
          return prev;
        }

        const updatedTenders = [...prev];
        const tenderToUpdate = updatedTenders[tenderIndex];

        updatedTenders[tenderIndex] = {
          ...tenderToUpdate,
          interaction: {
            decisionStatus:
              status.type === 'DecisionStatus'
                ? status.value
                : tenderToUpdate.interaction?.decisionStatus,
            decisionRenewalStatus:
              status.type === 'DecisionRenewalStatus'
                ? status.value
                : tenderToUpdate.interaction?.decisionRenewalStatus,
            owner: updatedTenders[tenderIndex].interaction?.owner || currentUser,
          },
        };

        return updatedTenders;
      });
    } else {
      const remainingMountedTenders = unmountTender(tenderId, status.value);
      if (remainingMountedTenders && remainingMountedTenders < REMAINING_TENDERS_TO_REFETCH) {
        fetchNextPage({ cancelRefetch: false });
      }
    }
  };

  const unmountTender = (tenderId: number, status: DecisionStatus | DecisionRenewalStatus) => {
    if (!tendersWithTransition) {
      return;
    }
    const tenderIndex = tendersWithTransition.findIndex(t => t.id === tenderId);
    if (tenderIndex === -1) {
      return;
    }

    const updatedTenders = [...tendersWithTransition];

    updatedTenders[tenderIndex] = {
      ...updatedTenders[tenderIndex],
      mounted: false,
      transitionStyle: status === DecisionStatus.REJECTED ? 'slide-right' : 'slide-left',
    };

    setTendersWithTransition(updatedTenders);
    return updatedTenders.filter(t => t.mounted).length;
  };

  const incrementDecisionsTaken = () => {
    setDecisionsTaken(prev => prev + 1);
  };

  return {
    tendersWithTransition,
    fetchNextPage: () => {
      fetchNextPage({ cancelRefetch: false });
    },
    isFetching,
    isRefetching,
    isLoading,
    updateTender,
    hasNextPage,
    incrementDecisionsTaken,
  };
};
