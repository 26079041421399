import type { ReactNode } from 'react';

import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { IconCheck } from '@tabler/icons-react';

import { useCheckboxCardContext } from './CheckboxCard.provider';

type CheckboxCardProps = {
  label?: string;
  icon: ReactNode;
  value: string;
  description: string;
  disabled?: boolean;
  bottomAction?: ReactNode;
};

export default function CheckboxCard({
  label,
  icon,
  value,
  description,
  disabled = false,
  bottomAction,
}: CheckboxCardProps) {
  const context = useCheckboxCardContext();
  const isSmallScreen = useMediaQuery('(max-width: 1340px)');

  if (!context) {
    throw new Error('CheckboxCard must be used within a CheckboxCardGroup');
  }

  const { isChecked, onCheckboxChange, isError } = context;
  const checked = isChecked(value);

  return (
    <Stack
      p="sm"
      spacing="03"
      sx={theme => ({
        ':hover': !disabled
          ? {
              backgroundColor: `${theme.colors.gray[0]}`,
              cursor: 'pointer',
            }
          : undefined,
        backgroundColor: disabled ? theme.colors.gray[1] : checked ? theme.colors.gray[0] : 'white',
        borderRadius: `${theme.radius.md}`,
        border: checked
          ? `2px solid ${theme.colors.primary[5]}`
          : `1px solid ${theme.colors.gray[3]}`,
        borderColor: isError ? `${theme.colors.red[3]}` : `${theme.colors.gray[3]}`,
      })}
      onClick={() => {
        !disabled && onCheckboxChange(value, !checked);
      }}
    >
      <Group position="apart" noWrap>
        <Group spacing={4}>
          {(!isSmallScreen || !label) && icon}
          {label && (
            <Text variant="md" fw={600}>
              {label}
            </Text>
          )}
        </Group>
        <CustomCheckboxIcon checked={checked} />
      </Group>
      <Text variant="sm" fw={400} c="gray.6">
        {description}
      </Text>
      {bottomAction && <Divider color="gray.1" />}
      {bottomAction}
    </Stack>
  );
}

const CustomCheckboxIcon = ({ checked }: { checked: boolean }) => {
  const size = 20;

  if (!checked)
    return (
      <Box
        p={0}
        miw={size}
        mih={size}
        sx={theme => ({
          border: `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.sm,
        })}
      />
    );
  return (
    <Box
      w={size}
      h={size}
      p={1}
      sx={theme => ({ backgroundColor: theme.colors.primary[5], borderRadius: theme.radius.sm })}
    >
      <IconCheck size={16} color="white" />
    </Box>
  );
};
