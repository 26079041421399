import { useState } from 'react';

import { Modal, Stack, Group, Text } from '@mantine/core';

import { Button } from '../../../../shared/components/UI/Button/Button';
import { TextArea } from '../../../../shared/components/UI/TextArea/TextArea';

import { useBidRequirement } from './useBidRequirement';
import { useProposalVersion } from './useProposalVersion.hook';

type DeletionModalProps = {
  opened: boolean;
  close: () => void;
  bidRequirementId: number;
  bidResponseId: number;
  proposalVersionId: number;
};
export const DeletionModal = ({
  opened,
  close,
  bidRequirementId,
  bidResponseId,
  proposalVersionId,
}: DeletionModalProps) => {
  const [deletionReason, setDeletionReason] = useState('');
  const { deleteBidRequirement } = useBidRequirement();
  const { refetchProposalVersion } = useProposalVersion(bidResponseId, proposalVersionId);
  const handleDelete = () => {
    deleteBidRequirement({
      bidResponseId,
      bidRequirementId,
      deletionReason,
      onSuccessCallback: () => {
        refetchProposalVersion();
      },
    });
    close();
  };

  const handleClose = () => {
    setDeletionReason('');
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title="Supprimer cette éxigence ?"
      styles={theme => ({
        body: {
          paddingTop: 0,
        },
        content: {
          borderRadius: theme.radius.md,
        },
      })}
    >
      <Stack spacing="03">
        <Text variant="sm" fw={400} c="gray.6">
          Attention cette action est irréversible.
        </Text>
        <TextArea
          value={deletionReason}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDeletionReason(event.currentTarget.value)
          }
          placeholder="Pourquoi cette éxigence n'était-elle pas pertinente pour vous ?"
        />
        <Group noWrap position="right">
          <Button variant="white" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="red" onClick={handleDelete}>
            Supprimer
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
