import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { downloadAllDocumentsAsZip } from '../../../../../shared/api/magellan/documents';
import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';

export const useDownloadAllDocumentsAsZip = (tenderTitle: string, tenderBuyerName?: string): UseMutationResult<Blob, Error, number> => {
  return useMutation({
    mutationFn: downloadAllDocumentsAsZip,
    onSuccess: (response) => {
      downloadFile(response, tenderTitle, tenderBuyerName);
    },
    onError: (error) => {
      Sentry.captureException(error);
      displayErrorNotification('Erreur', "Nous n'avons pas réussi à télécharger les documents");

      throw Error('Error while downloading documents');
    }
  });
};

// Creates an invisible link element, sets its href to the object URL of the Blob data
// triggers a click on it to download the file, and then cleans up by removing the link and revoking the URL.
const downloadFile = (data: Blob, tenderTitle: string, tenderBuyerName?: string) => {
  if (data) {
    const fileName = createFileName(tenderTitle, tenderBuyerName);
    const url = window.URL.createObjectURL(data);

    const a = document.createElement('a');
    a.href = url;
    a.style.display = 'none';
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
};


const createFileName = (tenderTitle: string, tenderBuyerName = '') => {
  const date = new Date();
  const fileNameSegments = [
    `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`,
    tenderBuyerName,
    tenderTitle
  ]
  const fileName = `${fileNameSegments.join('-')}.zip`
  
  return fileName;
}
