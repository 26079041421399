import { getStreams } from '../../../../../shared/api/magellan/stream';
import type { GetAllStreamsResponseDTO } from '../../../../../shared/api/magellan/stream/dto';
import { unmarkAsFavorite, markAsFavorite } from '../../../../../shared/api/magellan/users';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { useStreamsContext } from '../../../contexts/Streams.provider';

export function useFavorites() {
  const {
    data: {
      sidebarContent: { favorites },
    },
  } = useStreamsContext();

  const isFavorite = (streamId: number) =>
    !!favorites?.find(stream => stream.id === streamId) || false;

  const toggleFavorite = (streamId: number) => {
    queryClient.setQueryData([getStreams.name], (prev: GetAllStreamsResponseDTO) => {
      let newFavoriteIds: number[] = [];
      if (!prev) {
        return { favoriteIds: [], streams: [] };
      }
      if (isFavorite(streamId)) {
        newFavoriteIds = prev.favoriteIds.filter(id => id !== streamId);
        unmarkAsFavorite(streamId);
      } else {
        newFavoriteIds = [...prev.favoriteIds, streamId];
        markAsFavorite(streamId);
      }
      return { ...prev, favoriteIds: newFavoriteIds };
    });
  };

  return { isFavorite, toggleFavorite };
}
