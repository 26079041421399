import { Badge, Group, Text, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconDownload } from '@tabler/icons-react';

import { Button } from '../../../../../shared/components/UI/Button/Button';
import { Tooltip } from '../../../../../shared/components/UI/Tooltip/Tooltip';

import type { Document } from '../../../../../shared/entities/Document';
import CadFileIcon from '../assets/cad_file.svg?react';
import DbFileIcon from '../assets/db_file.svg?react';
import GantFileIcon from '../assets/gant_file.svg?react';
import ImageFileIcon from '../assets/image_file.svg?react';
import MiscFileIcon from '../assets/misc_file.svg?react';
import MovieFileIcon from '../assets/movie_file.svg?react';
import PdfFileIcon from '../assets/pdf_file.svg?react';
import SlideFileIcon from '../assets/slide_file.svg?react';
import SpreadsheetFileIcon from '../assets/spreadsheet_file.svg?react';
import TextFileIcon from '../assets/text_file.svg?react';
import ZipFileIcon from '../assets/zip_file.svg?react';

type DocumentComponentProps = {
  document: Document;
};

export function DocumentComponent({ document }: DocumentComponentProps) {
  const { ref, hovered } = useHover();
  const theme = useMantineTheme()

  const icon = getIcon(document.originalName);
  return (
    <Group
      ref={ref}
      noWrap
      spacing={0}
      position="apart"
      px="03"
      py="02"
      onClick={() => {
        window.open(document.fileURL, '_blank');
      }}
      sx={theme => ({
        borderRadius: theme.radius.md,
        ':hover': {
          backgroundColor: theme.colors.gray[0],
          cursor: 'pointer',
        },
      })}
    >
      <Group noWrap spacing="04" w="100%">
        {icon}
        <Badge bg="gray.1" c="gray.8" variant="filled" size="sm" ml="-8px">
          {document.type}
        </Badge>
        <Tooltip
          withArrow
          arrowSize={8}
          arrowRadius={2}
          position="bottom-start"
          arrowPosition="center"
          radius="md"
          openDelay={500}
          content={
            <Text variant="xs" fw={600}>
              {document.prettyName}
            </Text>
          }
        >
          <Text variant="sm" fw={400} c="gray.9" lineClamp={1} maw="80%">
            {document.prettyName}
          </Text>
        </Tooltip>
      </Group>
      {hovered && (
        <Button variant="default" color="primary" size="sm" leftIcon={<IconDownload color={theme.colors.primary[7]} />}>
          Télécharger
        </Button>
      )}
    </Group>
  );
}

const getIcon = (title: string) => {
  const fileExtension = title.split('.').pop();
  switch (fileExtension) {
    case 'pdf':
      return <PdfFileIcon style={{ height: '36px' }} />;
    case 'doc':
    case 'docx':
    case 'txt':
    case 'rtf':
    case 'odt':
      return <TextFileIcon style={{ height: '36px' }} />;
    case 'xls':
    case 'xlsx':
    case 'ods':
    case 'csv':
    case 'xml':
      return <SpreadsheetFileIcon style={{ height: '36px' }} />;
    case 'ppt':
    case 'pptx':
    case 'odp':
    case 'pps':
      return <SlideFileIcon style={{ height: '36px' }} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
    case 'tiff':
    case 'tif':
    case 'svg':
      return <ImageFileIcon style={{ height: '36px' }} />;
    case 'cad':
    case 'dwg':
    case 'dxf':
    case 'dwf':
    case 'pat':
      return <CadFileIcon style={{ height: '36px' }} />;
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
      return <ZipFileIcon style={{ height: '36px' }} />;
    case 'mpp':
    case 'mpx':
    case 'xer':
      return <GantFileIcon style={{ height: '36px' }} />;
    case 'mdb':
    case 'accdb':
    case 'sql':
      return <DbFileIcon style={{ height: '36px' }} />;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mp3':
    case 'wav':
    case 'aac':
    case 'flac':
      return <MovieFileIcon style={{ height: '36px' }} />;
    default:
      return <MiscFileIcon style={{ height: '36px' }} />;
  }
};
