import type { QuestionsSet } from '../../../entities/QuestionsSet';
import { magellanClient } from '../../../infra/axios';

export async function getAllQuestionsSets(): Promise<QuestionsSet[]> {
  const response = await magellanClient.get<QuestionsSet[]>(`/questionsSets`);

  return response.data;
}

export async function createQuestionsSet(
  title: string,
  questions: string[],
): Promise<QuestionsSet> {
  const response = await magellanClient.post<QuestionsSet>(`/questionsSets`, {
    title,
    questions,
  });

  return response.data;
}

export async function updateQuestionsSet(title: string, id: number): Promise<QuestionsSet> {
  const response = await magellanClient.put<QuestionsSet>(`/questionsSets/${id}`, {
    title,
  });

  return response.data;
}

export async function softDeleteQuestionsSet(id: number): Promise<void> {
  await magellanClient.delete(`/questionsSets/${id}`);
}
