import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';

import { getOnboardingFlag } from '../api/magellan/users';

type TranslationsKeys = {
  // Errors
  ERROR_NON_OPTIONAL: string;
  ERROR_EMAIL_NON_STRING: string;
  ERROR_EMAIL_INVALID: string;
  ERROR_PASSWORD_NON_STRING: string;
  ERROR_PASSWORD_TOO_SHORT: string;
  ERROR_PASSWORD_TOO_LONG: string;
  ERROR_PASSWORD_NO_ALPHA: string;
  ERROR_PASSWORD_NO_NUM: string;
  ERROR_CONFIRM_PASSWORD_NO_MATCH: string;
  SOMETHING_WENT_WRONG_ERROR: string;
  EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: string;

  // sign in with email page
  EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: string;
  EMAIL_PASSWORD_EMAIL_LABEL: string;
  EMAIL_PASSWORD_EMAIL_PLACEHOLDER: string;
  EMAIL_PASSWORD_PASSWORD_LABEL: string;
  EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: string;
  EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: string;
  EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: string;
  BRANDING_POWERED_BY_START: string;
  BRANDING_POWERED_BY_END: string;
  // Password reset page
  EMAIL_PASSWORD_RESET_HEADER_TITLE: string;
  EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: string;
  EMAIL_PASSWORD_RESET_SEND_BTN: string;
  EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: string;
  // Password reset confirmation page
  EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: string;
  EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: string;
  EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: string;
  EMAIL_PASSWORD_RESET_RESEND_LINK: string;
  EMAIL_PASSWORD_RESET_SIGN_IN_LINK: string;
  // Change password page
  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: string;
  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: string;
  EMAIL_PASSWORD_NEW_PASSWORD_LABEL: string;
  EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: string;
  EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: string;
  EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: string;
  EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: string;
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: string;
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: string;
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: string;
  //Error messages
  'This email already exists. Please sign in instead.': string;
  'Field is not optional': string;
  'Password must contain at least 8 characters, including a number': string;
  "Password's length must be lesser than 100 characters": string;
  'Password must contain at least one alphabet': string;
  'Password must contain at least one number': string;
  'Email is invalid': string;
};

const frTranslation: TranslationsKeys = {
  // Errors
  ERROR_NON_OPTIONAL: 'Champ obligatoire',
  ERROR_EMAIL_NON_STRING: 'Email invalide',
  ERROR_EMAIL_INVALID: 'Email invalide',
  ERROR_PASSWORD_NON_STRING: 'Mot de passe invalide',
  ERROR_PASSWORD_TOO_SHORT: 'Mot de passe trop court',
  ERROR_PASSWORD_TOO_LONG: 'Mot de passe trop long',
  ERROR_PASSWORD_NO_ALPHA: 'Le mot de passe doit contenir au moins 1 caratère spécial',
  ERROR_PASSWORD_NO_NUM: 'Le mot de passe doit contenir au moins 1 chiffre',
  ERROR_CONFIRM_PASSWORD_NO_MATCH: 'Les 2 mots de passe ne correspondent pas',
  SOMETHING_WENT_WRONG_ERROR: "Une erreur s'est produite",
  EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: 'Identifiant non reconnu',
  // sign in with email page
  EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: "S'identifier",
  EMAIL_PASSWORD_EMAIL_LABEL: 'Email',
  EMAIL_PASSWORD_EMAIL_PLACEHOLDER: 'jean-jacques@goldman.fr',
  EMAIL_PASSWORD_PASSWORD_LABEL: 'Mot de passe',
  EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: '**********',
  EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: 'SE CONNECTER',
  EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: 'Mot de passe oublié',
  BRANDING_POWERED_BY_END: '',
  BRANDING_POWERED_BY_START: 'Tengo avec ',
  // Password reset page
  EMAIL_PASSWORD_RESET_HEADER_TITLE: 'Mot de passe oublié ?',
  EMAIL_PASSWORD_RESET_HEADER_SUBTITLE:
    'Pas de panique, entrez votre adresse e-mail et nous vous enverrons la marche à suivre pour réinitialiser votre mot de passe.',
  EMAIL_PASSWORD_RESET_SEND_BTN: 'Envoyer ma demande',
  EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR:
    "Le lien de réinitialisation de mot de passe n'est plus valide",
  // Password reset confirmation page
  EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: '',
  EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: 'Un email a été envoyé à ',
  EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: '.',
  EMAIL_PASSWORD_RESET_RESEND_LINK: "Renvoyer ou changer d'email",
  EMAIL_PASSWORD_RESET_SIGN_IN_LINK: 'SE CONNECTER',

  // Change password page
  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: 'Réinitialisez votre mot de passe',
  EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: '',
  EMAIL_PASSWORD_NEW_PASSWORD_LABEL: 'Nouveau mot de passe',
  EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: '****************',
  EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: 'Confirmation',
  EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: '****************',
  EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: 'Valider',
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: 'Mot de passe mis à jour',
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: "Il ne vous reste plus qu'à vous connectez",
  EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: 'SE CONNECTER',
  //Errors messages
  'This email already exists. Please sign in instead.': 'Ce compte existe déjà',
  'Field is not optional': 'Champ obligatoire',
  'Password must contain at least 8 characters, including a number':
    'Le mot de passe doit contenir au moins 8 caractères dont un chiffre',
  "Password's length must be lesser than 100 characters":
    'Le mot de passe ne doit pas dépasser 100 caractères',
  'Password must contain at least one alphabet':
    'Le mot de passe doit contenir au moins une lettre',
  'Password must contain at least one number': 'Le mot de passe doit contenir au moins un chiffre',
  'Email is invalid': 'Email invalide',
};

export function init() {
  SuperTokens.init({
    appInfo: {
      // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
      appName: 'tenderx',
      apiDomain: import.meta.env.VITE_MAGELLAN_BASE_URL,
      websiteDomain: import.meta.env.VITE_LIVINGSTONE_BASE_URL,
      apiBasePath: '/auth',
      websiteBasePath: '/auth',
    },
    recipeList: [
      EmailPassword.init({
        getRedirectionURL: async context => {
          if (context.action === 'RESET_PASSWORD') {
            return '/auth/reset-password?rid=emailpassword';
          }

          const hasSeenOnboarding = await getOnboardingFlag();
          if (hasSeenOnboarding === true) {
            return '/';
          }
          return '/?onboarding=true';
        },
        style: `[data-supertokens~=container] {
                        --palette-primary: 76, 110, 245;
                        --palette-primaryBorder: 66, 99, 235;
                    }`,
        signInAndUpFeature: {
          signInForm: {
            style: `
                            [data-supertokens~=headerSubtitle] {
                                display: none;
                            }
                        `,
          },
        },
      }),
      Session.init(),
    ],
    languageTranslations: {
      translations: {
        fr: frTranslation,
      },
      defaultLanguage: 'fr',
    },
  });
}
