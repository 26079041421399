import { useEffect, useState } from 'react';

import { IconSearch } from '@tabler/icons-react';

import { TextInput } from '../../../../../shared/components/UI/TextInput/TextInput';

type GlobalSearchInputProps = {
  updateGlobalFilter: (value?: string) => void;
};
export const GlobalSearchInput = ({ updateGlobalFilter }: GlobalSearchInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    updateGlobalFilter(value);
  }, [updateGlobalFilter, value]);

  return (
    <TextInput
      variant="filled"
      size="xs"
      w={isFocused ? `300px` : `200px`}
      placeholder="Rechercher..."
      icon={<IconSearch />}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.currentTarget.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{
        transition: 'width 0.5s',
      }}
    />
  );
};
