import { useState } from 'react';

import { Group } from '@mantine/core';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getTender } from '../../../../shared/api/magellan/tender';
import { DecisionStatus } from '../../../../shared/entities/Interaction';
import type Tender from '../../../../shared/entities/Tender';
import { useUpsertDecision } from '../../../../shared/hooks/useUpsertDecision.hook';
import { BlockingPoints } from './BlockingPoints';
import { DecisionButtonsGroup } from './DecisionButtonsGroup';
import type { ActiveStatuses } from './DecisionStepper';
import { DecisionStepper } from './DecisionStepper';
import { DecisionStep } from './DecisionWidget.types';
import { EditStepConfirmModal } from './EditStepConfirmModal';

const DecisionStatusStepsMap = {
  [DecisionStatus.PENDING]: DecisionStep.EVALUATION,
  [DecisionStatus.REJECTED]: DecisionStep.EVALUATION,
  [DecisionStatus.TO_ANALYZE]: DecisionStep.GONOGO,
  [DecisionStatus.NOGO]: DecisionStep.GONOGO,
  [DecisionStatus.GO]: DecisionStep.SUBMISSION,
  [DecisionStatus.ANSWERED]: DecisionStep.RESULTS,
  [DecisionStatus.WIN]: DecisionStep.RESULTS,
  [DecisionStatus.LOSS]: DecisionStep.RESULTS,
};

type DecisionWidgetProps = {
  initialDecision: DecisionStatus;
  tenderId: number;
  participationURL?: string;
  isCollapsedHeader?: boolean;
};

export default function DecisionWidget({
  initialDecision,
  tenderId,
  participationURL,
  isCollapsedHeader = false,
}: DecisionWidgetProps) {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<DecisionStep>(
    DecisionStatusStepsMap[initialDecision],
  );
  const [editDecisionStatus, setEditDecisionStatus] = useState<ActiveStatuses | null>(null);
  const [decisionStatus, setDecisionStatus] = useState<DecisionStatus>(initialDecision);
  const upsertDecision = useUpsertDecision();

  const decisionMutation = useMutation({
    mutationFn: ({ decision, reason }: { decision: DecisionStatus; reason?: string }) =>
      upsertDecision(tenderId, { type: 'DecisionStatus', value: decision }, reason),
    onSuccess: data => {
      const queryKey = [getTender.name, tenderId];
      queryClient.setQueryData(queryKey, (prev: Tender) => {
        return { ...prev, interaction: data };
      });
    },
  });

  const upsertDecisionWithNotes = async (decision: DecisionStatus, reason?: string) => {
    if (
      decision === DecisionStatus.TO_ANALYZE ||
      decision === DecisionStatus.GO ||
      decision === DecisionStatus.ANSWERED
    ) {
      setCurrentStep(currentStep + 1);
    }
    if (decision === decisionStatus) {
      return;
    }
    setDecisionStatus(decision);
    await decisionMutation.mutateAsync({ decision, reason });
    setCurrentStep(DecisionStatusStepsMap[decision]);
  };

  const openConfirmationModal = (decision: ActiveStatuses) => {
    setEditDecisionStatus(decision);
    setIsModalOpen(true);
  };

  return (
    <Group noWrap position="apart" h="07">
      {editDecisionStatus && (
        <EditStepConfirmModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => upsertDecisionWithNotes(editDecisionStatus)}
          decision={editDecisionStatus}
        />
      )}
      {!isCollapsedHeader && (
        <DecisionStepper
          currentStep={currentStep}
          decisionStatus={decisionStatus}
          onEditDecision={openConfirmationModal}
        />
      )}
      <DecisionButtonsGroup
        currentStep={currentStep}
        onDecision={upsertDecisionWithNotes}
        decisionStatus={decisionStatus}
        participationURL={participationURL}
        blockingPointElement={isCollapsedHeader ? undefined : <BlockingPoints {...{ tenderId }} />}
        isCollapsedHeader={isCollapsedHeader}
      />
    </Group>
  );
}
