import { useState, useEffect } from 'react';

import { getTenderIsAnalysable } from '../../../shared/api/magellan/tender';
import type { NotAnalyzableReason } from '../../../shared/api/magellan/tender/dto';

type isAnalysableType = {
  isAnalysable: boolean;
  error?: NotAnalyzableReason;
};

export function useIsAnalysable(tenderId?: number) {
  const [isAnalysable, setIsAnalysable] = useState<isAnalysableType>();

  useEffect(() => {
    const fetchIsAnalysable = async (tenderId: number) => {
      const isAnalysable = await getTenderIsAnalysable(tenderId);
      setIsAnalysable(isAnalysable);
    };
    if (tenderId) {
      fetchIsAnalysable(tenderId);
    }
  }, [tenderId]);

  return isAnalysable;
}
