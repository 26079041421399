import { Stack, Title, Text, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { Button } from '../UI/Button/Button';

import { createQuestionsSet } from '../../api/magellan/instantAnalysis/questionSets';
import type { QuestionsSet } from '../../entities/QuestionsSet';
import { QuestionsInfiniteInput } from './questionsSetsInputs/QuestionInfiniteInput';

type NewQuestionSetFormValues = {
  title: string;
  questions: string[];
};

type NewQuestionsSetsModalProps = {
  onSubmit: (questionSet: QuestionsSet) => void;
  close: () => void;
};

export function NewQuestionsSetsModal({ onSubmit, close }: NewQuestionsSetsModalProps) {
  const form = useForm<NewQuestionSetFormValues>({
    initialValues: {
      title: '',
      questions: [],
    },
    validate: {
      title: value => (value ? undefined : 'Veuillez saisir un nom'),
      questions: value => (value.length > 0 ? undefined : 'Veuillez saisir au moins une question'),
    },
  });

  const handleSubmit = async (
    values: NewQuestionSetFormValues,
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    // stoping the propagation of the event to avoid triggering a submit on the parent form.
    event.stopPropagation();
    const newQuestionsSet = await createQuestionsSet(values.title, values.questions);
    onSubmit(newQuestionsSet);
  };

  const onAddQuestion = async (question: string) => {
    form.setFieldValue('questions', [...form.values.questions, question]);
  };

  const onDeleteQuestion = async (question: string) => {
    form.setFieldValue(
      'questions',
      form.values.questions.filter(q => q !== question),
    );
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack py={16} px={21} spacing="md">
        <Stack spacing={4}>
          <Title order={4}>Nouveau jeu de question</Title>
          <Text variant="sm" fw="400" c="gray.6">
            Posez les questions qui vous aideront à analyser l'appel d'offres.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <Text variant="sm" fw={700}>
            Nom du jeu de question
          </Text>
          <TextInput
            maw={200}
            placeholder="Audit énergétique..."
            color="gray.3"
            {...form.getInputProps('title')}
            styles={theme => ({
              input: {
                padding: theme.spacing.sm,
                paddingTop: theme.spacing.lg,
                paddingBottom: theme.spacing.lg,
                '::placeholder': {
                  color: theme.colors.gray[3],
                },
                color: theme.colors.gray[9],
                border: `1px solid ${theme.colors.gray[1]}`,
                borderRadius: theme.radius.md,
              },
            })}
          />
        </Stack>
        <QuestionsInfiniteInput
          {...form.getInputProps('questions')}
          onAddQuestion={onAddQuestion}
          onDeleteQuestion={onDeleteQuestion}
        />
        <Group position="right" mt="lg">
          <Button variant="subtle" onClick={close}>
            Annuler
          </Button>
          <Button type="submit" size="md">
            Enregistrer
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
