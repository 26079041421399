import { Badge, Text } from '@mantine/core';

import type { TenderStatus } from '../../../entities/Tender';

interface TenderStatusBadgeProps {
  status?: TenderStatus;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export default function TenderStatusBadge({ status, size = 'md' }: TenderStatusBadgeProps) {
  if (!status) {
    return null;
  }

  let color: string;
  let label: string;

  switch (status) {
    case 'OPEN': {
      color = 'teal';
      label = 'Ouvert';
      break;
    }

    case 'CLOSED': {
      color = 'red';
      label = 'Fermé';
      break;
    }

    default: {
      throw new Error(`Unknown status: ${status}`);
    }
  }

  return (
    <Badge
      size={size}
      radius="lg"
      sx={theme => ({
        background: theme.colors[color][1],
        border: `1px solid ${theme.colors[color][2]}`,
      })}
    >
      <Text variant="xs" fw="600" color="gray.9" sx={{ textTransform: 'capitalize' }}>
        {label}
      </Text>
    </Badge>
  );
}
