import { Group } from '@mantine/core';
import type { MRT_ColumnDef } from 'mantine-react-table';

import { RenewalTag } from '../../../../../shared/components/UI/RenewalTag/RenewalTag';
import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { TenderTitle } from '../../../../../shared/components/UI/TenderTitle/TenderTitle';
import { UserWithAvatar } from '../../../../../shared/components/UI/UserWithAvatar/UserWithAvatar';

import type { TenderWithListData } from '../../../../../app/pages/Pipeline.page';
import type {
  AssessmentRecap,
  InstantAnalysisStatus,
} from '../../../../../shared/entities/InstantAnalysisAnswer';
import type { DecisionStatus } from '../../../../../shared/entities/Interaction';
import type { TenderStatus } from '../../../../../shared/entities/Tender';
import { formatDate } from '../../../../../shared/utils/dates';
import { AssessmentRecapPreview } from '../components/AssessmentRecapPreview';
import { DecisionActionButton } from '../components/DecisionActionButton';
import { CollaborationCell } from '../components/cells/CollaborationCell';
import { ReasonCell } from '../components/cells/ReasonCell';

export enum TableColumn {
  OPPORTUNITY = 'opportunity',
  STATUS = 'status',
  RESPONSE_DEADLINE = 'responseDeadline',
  OWNER = 'owner',
  NO_GO = 'nogoReason',
  LOSS = 'lossReason',
  WIN = 'winReason',
  CONTRIBUTIONS = 'contributions',
  ACTIONS = 'actions',
  ANSWERED_AT = 'answeredAt',
  ASSESSMENT_RECAP = 'assessmentRecap',
}

export type TableColumnWithFilter =
  | TableColumn.STATUS
  | TableColumn.OWNER
  | TableColumn.RESPONSE_DEADLINE;

export type ActiveDecisionStatus = Exclude<
  DecisionStatus,
  DecisionStatus.PENDING | DecisionStatus.REJECTED
>;

const fixedWidthProps = (width?: number) => ({
  size: width,
  maxSize: width,
  minSize: width,
  mantineTableBodyCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
  mantineTableHeadCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
});

type OpportunityCellData = {
  buyerOriginalName: string;
  logoURL?: string;
  tenderTitle: string;
  buyerId: number;
};

export const oppotunityColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Opportunité',
  id: TableColumn.OPPORTUNITY,
  accessorFn: tender => ({
    buyerOriginalName: tender.buyer.originalName,
    logoURL: tender.buyer.logoURL,
    tenderTitle: tender.title,
    buyerId: Number(tender.buyer.id),
  }),
  Cell: ({ cell }) => {
    const { buyerOriginalName, logoURL, tenderTitle } = cell.getValue<OpportunityCellData>();
    return (
      <TenderTitle
        buyerOriginalName={buyerOriginalName}
        logoURL={logoURL}
        tenderTitle={tenderTitle}
        withTooltip
        size="sm"
      />
    );
  },
};

type StatusCellData = {
  status: TenderStatus;
  isRenewal: boolean;
};

export const statusColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Status',
  id: TableColumn.STATUS,
  accessorFn: tender => ({ status: tender.status, isRenewal: tender.isRenewal }) as StatusCellData,
  enableColumnFilter: false,
  Cell: ({ cell }) => {
    const { status, isRenewal } = cell.getValue<StatusCellData>();
    return (
      <Group noWrap spacing="01">
        <TenderStatusBadge status={status} />
        {isRenewal && <RenewalTag size="xs" />}
      </Group>
    );
  },
  filterFn: (row, _columnId, filterValue: TenderStatus[]) => {
    const status = row.original.status;
    return filterValue.includes(status);
  },
  ...fixedWidthProps(120),
};

export const responseDeadlineColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Date limite de dépot',
  id: TableColumn.RESPONSE_DEADLINE,
  accessorFn: tender => formatDate(tender.responseDeadline),
  enableColumnFilter: false,
  filterFn: (row, _columnId, filterValue: string[]) => {
    const dateFilter = new Date(filterValue[0]).setHours(0, 0, 0, 0);
    if (!row.original.responseDeadline) {
      return false;
    }
    const responseDeadline = new Date(row.original.responseDeadline).setHours(0, 0, 0, 0);

    return dateFilter >= responseDeadline;
  },
  ...fixedWidthProps(140),
};

type OwnerCellData =
  | undefined
  | {
      avatarColor: string;
      firstName: string;
      lastName: string;
      disabledAt: Date | null;
    };

export const ownerColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Responsable',
  id: TableColumn.OWNER,
  accessorFn: tender => tender.interaction?.owner,
  enableColumnFilter: false,
  Cell: ({ cell }) => {
    const owner = cell.getValue<OwnerCellData>();
    if (!owner) {
      return null;
    }
    return (
      <UserWithAvatar
        avatarColor={owner.avatarColor}
        firstName={owner.firstName}
        lastName={owner.lastName}
        isDisabled={!!owner.disabledAt}
      />
    );
  },
  filterFn: (row, _columnId, filterValue: string[]) => {
    if (!row.original.interaction?.owner) {
      return false;
    }

    return filterValue.includes(row.original.interaction.owner.id);
  },
  ...fixedWidthProps(160),
};

export const nogoColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Raison de No go',
  id: TableColumn.NO_GO,
  accessorFn: tender => ({ reason: tender.interaction?.nogoReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
};

export const lossColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Raison de perte',
  id: TableColumn.LOSS,
  accessorFn: tender => ({ reason: tender.interaction?.resultReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
};

export const winColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Raison de victoire',
  id: TableColumn.WIN,
  accessorFn: tender => ({ reason: tender.interaction?.resultReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
};

type NoteCellData = {
  tenderId: number;
  note: string;
  numberOfComments: number;
};

export const getContributionsColumn: (
  onNoteChange: (note: string, tenderId: number) => void,
) => MRT_ColumnDef<TenderWithListData> = onNoteChange => ({
  header: 'Contributions',
  id: TableColumn.CONTRIBUTIONS,
  accessorFn: tender => ({
    note: tender.interaction?.note,
    tenderId: tender.id,
    numberOfComments: tender.numberOfComments,
  }),
  Cell: ({ cell }) => {
    const { tenderId, note, numberOfComments } = cell.getValue<NoteCellData>();

    return (
      <CollaborationCell
        tenderId={tenderId}
        note={note}
        numberOfComments={numberOfComments}
        onNoteChange={onNoteChange}
      />
    );
  },
  ...fixedWidthProps(160),
});

type ActionCellData = {
  tenderId: number;
  decisionStatus: ActiveDecisionStatus;
};

export const getActionsColumn: (
  onTenderDecision: (index: number, status: DecisionStatus, reason?: string) => void,
) => MRT_ColumnDef<TenderWithListData> = onTenderDecision => ({
  header: '',
  id: TableColumn.ACTIONS,
  accessorFn: tender => ({
    tenderId: tender.id,
    decisionStatus: tender.interaction?.decisionStatus,
  }),
  Cell: ({ cell }) => {
    const { tenderId, decisionStatus } = cell.getValue<ActionCellData>();
    return (
      <DecisionActionButton
        tenderId={tenderId}
        status={decisionStatus}
        onTenderDecision={onTenderDecision}
      />
    );
  },
  ...fixedWidthProps(50),
});

export const answeredAtColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Date de dépot',
  id: TableColumn.ANSWERED_AT,
  accessorFn: tender => tender.interaction?.answeredAt,
  Cell: ({ cell }) => {
    const answeredAt = cell.getValue<string>();
    return formatDate(answeredAt);
  },
  ...fixedWidthProps(140),
};

type AssessmentRecapCellData = {
  tenderId: number;
  assessmentRecap: AssessmentRecap;
  instantAnalysisStatus: InstantAnalysisStatus;
  isMissingDocuments: boolean;
};

export const assessmentRecapColumn: MRT_ColumnDef<TenderWithListData> = {
  header: 'Analyse',
  id: TableColumn.ASSESSMENT_RECAP,
  accessorFn: tender => ({
    tenderId: tender.id,
    assessmentRecap: tender.assessmentRecap,
    instantAnalysisStatus: tender.interaction?.instantAnalysisStatus,
    isMissingDocuments: tender.analyzableData?.error === 'NO_DOCUMENTS',
  }),
  Cell: ({ cell }) => {
    const { tenderId, assessmentRecap, instantAnalysisStatus, isMissingDocuments } =
      cell.getValue<AssessmentRecapCellData>();

    return assessmentRecap ? (
      <AssessmentRecapPreview
        assessmentRecap={assessmentRecap}
        instantAnalysisStatus={instantAnalysisStatus}
        instantAnalysisURL={`/notices/${tenderId}?tab=analyse`}
        isMissingDocuments={isMissingDocuments}
      />
    ) : null;
  },
  ...fixedWidthProps(70),
};
