import type { CriteraColor, JudgementCriterion } from '../../../shared/entities/BidResponse';

export type CriteriaWithChildren = JudgementCriterion & { subCriteria: JudgementCriterion[] };

export function createCriteriaTree(criteria: JudgementCriterion[]): CriteriaWithChildren[] {
  const colors: CriteraColor['color'][] = ['teal', 'blue', 'orange', 'violet'];
  const colorShades: CriteraColor['shade'][] = [1, 3, 6, 9];

  const criteriaTree: CriteriaWithChildren[] = [];
  const criteriaMap = new Map<number, JudgementCriterion[]>();

  criteria.forEach(c => {
    if (!c.parentId) {
      criteriaTree.push({
        ...c,
        color: { color: colors.pop() },
        subCriteria: [],
      });
    }
  });

  criteria.forEach(c => {
    if (c.parentId) {
      const parent = criteriaMap.get(c.parentId) || [];
      const parentColor = criteriaTree.find(parent => parent.id === c.parentId)?.color?.color;
      parent.push({
        ...c,
        color: {
          color: parentColor,
          shade: colorShades[parent.length],
        },
      });
      criteriaMap.set(c.parentId, parent);
    }
  });

  return criteriaTree.map(c => ({
    ...c,
    subCriteria: criteriaMap.get(c.id) || [],
  }));
}
