import { useEffect, useState } from 'react';

import {
  Group,
  Box,
  Stack,
  Title,
  Text,
  Progress,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import * as Sentry from '@sentry/react';
import { IconPencil, IconWand } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../../shared/components/UI/Button/Button';
import { Error } from '../../../shared/components/UI/Error/Error';

import { getAllQuestionsSets } from '../../../shared/api/magellan/instantAnalysis/questionSets';
import type { AssessmentStatus } from '../../../shared/entities/AssessmentStatus';
import type {
  InstantAnalysisAnswer,
  InstantAnalysisStatus,
} from '../../../shared/entities/InstantAnalysisAnswer';
import type { QuestionsSet } from '../../../shared/entities/QuestionsSet';
import { useScrollToElement } from '../../notice/contexts/ScrollToElement.provider';
import { AssessmentBanner } from './AssessmentBanner';
import { OnTheFlyQuestions } from './OnTheFlyQuestions';
import { QuestionsSetAccordion } from './QuestionsSetAccordion';
import { SelectQuestionsSetsModalContainer } from './SelectQuestionsSetsModalContainer';
import { Spinner } from '../../../shared/components/UI/Spinner/Spinner';



export type AnswersGroupedByQuestionSetTitle = {
  [questionSetTitle: string]: InstantAnalysisAnswer[];
};

type AnswersCardProps = {
  triggerInstantAnalysis: (questionsSetsIds: number[]) => Promise<void>;
  onRetry?: () => void;
  llmStatus: InstantAnalysisStatus | null;
  answers: AnswersGroupedByQuestionSetTitle;
  tenderId: number;
};

export default function AnswersCard({
  answers,
  llmStatus,
  triggerInstantAnalysis,
  tenderId,
  onRetry,
}: AnswersCardProps) {
  const [questionsSets, setQuestionsSets] = useState<QuestionsSet[]>([]);
  const counts = countDoneAndTotalAnswers(answers);
  const [opened, { open, close }] = useDisclosure(false);
  const [filter, setFilter] = useState<AssessmentStatus | null>(null);
  const location = useLocation();
  const { scrollToElement } = useScrollToElement();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get('scrollTo');

    if (scrollTo) {
      scrollToElement(Number(scrollTo));
      searchParams.delete('scrollTo');
      const newUrl = `${location.pathname}?${searchParams.toString()}${location.hash}`;
      navigate(newUrl, { replace: true });
    }
  }, [location.hash, location.pathname, location.search, navigate, scrollToElement]);

  useEffect(() => {
    const fetchQuestionsSets = async () => {
      const questionsSets = await getAllQuestionsSets();
      setQuestionsSets(questionsSets);
    };

    fetchQuestionsSets();
  }, []);

  return (
    <Box
      p="05"
      mb="05"
      bg="white"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      <SelectQuestionsSetsModalContainer
        questionsSets={questionsSets}
        setQuestionsSets={setQuestionsSets}
        triggerInstantAnalysis={triggerInstantAnalysis}
        opened={opened}
        close={close}
      />
      <Stack spacing="xl">
        <HeaderComponent openModal={open} />

        <Sentry.ErrorBoundary fallback={<Error withBackground />}>
          <AssessmentBanner tenderId={tenderId} filter={filter} setFilter={setFilter} />
        </Sentry.ErrorBoundary>

        {llmStatus === 'RUNNING' && (
          <LoaderComponent onRetry={onRetry} count={counts.done} total={counts.total} withText />
        )}

        <Stack spacing="04" px="05">
          {Object.keys(answers).map(questionsSet => (
            <QuestionsSetAccordion
              filter={filter}
              key={questionsSet}
              questionsSetTitle={questionsSet}
              initialAnswers={answers[questionsSet]}
            />
          ))}
        </Stack>
        <OnTheFlyQuestions tenderId={tenderId} filter={filter} />
      </Stack>
    </Box>
  );
}

const countDoneAndTotalAnswers = (
  answers: AnswersGroupedByQuestionSetTitle,
): { done: number; total: number } => {
  let count = 0;
  let total = 0;
  for (const key in answers) {
    answers[key].forEach(answer => {
      if (answer.instantAnalysisStatus === 'DONE') {
        count++;
      }
      total++;
    });
  }
  return { done: count, total };
};

type HeaderComponentProps = {
  openModal: () => void;
};

const HeaderComponent = ({ openModal }: HeaderComponentProps) => {
  const theme = useMantineTheme();

  return (
    <Group noWrap position="apart">
      <Group noWrap align="flex-start" mt="xl">
        <Box c="violet.3">
          <IconWand size={24} style={{ marginTop: '4px' }} />
        </Box>
        <Stack spacing={4}>
          <Group spacing={6}>
            <Title order={4} c="dark.9">
              Analyse instantanée
            </Title>
          </Group>
          <Text variant="sm" fw="400" color="dark.2">
            Nous cherchons les réponses à vos questions dans les documents de l'appel d'offre grâce
            à l'intelligence artificielle.
          </Text>
        </Stack>
      </Group>
      <Button
        variant="outline"
        color="gray"
        leftIcon={<IconPencil size={14} color={theme.colors.primary[8]} />}
        onClick={openModal}
      >
        Éditer mes questions
      </Button>
    </Group>
  );
};

type LoaderComponentProps = {
  onRetry?: () => void;
  count?: number;
  total?: number;
  withText?: boolean;
};

export const LoaderComponent = ({
  onRetry,
  count,
  total,
  withText = false,
}: LoaderComponentProps) => {

  return (
    <Group spacing="xxs" px="05" c="primary.5">
      {onRetry ? (
        <Button onClick={onRetry}>Réessayer</Button>
      ) : (
        <Stack spacing={4}>
          <Group noWrap spacing="xxs">
            <Spinner />
            {withText && (
              <Text variant="sm" fw="400">
                L'analyse peut prendre quelques minutes...
              </Text>
            )}
          </Group>
          {count !== undefined && total !== undefined && (
            <Stack spacing={4}>
              <Progress value={(count / total) * 100} h={3} color="blue.6" />
              <Text variant="xs" fw="400" color="gray.3">
                {`${count}/${total} questions analysées`}
              </Text>
            </Stack>
          )}
        </Stack>
      )}
    </Group>
  );
};
