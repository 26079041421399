import React from 'react';

import { Group, MediaQuery, Menu, Stack, Text, UnstyledButton } from '@mantine/core';

import { IconLogout } from '@tabler/icons-react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';

import { Avatar } from '../Avatar/Avatar';

type AvatarWithMenuProps = {
  email: string;
  firstName: string;
  lastName: string;
};

export default function AvatarWithMenu(props: AvatarWithMenuProps) {
  const navigate = useNavigate();

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Target {...props} />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          color="red"
          icon={<IconLogout size={14} />}
          onClick={async () => {
            posthog.reset();
            await signOut();
            navigate('/auth');
          }}
        >
          Déconnexion
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const Target = React.forwardRef<HTMLButtonElement, AvatarWithMenuProps>(
  ({ email, firstName, lastName, ...others }, ref) => {
    return (
      <UnstyledButton ref={ref} {...others}>
        <Group noWrap spacing="02" maw={164}>
          <Avatar size="lg" radius="md">
            {firstName[0] + lastName[0]}
          </Avatar>
          <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
            <Stack spacing={0} w={124}>
              <Text
                variant="sm"
                fw={500}
                c="gray.9"
                lineClamp={1}
              >{`${firstName} ${lastName}`}</Text>
              <Text variant="xs" fw={400} c="gray.6" sx={{ whiteSpace: 'nowrap' }} truncate>
                {email}
              </Text>
            </Stack>
          </MediaQuery>
        </Group>
      </UnstyledButton>
    );
  },
);
