import {
  Stack,
  Grid,
  Group,
  Text,
  Divider,
  useMantineTheme,
  Center,
  ActionIcon,
} from '@mantine/core';

import { IconCoin, IconClockHour8, IconMapPin, IconExternalLink } from '@tabler/icons-react';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';
import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import type Lot from '../../../../shared/entities/Lot';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { goToGmap } from '../../../../shared/utils/navigation';
import { CPVRow } from '../../../streams/components/listOfResults/TenderPreviewCard';
import { useNoticeContext } from '../../contexts/Notice.provider';

export default function LotsCard() {
  const { tender } = useNoticeContext();
  const { lots } = tender;

  if (!lots || lots.length === 0) {
    return null;
  }

  return (
    <BrandedCard title="Lots" w="100%">
      <Grid>
        {lots.map(lot => (
          <Grid.Col span="content" key={lot.title}>
            <LotCard lot={lot} />
          </Grid.Col>
        ))}
      </Grid>
    </BrandedCard>
  );
}

type LotCardProps = {
  lot: Lot;
};

const LotCard = ({ lot }: LotCardProps) => {
  const theme = useMantineTheme();

  return (
    <Stack
      spacing="04"
      w="422px"
      h="100%"
      p="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[1]}`,
        background: `linear-gradient(180deg, #F7F7F8 0%, #FFFFFF 100%);`,
      })}
    >
      <Stack spacing="01">
        <Group noWrap spacing="01">
          <Text variant="md" fw={500} c="gray.9">
            <span style={{ color: theme.colors.gray[5] }}>{`Lot ${lot.reference} ·`}</span>{' '}
            {lot.title}
          </Text>
        </Group>
        <Text variant="sm" fw={400} color="gray.6">
          {lot.description}
        </Text>
      </Stack>

      <Group noWrap w="100%" spacing="02" c="gray.4">
        <Group noWrap spacing="01">
          <IconCoin size={16} />
          <Text variant="md" fw={400} mt={2} color="gray.9">
            {lot.valueInEur ? formatCurrency(lot.valueInEur) : '-'}
          </Text>
        </Group>
        <Divider orientation="vertical" color="gray.2" h="04" my="auto" />
        <Group noWrap spacing="01">
          <IconClockHour8 size={16} />
          <Text variant="md" fw={400} mt={2} color="gray.9">
            {lot.durationInMonths ? `${lot.durationInMonths} mois` : '-'}
          </Text>
        </Group>
        <Divider orientation="vertical" color="gray.2" h="04" my="auto" />
        <Group noWrap spacing="01">
          <Center>
            <IconMapPin size={16} />
          </Center>
          {lot.executionLocationString ? (
            <Group noWrap spacing="01">
              <Text variant="md" fw={400} mt={2} color="gray.9" lineClamp={1}>
                {lot.executionLocationString}
              </Text>
              <Tooltip content={'Ouvrir Google Maps'}>
                <ActionIcon
                  size="xs"
                  color="primary"
                  onClick={() =>
                    goToGmap({
                      address: lot.executionLocationString || '',
                      city: '',
                      postalCode: '',
                      NUTS: '',
                    })
                  }
                >
                  <IconExternalLink />
                </ActionIcon>
              </Tooltip>
            </Group>
          ) : (
            <Text variant="md" fw={400} mt={2} color="gray.9" lineClamp={1}>
              -
            </Text>
          )}
        </Group>
      </Group>
      {!!lot.cpvs?.length && lot.cpvs?.length > 0 && (
        <Stack spacing="04">
          {lot.cpvs?.length && <Divider orientation="horizontal" color="gray.1" />}
          {lot.cpvs?.length > 0 && <CPVRow cpvs={lot.cpvs} />}
        </Stack>
      )}
    </Stack>
  );
};
