import { Group, Stack, Title } from '@mantine/core';

import { BuyerLogo } from '../../../shared/components/UI/BuyerLogo/BuyerLogo';

import { HeaderActionBar } from '../../../shared/components/HeaderActionBar';
import type Buyer from '../../../shared/entities/Buyer';

export const BUYER_HEADER_HEIGHT = 126;

type BuyerHeaderProps = {
  buyer: Buyer;
};

export function Header({ buyer }: BuyerHeaderProps) {
  return (
    <Stack
      spacing="04"
      mb="04"
      sx={{
        background: 'linear-gradient(180deg, #EFF8FF -5.79%, #FFFFFF 80.09%)',
      }}
    >
      <HeaderActionBar />
      <Group px="06" spacing="03">
        <BuyerLogo size="md" radius="sm" logoURL={buyer.logoURL} buyerId={Number(buyer.id)} />
        <Title order={3} c="dark.9">
          {buyer.originalName}
        </Title>
      </Group>
    </Stack>
  );
}
