import type { User } from '../../../entities/User';
import { magellanClient } from '../../../infra/axios';
import type { GetActiveUserResponseDto } from './dto';

export async function getUsers(): Promise<User[]> {
  const response = await magellanClient.get<User[]>(`/users`);

  return response.data;
}

export async function getOnboardingFlag(): Promise<boolean> {
  const { data } = await magellanClient.get<{ hasSeenOnboarding: boolean }>('/users/hasSeenOnboarding');
  return data.hasSeenOnboarding;
}

export async function setOnboardingFlag(hasSeenOnboarding: boolean): Promise<boolean> {
  const res = await magellanClient.post<boolean>('/users/hasSeenOnboarding', { hasSeenOnboarding });
  return res.data;
}

export async function markAsFavorite(streamId: number): Promise<void> {
  await magellanClient.post(`/users/${streamId}/favorite`);
}

export async function unmarkAsFavorite(streamId: number): Promise<void> {
  await magellanClient.delete(`/users/${streamId}/favorite`);
}

export async function getActiveUser(): Promise<GetActiveUserResponseDto> {
  const response = await magellanClient.get<GetActiveUserResponseDto>(`/users/me`);
  return response.data;
}
