import { Group, Header, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { Link } from 'react-router-dom';

import {
  BuyerSearchBar,
  BuyerSearchButton,
} from '../../../../features/buyer/components/BuyerSearchBar';
import type { User } from '../../../entities/User';
import { LogoWithText } from '../Logo/LogoWithText';
import AvatarWithMenu from './AvatarWithMenu';
import type { NavbarLinkProps } from './NavbarLink';
import NavbarLink from './NavbarLink';

export const NAVBAR_HEIGHT = 60;

export type NavabarProps = {
  links: NavbarLinkProps[];
  user: User;
};

export function Navbar({ links, user: { firstName, lastName, email } }: NavabarProps) {
  const items = links.map(link => <NavbarLink {...link} key={link.label} />);
  const theme = useMantineTheme();
  const isLargerThanMd = useMediaQuery(`(min-width: ${theme.breakpoints.md})`);

  return (
    <Header
      height={NAVBAR_HEIGHT}
      px="04"
      sx={theme => ({
        borderBottom: `1px solid ${theme.fn.rgba(theme.colors.gray[9], 0.08)}`,
        boxShadow: `${theme.shadows.xs}`,
      })}
    >
      <Group noWrap w="100%" h="100%" spacing="04">
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <LogoWithText />
        </Link>
        <Group noWrap position="apart" w="100%">
          <Group noWrap spacing="03">
            {items}
          </Group>
          <Group noWrap px="02">
            {isLargerThanMd ? <BuyerSearchBar /> : <BuyerSearchButton />}
            <AvatarWithMenu firstName={firstName} lastName={lastName} email={email} />
          </Group>
        </Group>
      </Group>
    </Header>
  );
}
