import { Suspense } from 'react';

import * as reactRouterDom from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';

import { StreamsProvider } from '../features/streams/contexts/Streams.provider';
import { AppWithLogin } from './App';
import { BidResponsePage } from './pages/BidResponse.page';
import BuyerPage from './pages/Buyer.page';
import { BuyerSimulationPage } from './pages/BuyerSimulation.page';
import CreateStreamPage from './pages/CreateStreamPage';
import NoticePage from './pages/Notice.page';
import PageNotFound from './pages/PageNotFound';
import PipelinePage, { DecisionListLoader } from './pages/Pipeline.page';
import StreamPage from './pages/Stream.page';
import UpdateStreamPage from './pages/UpdateStreamPage';

const { Routes, Route, Navigate, useParams } = reactRouterDom;

export function Router() {
  return (
    <Routes>
      {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI])}
      <Route
        path="/"
        element={
          <SessionAuth>
            <AppWithLogin />
          </SessionAuth>
        }
      >
        {/* HOME PAGE */}
        <Route path="/" element={<Navigate to={`/flux`} />} />

        {/* VIEWS/STREAM REDIRECTIONS PAGES */}
        <Route path="/views" element={<Navigate to="/flux" />} />
        <Route path="/views/:id" element={<RedirectWithParams to="/flux" />} />
        <Route path="/stream" element={<Navigate to="/flux" />} />
        <Route path="/stream/:id" element={<RedirectWithParams to="/flux" />} />

        {/* PIPELINE REDIRECTIONS PAGES */}
        <Route
          path="/tenders/:tabParam?"
          element={<RedirectWithParams to="/pipeline" paramNames={['tabParam']} />}
        />

        {/* STREAMS PAGES */}
        <Route element={<StreamsProvider />}>
          <Route path="/flux/:id/edit" element={<UpdateStreamPage />} />
          <Route path="/flux/create" element={<CreateStreamPage />} />
          <Route path="/flux" element={<StreamPage />} />
          <Route path="/flux/:id" element={<StreamPage />} />
        </Route>

        {/* STREAMS PAGES */}
        <Route path="/buyer/:buyerId" element={<BuyerPage />} />

        {/* RESPONSE PAGES */}
        <Route path="/reponse" element={<BidResponsePage />} />
        <Route path="/reponse/:id" element={<BuyerSimulationPage />} />

        {/* NOTICES PAGES */}
        {/* tabParam is deprecated. Leaving it to ensure users are not redirected to a 404 */}
        <Route path="/notices/:id/:tabParam?" element={<NoticePage />} />

        {/* PIPELINE PAGES */}
        <Route
          path="/pipeline/:tabParam?"
          element={
            <Suspense fallback={<DecisionListLoader withHeader />}>
              <PipelinePage />
            </Suspense>
          }
        />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

const RedirectWithParams = ({
  to,
  query,
  paramNames = ['id'],
}: {
  to: string;
  query?: string;
  paramNames?: string[];
}) => {
  const params = useParams();
  const pathParams = paramNames.map(name => params[name]).join('/'); // Build path from params
  const queryString = query ? `?${query}` : '';

  return <Navigate to={`${to}/${pathParams}${queryString}`} replace />;
};
