import { Group, Divider, Stack } from '@mantine/core';

import { RichTextEditor } from '../../../../../shared/components/UI/RichTextEditor/RichTextEditor';

import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useUsers } from '../../../../../shared/hooks/useUsers.hook';

type CommentContentProps = {
  content: RichTextJson;
  PreviewCard?: React.ReactNode;
};

export function CommentContent({ content, PreviewCard }: CommentContentProps) {
  const { users } = useUsers();
  return (
    <Group noWrap spacing="02" h="100%">
      <Divider
        orientation="vertical"
        mx="11.5px"
        color="gray.1"
        sx={theme => ({ borderRadius: theme.radius.xl })}
      />
      <Stack spacing="01">
        {PreviewCard}
        <RichTextEditor variant="subtle" initialContent={content} users={users} editable={false} />
      </Stack>
    </Group>
  );
}
