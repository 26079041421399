import { useEffect, useState } from 'react';

import { Box, Group, Stack } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';

import { useMutation } from '@tanstack/react-query';

import { TenderTitle } from '../../../shared/components/UI/TenderTitle/TenderTitle';

import { getTender } from '../../../shared/api/magellan/tender';
import { RenewalActionButtons } from '../../../shared/components/RenewalActionButton';
import { DecisionRenewalStatus, DecisionStatus } from '../../../shared/entities/Interaction';
import type Tender from '../../../shared/entities/Tender';
import { useUpsertDecision } from '../../../shared/hooks/useUpsertDecision.hook';
import { queryClient } from '../../../shared/infra/queryClient';
import { useNoticeContext } from '../contexts/Notice.provider';
import DecisionWidget from './decisionWidget/DecisionWidget';

const COLLAPSED_NOTICE_HEADER_HEIGHT = 53;
const FULL_NOTICE_HEADER_HEIGHT = 230;

export function Header() {
  const { tender } = useNoticeContext();
  const [scroll] = useWindowScroll();
  const scrollY = scroll.y;
  const [collapseHeader, setCollapseHeader] = useState<boolean>(false);

  useEffect(() => {
    // Hysteresis effect to avoid flickering
    // If the header is already collapsed, we only expand it when the scroll is at the top
    // If the header is not collapsed, we only collapse it when the scroll is past the threshold
    setCollapseHeader(prev => {
      if (prev) {
        return scrollY !== 0;
      }
      return scrollY >= FULL_NOTICE_HEADER_HEIGHT - COLLAPSED_NOTICE_HEADER_HEIGHT;
    });
  }, [collapseHeader, scrollY]);

  return (
    <Stack
      spacing={0}
      h="fit-content"
      sx={theme => ({
        background: 'white',
        zIndex: 10,
        borderRadius: theme.radius.md,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
      })}
    >
      {collapseHeader ? <CollapsedHeader tender={tender} /> : <FullHeightHeader tender={tender} />}
    </Stack>
  );
}

type HeaderProps = {
  tender: Tender;
};

const FullHeightHeader = ({ tender }: HeaderProps) => {
  return (
    <Stack spacing="04" p="05">
      <Group noWrap position="apart">
        <TenderTitle
          tenderTitle={tender.title}
          buyerId={Number(tender.buyer.id)}
          buyerOriginalName={tender.buyer.originalName}
          logoURL={tender.buyer.logoURL}
          size="lg"
          withExternalLinkIcon
        />
        {tender.isRenewal && (
          <RenewalDecisionWidget
            tenderId={tender.id}
            renewalDecision={
              tender.interaction?.decisionRenewalStatus || DecisionRenewalStatus.PENDING
            }
          />
        )}
      </Group>
      {!tender.isRenewal && (
        <DecisionWidget
          tenderId={tender.id}
          initialDecision={tender.interaction?.decisionStatus || DecisionStatus.PENDING}
          participationURL={tender.buyerProfileParticipationURL}
        />
      )}
    </Stack>
  );
};

const CollapsedHeader = ({ tender }: HeaderProps) => {
  return (
    <Group noWrap px="05" pt="04" pb="03" position="apart" maw="100%">
      <Box w="80%">
        <TenderTitle
          tenderTitle={tender.title}
          buyerId={Number(tender.buyer.id)}
          buyerOriginalName={tender.buyer.originalName}
          logoURL={tender.buyer.logoURL}
          size="xs"
          withExternalLinkIcon
        />
      </Box>
      {tender.isRenewal ? (
        <RenewalDecisionWidget
          tenderId={tender.id}
          renewalDecision={
            tender.interaction?.decisionRenewalStatus || DecisionRenewalStatus.PENDING
          }
        />
      ) : (
        <DecisionWidget
          isCollapsedHeader
          tenderId={tender.id}
          initialDecision={tender.interaction?.decisionStatus || DecisionStatus.PENDING}
          participationURL={tender.buyerProfileParticipationURL}
        />
      )}
    </Group>
  );
};

type RenewalDecisionWidgetProps = {
  tenderId: number;
  renewalDecision: DecisionRenewalStatus;
};

function RenewalDecisionWidget({ tenderId, renewalDecision }: RenewalDecisionWidgetProps) {
  const upsertDecision = useUpsertDecision();

  const decisionRenewalMutation = useMutation({
    mutationFn: ({ decision }: { decision: DecisionRenewalStatus; reason?: string }) =>
      upsertDecision(tenderId, { type: 'DecisionRenewalStatus', value: decision }),
    onSuccess: data => {
      const queryKey = [getTender.name, tenderId];
      queryClient.setQueryData(queryKey, (prev: Tender) => {
        return { ...prev, interaction: data };
      });
    },
  });

  return (
    <RenewalActionButtons
      decisionRenewalStatus={renewalDecision}
      onTenderDecision={async decision => await decisionRenewalMutation.mutateAsync({ decision })}
    />
  );
}
