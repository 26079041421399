import type { NoticeSidePanelTabValues } from '../../../features/collaboration/components/Header';
import type { PreviousPageData } from '../../components/HeaderActionBar';
import { encodeObjectToBase64, removeEmoji } from '../strings';

export enum UrlHashTypes {
  LOCATION_DATA = 'LOCATION_DATA',
  SIDEPANEL_ACTION = 'SIDEPANEL_ACTION',
}
export type UrlHashValues = {
  [UrlHashTypes.LOCATION_DATA]: PreviousPageData;
  [UrlHashTypes.SIDEPANEL_ACTION]: NoticeSidePanelTabValues;
};

export type UrlHashObject = Partial<Record<UrlHashTypes, any>>;

export class UrlHashBuilder {
  private urlHash: UrlHashObject;

  constructor() {
    this.urlHash = {};
  }

  public addLocationData(): UrlHashBuilder {
    const documentTitle = document.title;
    const locationData: PreviousPageData = {
      pathname: location.pathname,
      title: removeEmoji(documentTitle),
    };

    this.urlHash[UrlHashTypes.LOCATION_DATA] = locationData;
    return this;
  }

  // If you update this method, make sure to update hard encoded hash in magellan's sendgridService.sendMentionInCommentEmail
  public addSidePanelActionData(tabToOpen: NoticeSidePanelTabValues): UrlHashBuilder {
    this.urlHash[UrlHashTypes.SIDEPANEL_ACTION] = tabToOpen;
    return this;
  }

  public build(): string {
    return encodeObjectToBase64(this.urlHash);
  }
}
