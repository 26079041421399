import { useEffect, useState } from 'react';

import { Stack } from '@mantine/core';

import { useParams } from 'react-router-dom';

import { Loader } from '../../shared/components/UI/Loader/Loader';
import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import { ContactsTabContent } from '../../features/buyer/components/ContactsTabContent';
import { BUYER_HEADER_HEIGHT, Header } from '../../features/buyer/components/Header';
import { TendersTabContent } from '../../features/buyer/components/TendersTabContent';
import { getBuyerById } from '../../shared/api/magellan/buyer';
import type Buyer from '../../shared/entities/Buyer';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';

type TabValues = 'opportunités' | 'contacts';
const Tabs = TabsComponents<TabValues>();

type PageParams = {
  buyerId: string;
  tabValue?: string;
};

export default function BuyerPage() {
  const [buyer, setBuyer] = useState<Buyer>();
  useSetPageTitle(buyer?.originalName);
  const { buyerId } = useParams<PageParams>() as PageParams; // Casting to enforce type. See https://github.com/remix-run/react-router/issues/8498

  useEffect(() => {
    async function magellanApiCall() {
      if (!buyerId) {
        return;
      }
      const res = await getBuyerById(buyerId);
      setBuyer(res);
    }
    magellanApiCall();
  }, [buyerId]);

  if (!buyer) {
    return <Loader />;
  }

  return (
    <Stack
      spacing={0}
      mah={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      w="100%"
      sx={{ overflow: 'hidden' }}
    >
      <Header buyer={buyer} />

      <Tabs defaultTab="opportunités" h={`calc(100vh - ${NAVBAR_HEIGHT + BUYER_HEADER_HEIGHT}px)`}>
        <Tabs.Button value="opportunités" label="Opportunités" />
        <Tabs.Button value="contacts" label="Contacts" />

        <Tabs.Content value="opportunités">
          <TendersTabContent buyerId={Number(buyerId)} />
        </Tabs.Content>
        <Tabs.Content value="contacts">
          <ContactsTabContent buyerId={Number(buyerId)} />
        </Tabs.Content>
      </Tabs>
    </Stack>
  );
}
