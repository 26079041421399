import { DateTime } from 'luxon';

export function formatDate(date?: string) {
  if (!date) {
    return null;
  }
  return DateTime.fromISO(date).toLocaleString();
}

export function formatDateAsText(date?: string, dayOnly = false, truncateMonth = true) {
  if (!date) {
    return DateTime.now().toLocaleString({
      day: '2-digit',
      month: truncateMonth ? 'short' : 'numeric',
      year: dayOnly ? undefined : 'numeric',
    });
  }
  return DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: truncateMonth ? 'short' : 'long',
    year: dayOnly ? undefined : 'numeric',
  });
}
