import { useState } from 'react';

import {
  type Sx,
  Select as MantineSelect,
  useMantineTheme,
  type MantineTheme,
  type SelectItem,
} from '@mantine/core';

import { IconChevronUp, IconSelector } from '@tabler/icons-react';

import type { InputWrapperProps } from '../InputWrapper/InputWrapper';
import { InputWrapper } from '../InputWrapper/InputWrapper';

type Size = 'xs' | 'sm' | 'md' | 'lg';
type Variant = 'filled' | 'default';
type Styles = {
  [key: string]: string | number | Styles;
};
type InputProps = {
  size?: Size;
  variant?: Variant;
  sx?: Sx;
  disabled?: boolean;
  isError?: boolean;
  withoutResultBorder?: boolean;
  dropdownWidth?: string;
  data: readonly (string | SelectItem)[];
  [key: string]: any; // allow any other props
};

export type SelectProps = Omit<InputWrapperProps, 'children'> & InputProps;

export function Select({ label, description, error, disabled, required, ...rest }: SelectProps) {
  return (
    <InputWrapper
      label={label}
      description={description}
      required={required}
      error={error}
      disabled={disabled}
    >
      <SelectWithoutWrapper {...rest} />
    </InputWrapper>
  );
}

function SelectWithoutWrapper({
  size = 'md',
  variant = 'default',
  disabled = false,
  onFocus,
  onBlur,
  sx,
  isError,
  data,
  clearable = true,
  withoutResultBorder = false,
  dropdownWidth,
  ...rest
}: InputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const theme = useMantineTheme();
  const sizeParams = getSizeParams(theme)[size];
  const variantParams = getVariantParams(theme)[variant];

  const errorBorder = isError && `2px solid ${theme.colors.red[5]}`;
  const styles: Styles = {
    input: {
      minHeight: sizeParams.height,
      height: sizeParams.height,
      padding: 0,
      border: 'none',
      background: 'transparent',
      margin: 'auto',
      '::placeholder': {
        color: variantParams.placeholder,
        fontWeight: 400,
        fontSize: theme.fontSizes.sm,
      },
      ':disabled': {
        background: 'transparent',
        color: theme.colors.gray[3],
      },
      '&[data-with-icon]': {
        paddingLeft: 0,
      },
    },
    icon: {
      position: 'relative',
      height: theme.spacing['04'],
      width: theme.spacing['04'],
      margin: 'auto',
      marginRight: theme.spacing['02'],
      color: theme.colors.gray[5],
    },
    rightSection: {
      position: 'relative',
      height: theme.spacing['04'],
      width: theme.spacing['04'],
      margin: 'auto',
      marginLeft: theme.spacing['02'],
      color: theme.colors.gray[5],
    },
    wrapper: {
      display: 'flex',
      background: disabled
        ? theme.colors.gray[0]
        : isFocused
          ? variantParams.focus.background
          : variantParams.background,
      borderRadius: theme.radius.md,
      outline: disabled
        ? `none`
        : errorBorder || (isFocused ? variantParams.focus.border : variantParams.border),
      height: '100%',
      paddingRight: sizeParams.paddingX,
      paddingLeft: sizeParams.paddingX,
      paddingTop: '3px',
      paddingBottom: '3px',
      ':hover': disabled
        ? {}
        : {
            background: variantParams.hover.background,
            outline:
              errorBorder || (isFocused ? variantParams.focus.border : variantParams.hover.border),
          },
    },
    dropdown: {
      border: withoutResultBorder ? 'none' : `1px solid ${theme.colors.gray[1]}`,
      width: `${dropdownWidth}!important`,
      boxShadow: theme.shadows.md,
    },
  };

  const handleFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };

  return (
    <MantineSelect
      data={data}
      clearable={clearable}
      styles={styles}
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      rightSection={isFocused ? <IconChevronUp /> : <IconSelector />}
      sx={sx}
      {...rest}
    />
  );
}

type SizeParams = {
  paddingX: string;
  height: string;
};

const getSizeParams = (theme: MantineTheme): Record<Size, SizeParams> => ({
  xs: { paddingX: theme.spacing['02'], height: '26px' },
  sm: { paddingX: theme.spacing['03'], height: '32px' },
  md: { paddingX: theme.spacing['03'], height: '36px' },
  lg: { paddingX: theme.spacing['03'], height: '40px' },
});

type VariantParams = {
  placeholder: string;
  color: string;
  background: string;
  border: string;
  hover: {
    background: string;
    border: string;
  };
  focus: {
    background: string;
    border: string;
  };
};

const getVariantParams = (theme: MantineTheme): Record<Variant, VariantParams> => ({
  filled: {
    placeholder: theme.colors.gray[4],
    color: theme.colors.gray[9],
    background: theme.colors.gray[0],
    border: `none`,
    hover: {
      background: theme.colors.gray[1],
      border: `none`,
    },
    focus: {
      background: 'white',
      border: `2px solid ${theme.colors.blue[6]}`,
    },
  },
  default: {
    placeholder: theme.colors.gray[4],
    color: theme.colors.gray[9],
    background: 'white',
    border: `2px solid ${theme.colors.gray[2]}`,
    hover: {
      background: 'white',
      border: `2px solid ${theme.colors.gray[3]}`,
    },
    focus: {
      background: 'white',
      border: `2px solid ${theme.colors.blue[6]}`,
    },
  },
});
