import { Paper, Stack, Button, Text, Title } from '@mantine/core';

import { IconCheck, IconFileImport } from '@tabler/icons-react';

import { DceRequestStatus } from '../../../../../shared/entities/DceRequestStatus';

type RequestDCECardProps = {
  dceRequestStatus?: DceRequestStatus;
  sendDCERequest: () => void;
};

export const RequestDCECard = ({ dceRequestStatus, sendDCERequest }: RequestDCECardProps) => {
  const requestText = {
    subtitle: 'Nous allons chercher les documents pour vous.',
    button: 'Demander les documents',
  };

  const searchingText = {
    subtitle: 'Nous cherchons les documents pour vous.',
    button: 'Documents demandés',
  };

  const text = dceRequestStatus === DceRequestStatus.IN_SEARCH ? searchingText : requestText;
  return (
    <Paper
      p="xl"
      shadow="lg"
      radius="sm"
      sx={theme => ({ border: `1px solid ${theme.colors.gray[0]}` })}
    >
      <Stack align="center">
        <Title order={4} c="gray.5">
          Demander à Tengo
        </Title>
        <Text variant="sm" fw="400" c="gray.4">
          {text.subtitle}
        </Text>
        <Button
          radius="md"
          size="sm"
          disabled={dceRequestStatus !== DceRequestStatus.WAITING}
          leftIcon={dceRequestStatus !== DceRequestStatus.IN_SEARCH && <IconFileImport size={16} />}
          rightIcon={dceRequestStatus === DceRequestStatus.IN_SEARCH && <IconCheck size={16} />}
          onClick={sendDCERequest}
        >
          {text.button}
        </Button>
      </Stack>
    </Paper>
  );
};
