import { Button, Box, type MantineTheme, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { Counter } from '../../../../shared/components/UI/Counter/Counter';

import type { requirementEvaluation } from '../../../../shared/entities/BidResponse';
import type { CriteriaWithChildren } from '../../utils/createCriteriaTree';
import type { FilterButtonProps } from './GridFilters';

export const FilterCriteriaButton = ({
  onClick,
  criteriaWithChildren,
  selected = false,
  requirementEvaluation,
}: FilterButtonProps & {
  criteriaWithChildren: CriteriaWithChildren;
}) => {
  const { hovered, ref } = useHover<HTMLButtonElement>();
  const filterMissingElements = (e: requirementEvaluation) =>
    e.bidRequirement.judgementCriterionId === criteriaWithChildren.id ||
    criteriaWithChildren.subCriteria.some(sub => sub.id === e.bidRequirement.judgementCriterionId);
  const color = criteriaWithChildren.color?.color || 'gray';
  const count = requirementEvaluation.filter(filterMissingElements).length;
  if (!count) {
    return null;
  }

  return (
    <Button
      ref={ref}
      variant="outline"
      radius="md"
      c="gray.9"
      disabled={!onClick}
      leftIcon={
        <Box>
          <Box
            w="10px"
            h="10px"
            bg={`${color}.5`}
            sx={theme => ({
              rotate: '45deg',
              borderRadius: theme.radius.xs,
              border: `1px solid ${theme.colors[color][6]}`,
              boxShadow:
                '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset;',
            })}
          />
        </Box>
      }
      rightIcon={
        count && (
          <Counter
            c="gray.9"
            variant="subtle"
            value={count}
            styles={(theme: MantineTheme) => ({
              root: {
                backgroundColor: `${hovered || selected ? theme.colors.primary[2] : theme.colors.gray[1]}!important`,
                color: theme.colors.gray[9],
              },
            })}
          />
        )
      }
      onClick={() =>
        onClick && onClick(selected ? null : filterMissingElements, criteriaWithChildren.title)
      }
      styles={{
        inner: {
          justifyContent: 'flex-start',
        },
      }}
      sx={(theme: MantineTheme) => ({
        alignContent: 'flex-start',
        border: selected
          ? `2px solid ${theme.colors.primary[7]}`
          : `1px solid ${theme.colors.gray[3]}`,
        backgroundColor: selected ? theme.colors.primary[0] : 'white',
        ':hover': {
          backgroundColor: theme.colors.primary[0],
        },
      })}
    >
      <Text variant="sm" fw="500">
        {criteriaWithChildren.title}
      </Text>
    </Button>
  );
};
