import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { getStream } from '../../../../../shared/api/magellan/stream';
import type Stream from '../../../../../shared/entities/Stream';
import { useSetPageTitle } from '../../../../../shared/hooks/useSetPageTitle.hook';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { removeEmoji } from '../../../../../shared/utils/strings';

export const useStream = (id: number) => {
  const navigate = useNavigate();
  const queryKey = [getStream.name, id];
  const {
    data: stream,
    isError,
    isLoading,
    error,
  } = useQuery({
    queryKey,
    queryFn: () => getStream(id),
    enabled: id !== undefined,
    retry: false,
  });

  useSetPageTitle(`Flux - ${removeEmoji(stream?.name || '')}`);

  useEffect(() => {
    if (isError && axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        navigate(`/flux`);
      }
    }
  }, [error, isError, navigate]);

  if (isError && axios.isAxiosError(error)) {
    if (error.code === 'ERR_BAD_REQUEST') {
      return { stream: null };
    }
  }

  if (isLoading || isError || !stream) {
    return { stream: undefined, isLoading, isError, error };
  }

  const decrementPendingDecisionCount = () => {
    queryClient.setQueryData(queryKey, (prev: Stream) => {
      const pendingDecisionCount = prev.pendingDecisionCount - 1;
      if (pendingDecisionCount < 0) {
        throw new Error('Invalid pendingDecisionCount, should be positive or 0');
      }

      return {
        ...prev,
        pendingDecisionCount,
      };
    });
  };

  const incrementPendingDecisionCount = () => {
    queryClient.setQueryData(queryKey, (prev: Stream) => ({
      ...prev,
      pendingDecisionCount: prev.pendingDecisionCount + 1,
    }));
  };

  return { stream, decrementPendingDecisionCount, incrementPendingDecisionCount };
};
