import { Group, Modal, Stack, Text, Title } from '@mantine/core';

import { Button } from '../../../../shared/components/UI/Button/Button';

import { DecisionStatus } from '../../../../shared/entities/Interaction';
import type { ActiveStatuses } from './DecisionStepper';

type EditStepConfirmModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  decision: ActiveStatuses;
};

export function EditStepConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  decision,
}: EditStepConfirmModalProps) {
  const decisionTitleMap: Record<ActiveStatuses, string> = {
    [DecisionStatus.PENDING]: 'annuler la décision ?',
    [DecisionStatus.TO_ANALYZE]: ' revenir au status "À analyser" ?',
    [DecisionStatus.GO]: ' revenir au status "Go" ?',
    [DecisionStatus.ANSWERED]: ' revenir au status "Répondu" ?',
  };

  return (
    <Modal opened={isOpen} onClose={onClose} withCloseButton={false} radius="md" yOffset="12vh">
      <Stack p="04" spacing="00">
        <Title order={5}>Changer la décision</Title>
        <Text variant="sm" fw="400" c="gray.6">
          Êtes-vous sûr(e) de vouloir {decisionTitleMap[decision]}
        </Text>
        <Group position="right" mt="04">
          <Button variant="subtle" color="dark" onClick={onClose}>
            Non, annuler
          </Button>
          <Button
            size="md"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Oui, confirmer
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
