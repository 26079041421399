import { forwardRef, type ReactNode } from 'react';

import { Box, Divider, Group, Skeleton, Stack, Text, Transition } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconArrowRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { RenewalTag } from '../../../../shared/components/UI/RenewalTag/RenewalTag';
import { TenderDataDisplay } from '../../../../shared/components/UI/TenderDataDisplay/TenderDataDisplay';
import TenderStatusBadge from '../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { TenderTitle } from '../../../../shared/components/UI/TenderTitle/TenderTitle';
import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import { RenewalActionButtons } from '../../../../shared/components/RenewalActionButton';
import { useUrlHash } from '../../../../shared/contexts/UrlHash.provider';
import type { Cpv } from '../../../../shared/entities/Cpv';
import { DecisionRenewalStatus } from '../../../../shared/entities/Interaction';
import { DecisionStatus } from '../../../../shared/entities/Interaction';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { formatDateAsText } from '../../../../shared/utils/dates';
import { UrlHashBuilder } from '../../../../shared/utils/navigation/UrlHashBuilder';
import CardCorner from '../../assets/card_corner.svg';
import type { TenderWithTransition } from '../forms/hooks/useSearchTenders.hook';
import { TenderPreviewCardActionElement } from './PreviewCardActionElement';

type TenderPreviewCardProps = {
  tender: TenderWithTransition;
  onTenderDecision: (status: DecisionStatus | DecisionRenewalStatus, reason?: string) => void;
};

export function TenderPreviewCard({ tender, onTenderDecision }: TenderPreviewCardProps) {
  const decisionElement = (
    <TenderPreviewCardActionElement
      decisionStatus={tender.interaction?.decisionStatus}
      onTenderDecision={onTenderDecision}
      owner={tender.interaction?.owner}
    />
  );

  const renewalDecisionElement = (
    <RenewalActionButtons
      onTenderDecision={onTenderDecision}
      decisionRenewalStatus={tender.interaction?.decisionRenewalStatus}
      owner={tender.interaction?.owner}
    />
  );

  const actionElements = tender.isRenewal ? renewalDecisionElement : decisionElement;

  return (
    <Transition
      mounted={tender.mounted === undefined ? true : tender.mounted}
      transition={tender.transitionStyle || 'fade'}
      exitDuration={450}
      timingFunction="ease"
    >
      {style => (
        <Container
          style={style}
          decisionStatus={
            tender.isRenewal
              ? tender.interaction?.decisionRenewalStatus
              : tender.interaction?.decisionStatus
          }
          tenderId={tender.id}
        >
          <Group noWrap align="flex-start" position="apart">
            <Stack spacing="03">
              <Header tender={tender} />
              <DataRow tender={tender} />
              <CPVRow cpvs={tender.cpvs} />
              {tender.isRenewal && <RenewalTag />}
            </Stack>
            {actionElements}
          </Group>
        </Container>
      )}
    </Transition>
  );
}

type ContainerProps = {
  style: React.CSSProperties;
  tenderId: number;
  decisionStatus?: DecisionStatus | DecisionRenewalStatus;
  children: ReactNode;
};

const Container = ({ style, decisionStatus, tenderId, children }: ContainerProps) => {
  const { hovered, ref } = useHover<HTMLAnchorElement>();
  const { getRedirectUrl } = useUrlHash();

  let background: string;
  switch (decisionStatus) {
    case DecisionStatus.TO_ANALYZE:
    case DecisionStatus.GO:
    case DecisionStatus.ANSWERED:
    case DecisionStatus.WIN:
    case DecisionRenewalStatus.TO_ANALYZE:
      background = `linear-gradient(86.95deg, rgba(255, 255, 255, 0.06) 83.71%, rgba(26, 101, 229, 0.06) 110%)`;
      break;
    case DecisionStatus.REJECTED:
    case DecisionStatus.NOGO:
    case DecisionStatus.LOSS:
    case DecisionRenewalStatus.REJECTED:
      background = `linear-gradient(86.95deg, rgba(255, 255, 255, 0.06) 83.71%, rgba(205, 44, 44, 0.06) 100%)`;
      break;
    default:
      background = `linear-gradient(86.95deg, rgba(90, 91, 111, 0.06) 0%, rgba(255, 255, 255, 0.06) 10.18%)`;
  }

  return (
    <Box
      style={style}
      ref={ref}
      component={Link}
      p="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[2]}`,
        boxShadow: theme.shadows.sm,
        background,
        position: 'relative',
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
          cursor: 'pointer',
          boxShadow: theme.shadows.lg,
        },
      })}
      to={getRedirectUrl(`/notices/${tenderId}`, new UrlHashBuilder().addLocationData().build())}
    >
      {children}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          paddingTop: '14px',
          paddingLeft: '20px',
          width: '53px',
          height: '41px',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '53px',
            height: '41px',
            backgroundImage: `url(${CardCorner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            transform: hovered ? 'scale(1.4) rotate(-20deg) translateX(-4px) translateY(8px)' : '',
            transition: 'transform 0.3s',
          },
        }}
      >
        <IconArrowRight size={16} stroke={1.5} />
      </Box>
    </Box>
  );
};

type HeaderProps = {
  tender: TenderWithTransition;
};

const Header = ({ tender }: HeaderProps) => {
  return (
    <Group noWrap position="apart" align="flex-start" w="100%">
      <TenderTitle
        buyerOriginalName={tender.buyer.originalName}
        tenderTitle={tender.title}
        logoURL={tender.buyer.logoURL}
        buyerId={Number(tender.buyer.id)}
        size="md"
      />
    </Group>
  );
};

const DataRow = ({ tender }: { tender: TenderWithTransition }) => {
  return (
    <Group noWrap spacing="05">
      {/* Dates */}
      <TenderDataDisplay
        statTitle={
          <Group noWrap spacing="02">
            <Text variant="xs" fw="500" c="gray.6">
              Dates
            </Text>
            <TenderStatusBadge status={tender.status} />
          </Group>
        }
        statValue={`${formatDateAsText(tender.publicationDate, false, false)} - ${formatDateAsText(tender.responseDeadline, false, false)}`}
      />
      <DataDivider />

      {/* Amout */}
      <TenderDataDisplay
        statTitle="Montant"
        statValue={formatCurrency(tender.estimatedValueInEur) || '-'}
        isLmmEnriched={tender.isEstimatedValueInEurLLMEnriched}
      />
      <DataDivider />

      {/* Duration */}
      <TenderDataDisplay
        statTitle="Durée"
        statValue={tender.durationInMonth ? tender.durationInMonth + ' mois' : '-'}
        isLmmEnriched={tender.isDurationInMonthLLMEnriched}
      />
      <DataDivider />

      {/* Location */}
      <TenderDataDisplay
        statTitle="Lieu d'exc."
        statValue={tender.executionLocation?.city || '-'}
      />
      <DataDivider />

      {/* Lots */}
      <TenderDataDisplay statTitle="Lots" statValue={tender.lots?.length || '-'} />
    </Group>
  );
};

type CPVRowProps = {
  cpvs: Cpv[] | undefined;
};

export const CPVRow = ({ cpvs }: CPVRowProps) => {
  if (!cpvs?.length) {
    return;
  }

  return (
    <Tooltip
      content={<CPVTooltipContent cpvs={cpvs} />}
      dropDownProps={{
        bg: 'white',
        p: '02',
      }}
    >
      <Group maw="80%">
        <Text variant="xs" fw="400" c="gray.6" lineClamp={1}>
          {`CPV ·` + cpvs?.map(cpv => ` ${cpv.title}`).join(', ')}
        </Text>
      </Group>
    </Tooltip>
  );
};

export const CPVTooltipContent = forwardRef<HTMLDivElement, CPVRowProps>(({ cpvs }, ref) => {
  return (
    <Stack
      ref={ref}
      spacing="00"
      p="01"
      m={0}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      }}
    >
      <Text px="02" py="01" c="gray.6" variant="xs" fw={400}>
        CPV associés
      </Text>
      {cpvs?.map((cpv: Cpv, index: number) => {
        return (
          <Stack
            key={cpv.code}
            spacing="00"
            sx={theme => ({
              borderRadius: theme.radius.sm,
            })}
          >
            {index !== 0 && <Divider py="00" color="gray.1" />}
            <Text variant="sm" fw={500} c="gray.9" px="02">
              {cpv.title}
            </Text>
            <Text variant="xs" fw={400} c="gray.6" px="02">
              {cpv.code}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
});

const DataDivider = () => <Divider orientation="vertical" h="07" my="auto" color="gray.2" />;

export const TenderPreviewSkeletton = () => {
  return (
    <Stack spacing={24}>
      <Skeleton p={24} maw="1500px" h="250px" radius={8}></Skeleton>
      <Skeleton p={24} maw="1500px" h="250px" radius={8}></Skeleton>
    </Stack>
  );
};
