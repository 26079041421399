import React, { forwardRef, useEffect, useState } from 'react';

import {
  Stack,
  Group,
  Text,
  TextInput,
  MultiSelect,
  Box,
  CloseButton,
  Select,
  ScrollArea,
  Divider,
} from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { modals } from '@mantine/modals';

import { IconCircleDashed, IconPlus } from '@tabler/icons-react';

import {
  getStreamSections,
  createStreamSection,
} from '../../../../../shared/api/magellan/streamSections';
import { displayErrorNotification } from '../../../../../shared/components/notifications/errorNotification';
import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';

type BasicFieldsProps = {
  form: UseFormReturnType<StreamFormValues, (values: StreamFormValues) => StreamFormValues>;
};

export const BasicFields = ({ form }: BasicFieldsProps) => {
  const [searchValue, setSearchValue] = useState('');
  const keywords = form.values.keywords || [];

  return (
    <Stack>
      <Group grow w="80%" spacing={40}>
        {/* Stream title */}
        <Stack spacing="md" maw="245px">
          <Text variant="md" fw="600" c="gray.9">
            Nom de mon flux
          </Text>
          <TextInput
            placeholder="Mon Flux"
            radius="md"
            w="100%"
            {...form.getInputProps('name')}
            autoFocus
          />
        </Stack>
        {/* Section selector */}
        <StreamSectionSelector {...form.getInputProps('streamSectionId')} />
      </Group>
      {/* keywords */}
      <Stack spacing="md">
        <Text variant="md" fw="600" c="gray.9">
          Mots clés inclus dans l'appel d'offre
        </Text>
        <MultiSelect
          placeholder="Formation, informatique, béton, ..."
          data={[]}
          value={[]}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchable
          creatable
          clearable
          radius="md"
          hoverOnSearchChange
          getCreateLabel={query => `+ Ajouter ${query}`}
          onCreate={query => {
            const newKeyword = query.trim();
            if (!keywords.includes(newKeyword)) {
              form.insertListItem('keywords', newKeyword);
            }
            return query;
          }}
          onBlur={() => {
            if (searchValue) {
              form.insertListItem('keywords', searchValue);
            }
            setSearchValue('');
          }}
          rightSection={<></>}
        />
        <Group w="80%" spacing={4}>
          {keywords.map(keyword => (
            <Box
              sx={theme => ({
                border: `1px solid ${theme.colors.gray[3]}`,
                borderRadius: '8px',
              })}
              key={keyword}
            >
              <Group spacing="01" px="02" py="01">
                <Text variant="xs" fw="500" c="dark.2">
                  {keyword}
                </Text>
                <CloseButton
                  color="dark.2"
                  onClick={() => {
                    const index = keywords.indexOf(keyword);
                    form.removeListItem('keywords', index);
                  }}
                />
              </Group>
            </Box>
          ))}
        </Group>
      </Stack>
    </Stack>
  );
};

interface SelectItemProps extends React.ComponentPropsWithoutRef<'div'> {
  value: string;
  label: string;
  icon?: React.ReactNode;
  section_id: number | null;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, icon, ...others }: SelectItemProps, ref) => (
    <Box ref={ref} {...others}>
      <Group noWrap spacing="xxs">
        {icon}
        {label}
      </Group>
    </Box>
  ),
);

type StreamSectionSelectorProps = {
  value?: number | null;
  onChange: (value?: number) => void;
};

const StreamSectionSelector = ({
  value: initialId,
  onChange: updateSelectedId,
}: StreamSectionSelectorProps) => {
  const [streamSectionItems, setStreamSectionItems] = useState<SelectItemProps[]>([]);
  const [selectedSection, setSelectedSection] = useState<SelectItemProps | null>(null);

  // initial fetch
  useEffect(() => {
    const noSectionItem = {
      value: 'noSection',
      label: 'Aucune section',
      section_id: -1,
      icon: (
        <Box mt={2}>
          <IconCircleDashed size={14} stroke={2} />
        </Box>
      ),
    };

    const fetchStreamSectionTitles = async () => {
      const res = await getStreamSections();
      const items = res.map(({ title, id }) => ({ value: title, label: title, section_id: id }));
      setStreamSectionItems([noSectionItem, ...items]);
      if (initialId) {
        const selectedSection = items.find(item => item.section_id === initialId);
        setSelectedSection(selectedSection || noSectionItem);
      }
    };
    if (streamSectionItems.length === 0) {
      fetchStreamSectionTitles();
    }
  }, [initialId, streamSectionItems.length]);

  const createStreamsSections = async (title: string) => {
    try {
      const res = await createStreamSection(title);
      const newSection = {
        value: res.title,
        label: res.title,
        section_id: res.id,
      };
      setStreamSectionItems(currentItems => [...currentItems, newSection]);
      setSelectedSection(newSection);
      updateSelectedId(res.id);
      return true;
    } catch (e: any) {
      if (e.response.status === 409) {
        displayErrorNotification('Erreur', 'Cette section existe déjà');
      } else {
        displayErrorNotification(
          'Erreur',
          "Une erreur est survenue, votre section n'a pas été créée",
        );
      }
      return false;
    }
  };

  const handleSectionChange = (value: string) => {
    if (!value || value === 'noSection') {
      setSelectedSection(null);
      updateSelectedId(-1);
      return;
    }
    const selectedSection = streamSectionItems.find(item => item.value === value);

    if (!selectedSection) {
      return;
    }
    setSelectedSection(selectedSection);
    if (selectedSection.section_id) {
      updateSelectedId(selectedSection.section_id);
    }
  };

  type ChildrenType = Pick<DropdownComponentProps, 'children'>;
  const DropdownWithHandler = forwardRef<HTMLDivElement, ChildrenType>(
    ({ children }: ChildrenType, ref) => (
      <DropdownComponent ref={ref} createStreamsSections={createStreamsSections}>
        {children}
      </DropdownComponent>
    ),
  );

  return (
    <Stack spacing="md">
      <Text variant="md" fw="600" c="gray.9" miw="398px">
        Sélectionner l'emplacement du flux
      </Text>
      <Select
        miw="398px"
        radius="md"
        value={selectedSection ? selectedSection.value : 'noSection'}
        onChange={handleSectionChange}
        data={streamSectionItems}
        itemComponent={SelectItem}
        icon={
          <Box c="dark.9" mt={2}>
            {selectedSection ? selectedSection.icon : <IconCircleDashed size={14} stroke={2} />}
          </Box>
        }
        defaultValue={'noSection'}
        clearable={!selectedSection || selectedSection?.value === 'noSection' ? false : true}
        allowDeselect
        dropdownComponent={DropdownWithHandler}
        styles={{
          input: {
            paddingLeft: `36px`,
          },
        }}
      />
    </Stack>
  );
};

type DropdownComponentProps = {
  children: React.ReactNode;
  createStreamsSections: (title: string) => Promise<boolean>;
};

const DropdownComponent = forwardRef<HTMLDivElement, DropdownComponentProps>(
  ({ children, createStreamsSections }: DropdownComponentProps, ref) => {
    const openModal = () =>
      modals.openContextModal({
        modal: 'createStreamSection',
        innerProps: {
          onCreate: createStreamsSections,
        },
      });

    return (
      <Stack p="xxs" spacing={0} w="100%">
        <ScrollArea w="100%" ref={ref}>
          {children}
        </ScrollArea>
        <Divider color="dark.1" />
        <Group
          noWrap
          spacing="xxs"
          px="sm"
          py="xxs"
          c="primary.7"
          onClick={() => openModal()}
          sx={theme => ({ ':hover': { background: theme.colors.gray[0], cursor: 'pointer' } })}
        >
          <IconPlus size={16} />
          <Text variant="sm" fw={600}>
            Créer une nouvelle section
          </Text>
        </Group>
      </Stack>
    );
  },
);
