import type Buyer from '../../../entities/Buyer';
import type { BuyerContact } from '../../../entities/Buyer';
import { magellanClient } from '../../../infra/axios';
import type { SearchTenderDTO } from '../tender/dto';
import type { MinimalBuyerDTO } from './dto';

export async function getBuyersByName(buyerName: string): Promise<MinimalBuyerDTO[]> {
  const response = await magellanClient.get<MinimalBuyerDTO[]>(`/buyers?name=${buyerName}`);
  return response.data;
}

export async function getBuyerById(id: string): Promise<Buyer> {
  const response = await magellanClient.get<Buyer>(`/buyers/${id}`);
  return response.data;
}

export async function getTenderForBuyer(
  buyerId: number,
  skip: number,
  take: number,
  streamIds: string[],
): Promise<SearchTenderDTO> {
  const response = await magellanClient.post<SearchTenderDTO>(`/buyers/${buyerId}/tenders`, {
    streamIds: streamIds.map(id => parseInt(id, 10)),
    skip,
    take,
  });

  return response.data;
}

export async function getContactsForBuyer(buyerId: number): Promise<BuyerContact[]> {
  const response = await magellanClient.get<BuyerContact[]>(`/buyers/${buyerId}/contacts`);
  return response.data;
}

export async function deleteBuyerLogoURL(buyerId: number): Promise<void> {
  await magellanClient.put(`/buyers/${buyerId}/logo`);
}
