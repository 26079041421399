import { Stack, Text } from '@mantine/core';

import { Avatar } from '../../../../shared/components/UI/Avatar/Avatar';

import { useActiveUser } from '../../../../shared/contexts/FeatureFlagsAndActiveUser.provider';
import type { RichTextJson } from '../../../../shared/entities/Comment';
import type { Answer } from '../../../../shared/entities/InstantAnalysisAnswer';
import type { Thread } from '../../../../shared/entities/Thread';
import { CommentInput } from '../../../collaboration/components/comments/components/CommentInput';
import { ThreadComponent } from '../../../collaboration/components/comments/components/ThreadComponent';
import { useThreads } from '../../../collaboration/components/comments/hooks/useThreads.hook';
import { useNoticeContext } from '../../../notice/contexts/Notice.provider';

type IACommentsProps = {
  answer: Answer;
};

export function InstantAnalysisComments({ answer }: IACommentsProps) {
  return (
    <Stack spacing={!answer.thread ? 0 : '02'} w="860px">
      <Text variant="xs" fw={500} c="gray.4">
        Commentaires
      </Text>
      <IAThread thread={answer.thread} instantAnalysisAnswerId={answer.id} />
    </Stack>
  );
}

type IAThreadProps = {
  thread?: Thread | null;
  instantAnalysisAnswerId: number;
};

function IAThread({ thread, instantAnalysisAnswerId }: IAThreadProps) {
  const { activeUser } = useActiveUser();
  const { tender } = useNoticeContext();
  const { createThread } = useThreads(tender.id);

  if (!thread) {
    return (
      <CommentInput
        variant="subtle"
        avatar={
          <Avatar color={activeUser.avatarColor}>
            {activeUser.firstName[0].toUpperCase() + activeUser.lastName[0].toUpperCase()}
          </Avatar>
        }
        saveComment={(content: RichTextJson) => {
          createThread({ content, instantAnalysisAnswerId });
        }}
        placeholder={'Ajouter un commentaire'}
        showBorderOnFocus
      />
    );
  }
  return <ThreadComponent thread={thread} px="0" showBorderOnFocus />;
}
