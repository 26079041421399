import { useSuspenseQuery } from '@tanstack/react-query';

import { getProposalVersion } from '../../../../shared/api/magellan/bidResponses';
import { queryClient } from '../../../../shared/infra/queryClient';

export const useProposalVersion = (bidResponseId: number, proposalVersionId: number) => {
  const queryKey = [getProposalVersion.name, bidResponseId, proposalVersionId];
  const queryFn = async () => {
    const res = await getProposalVersion(bidResponseId, proposalVersionId);
    return res;
  };

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn,
    refetchInterval: query => {
      if (query.state.data?.extractionStatus === 'RUNNING') {
        return 5000;
      }
      return false;
    },
  });

  const refetchProposalVersion = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    proposalVersion: data,
    refetchProposalVersion,
  };
};
