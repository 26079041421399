import { createContext, useContext, useRef } from 'react';

type ScrollToElementContextType = {
  elementRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  scrollToElement: (index: number) => void;
};

const ScrollContext = createContext<ScrollToElementContextType | undefined>(undefined);

type ScrollToElementProviderProps = {
  children: React.ReactNode;
};

export const ScrollToElementProvider = ({ children }: ScrollToElementProviderProps) => {
  const elementRefs = useRef<(HTMLDivElement | null)[]>([]);

  const scrollToElement = (index: number) => {
    const offset = -300;
    const element = elementRefs.current[index];

    if (!element) {
      return;
    }
    const elementPosition = element?.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition + offset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
  };

  return (
    <ScrollContext.Provider value={{ elementRefs, scrollToElement }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScrollToElement = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScrollToElement must be used within a ScrollToElementProvider');
  }
  return context;
};
