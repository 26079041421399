import React from 'react';

import { Box, Divider, Group, Indicator, Stack, Text, useMantineTheme } from '@mantine/core';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import { Avatar } from '../../../../../shared/components/UI/Avatar/Avatar';

import type { DecisionLog } from '../../../../../shared/entities/DecisionLog';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import AnalyzeIndicator from '../assets/activity_indicator_analyze.svg?react';
import LostIndicator from '../assets/activity_indicator_lost.svg?react';
import CrossIndicator from '../assets/activity_indicator_nogo.svg?react';
import SentIndicator from '../assets/activity_indicator_sent.svg?react';
import WinIndicator from '../assets/activity_indicator_win.svg?react';

type ActivityProps = DecisionLog & { withOutDivider?: boolean };

export function Activity({
  user,
  decisionStatus,
  createdAt,
  reason,
  withOutDivider = false,
}: ActivityProps) {
  const formatedDate = dayjs(createdAt).fromNow();
  const decisionData = decisionTextConfigs[decisionStatus];
  const isGoNoGo = decisionStatus === DecisionStatus.GO || decisionStatus === DecisionStatus.NOGO;
  const joinText = isGoNoGo ? " a placé l'opportunité en " : " a marqué l'opportunité comme ";

  return (
    <Group noWrap spacing="03" align="flex-start">
      <Stack h="100%" spacing="02">
        <Indicator
          label={decisionData.indicator}
          styles={{
            indicator: {
              padding: 0,
              marginRight: '2px',
              marginTop: '22px',
              background: 'transparent',
            },
          }}
        >
          <Avatar color={user.avatarColor} isDisabled={!!user.disabledAt}>
            {user.firstName[0] + user.lastName[0]}
          </Avatar>
        </Indicator>
        {!withOutDivider && (
          <Box w="100%" sx={{ flexGrow: 1 }}>
            <Divider w={0} orientation="vertical" h="100%" color="gray.1" m="auto" />
          </Box>
        )}
      </Stack>
      <Stack spacing="02">
        <Text variant="sm" fw={400} c="gray.9">
          <Text
            span
            fw={500}
            c={user.disabledAt ? 'gray.5' : 'gray.9'}
            strikethrough={!!user.disabledAt}
          >{`${user.firstName} ${user.lastName}`}</Text>
          {joinText}
          <Text span fw={500} sx={{ whiteSpace: 'nowrap' }}>
            {decisionData.text}
          </Text>
        </Text>
        {reason && <ReasonCard reason={reason} decisionStatus={decisionStatus} />}
        <Text span variant="xs" fw={400} c="gray.4" sx={{ whiteSpace: 'nowrap' }} truncate>
          {formatedDate}
        </Text>
      </Stack>
    </Group>
  );
}

const ReasonCard = ({
  reason,
  decisionStatus,
}: {
  reason: string;
  decisionStatus: DecisionStatus;
}) => {
  const theme = useMantineTheme();
  const color = decisionStatus === DecisionStatus.WIN ? 'blue' : 'red';
  let reasonText;
  switch (decisionStatus) {
    case DecisionStatus.WIN:
      reasonText = 'Raison de la victoire';
      break;
    case DecisionStatus.LOSS:
      reasonText = "raison de l'échec";
      break;
    case DecisionStatus.NOGO:
      reasonText = 'Raison du No go';
      break;
    default:
      throw new Error(`Unsupported reason type: ${reason}`);
  }

  return (
    <Stack
      p="01"
      spacing={0}
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.xs,
      })}
    >
      <Text
        variant="xs"
        fw={500}
        c={theme.colors[color][6]}
        px="02"
        py="01"
        bg={theme.colors[color][0]}
        sx={theme => ({ borderRadius: theme.radius.sm })}
      >
        {reasonText}
      </Text>
      <Text variant="sm" fw={400} c="gray.9" p="02">
        {reason}
      </Text>
    </Stack>
  );
};

type decisionDataConfig = {
  text: string;
  indicator: React.ReactNode;
};

const decisionTextConfigs: { [key in DecisionStatus]: decisionDataConfig } = {
  [DecisionStatus.REJECTED]: { text: 'Rejetée', indicator: <CrossIndicator /> },
  [DecisionStatus.TO_ANALYZE]: { text: 'À analyser', indicator: <AnalyzeIndicator /> },
  [DecisionStatus.PENDING]: { text: 'En attente', indicator: null },
  [DecisionStatus.NOGO]: { text: 'No go', indicator: <CrossIndicator /> },
  [DecisionStatus.GO]: { text: 'Go', indicator: <AnalyzeIndicator /> },
  [DecisionStatus.ANSWERED]: { text: 'Déposée', indicator: <SentIndicator /> },
  [DecisionStatus.WIN]: { text: 'Gagnée', indicator: <WinIndicator /> },
  [DecisionStatus.LOSS]: { text: 'Perdue', indicator: <LostIndicator /> },
};
