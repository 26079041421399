import type { InstantAnalysisQuestion } from '../../../entities/InstantAnalysisQuestion';
import { magellanClient } from '../../../infra/axios';

export async function createQuestion(
  question: string,
  questionsSetId: number,
): Promise<InstantAnalysisQuestion> {
  const response = await magellanClient.post<InstantAnalysisQuestion>(`/instantAnalysisQuestions`, {
    question,
    questionsSetId,
  });

  return response.data;
}

export async function deleteQuestion(questionId: number): Promise<void> {
  await magellanClient.delete(`/instantAnalysisQuestions/${questionId}`);
}
