import { Box, Center, Group, Stack } from '@mantine/core';

import { IconLayoutSidebarRightCollapse } from '@tabler/icons-react';

import { NAVBAR_HEIGHT } from '../../../shared/components/UI/Navbar/Navbar';

import { SidePanelActionsProvider, useSidePanelActions } from '../contexts/PannelAction.provider';
import { Content } from './Content';
import { Header, type NoticeSidePanelTabValues } from './Header';

const NOTICE_SIDE_PANEL_WIDTH = 404;

type SidePanelProps = {
  oppened: boolean;
  activeTab: NoticeSidePanelTabValues | null;
  setActiveTab: (tab: NoticeSidePanelTabValues | null) => void;
};

export function SidePanel(props: SidePanelProps) {
  return (
    <SidePanelActionsProvider setActiveTab={props.setActiveTab}>
      <Group
        noWrap
        spacing={0}
        h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
        w={props.oppened ? '100vw' : 0}
        pos="fixed"
        top={NAVBAR_HEIGHT}
        right={0}
        align="flex-start"
        sx={{
          zIndex: 11,
          flexDirection: 'row-reverse',
        }}
      >
        <Panel {...props} />
        <Backdrop oppened={props.oppened} />
      </Group>
    </SidePanelActionsProvider>
  );
}

function Panel({ oppened, activeTab, setActiveTab }: SidePanelProps) {
  return (
    <Box
      pos="relative"
      w={oppened ? NOTICE_SIDE_PANEL_WIDTH : 0}
      h="100%"
      pt="02"
      sx={theme => ({
        borderLeft: `1px solid ${theme.colors.gray[1]}`,
        transition: 'width 0.5s',
        background: 'white',
      })}
    >
      <Stack h="100%" w={oppened ? NOTICE_SIDE_PANEL_WIDTH : 0} opacity={1} spacing="03">
        <Header activeTab={activeTab} setActiveTab={setActiveTab} oppened={oppened} />
        <Content activeTab={activeTab} />
      </Stack>
      <CloseSidePanelButton closeSidePanel={() => setActiveTab(null)} isOpenned={!!activeTab} />
    </Box>
  );
}

type BackdropProps = {
  oppened: boolean;
};

function Backdrop({ oppened }: BackdropProps) {
  const { closeSidePanel } = useSidePanelActions();

  return (
    <Box
      p="05"
      w="100%"
      h="100%"
      onClick={() => closeSidePanel()}
      sx={theme => ({
        visibility: oppened ? undefined : 'hidden',
        boxShadow: theme.shadows.md,
        background: theme.colors.gray[6],
        opacity: 0.16,
      })}
    />
  );
}

type CloseSidePanelButtonProps = {
  closeSidePanel: () => void;
  isOpenned: boolean;
};

function CloseSidePanelButton({ closeSidePanel, isOpenned }: CloseSidePanelButtonProps) {
  return (
    <Center
      c="gray.6"
      w="fit-content"
      p="01"
      pr="00"
      mt="02"
      pos="absolute"
      top={0}
      left={isOpenned ? -24 : 0}
      sx={theme => ({
        background: 'white',
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRight: `none`,
        borderRadius: theme.radius.md,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        zIndex: 10,
        ':hover': {
          cursor: 'pointer',
          background: theme.colors.gray[0],
        },
        transition: 'left 0.2s ',
      })}
      onClick={closeSidePanel}
    >
      <IconLayoutSidebarRightCollapse size={16} />
    </Center>
  );
}
