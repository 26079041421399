import { Center, Loader as MantineLoader, Stack, Text, Title } from '@mantine/core';

type CustomLoader = {
  title?: string;
  subtitle?: string;
  mt?: number;
};

export function Loader({ title, subtitle, mt }: CustomLoader) {
  return (
    <Center h="100%" mt={mt}>
      <Stack>
        <MantineLoader size="xl" m="auto" />
        <Title order={3} align="center">
          {title}
        </Title>
        <Text variant="md" c="dark.9" align="center" maw="480px">
          {subtitle}
        </Text>
      </Stack>
    </Center>
  );
}
