import { Stack, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';

import { IconTrash } from '@tabler/icons-react';

import { Button } from '../../../../../shared/components/UI/Button/Button';

type DeleteSectionProps = {
  onDelete: () => void;
};
export const DeleteSection = ({ onDelete }: DeleteSectionProps) => {
  const openModal = () =>
    modals.openContextModal({
      modal: 'streamDelete',
      innerProps: { onDelete },
    });

  return (
    <Stack>
      <Title order={4}>Suppression du flux</Title>
      <Text variant="sm">
        Attention, une fois votre flux supprimé, vous ne pourrez plus revenir en arrière.
      </Text>
      <Button
        onClick={openModal}
        leftIcon={<IconTrash size={18} />}
        maw="25%"
        miw="200px"
        size="md"
        color="red"
      >
        Supprimer le flux
      </Button>
    </Stack>
  );
};
