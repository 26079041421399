import { Group, Skeleton, Text } from '@mantine/core';

import { useQuery } from '@tanstack/react-query';

import { getAssessmentsRecap } from '../../../../shared/api/magellan/instantAnalysis/answers';
import { AssessmentStatus } from '../../../../shared/entities/AssessmentStatus';
import { AssessmentBadge } from '../../../instantAnalysis/components/AssessmentBadge';

type BlockingPointsProps = {
  tenderId: number;
};

export function BlockingPoints({ tenderId }: BlockingPointsProps) {
  const { isPending, isError, data } = useQuery({
    queryKey: [getAssessmentsRecap.name, tenderId],
    queryFn: () => getAssessmentsRecap(tenderId),
  });

  if (isPending) {
    return <Skeleton h={20} w={120} />;
  }

  if (isError) {
    // non critical error we don't want to block the user
    return;
  }
  const blockingPoint = data.assessmentRecap.blockingPoint;
  return (
    <Group noWrap spacing="02" w="140px">
      <AssessmentBadge assessment={AssessmentStatus.BLOCKING_POINT} isSelected />
      <Text variant="sm" fw="500" c="dark.9">
        {blockingPoint} {blockingPoint > 1 ? 'points' : 'point'} bloquant
      </Text>
    </Group>
  );
}
