import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import type { SessionContextType } from 'supertokens-auth-react/recipe/session';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { getActiveUser } from '../api/magellan/users';
import type { FeatureFlag, FeatureFlagsType } from '../entities/FeatureFlags';
import type { User } from '../entities/User';

type FeatureFlagsContextType = {
  isFeatureEnabled: (feature: FeatureFlag) => boolean;
};

type ActiveUserContextType = {
  activeUser?: User;
  session: SessionContextType;
  ignoreInReporting: boolean;
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

const ActiveUserContext = createContext<ActiveUserContextType | null>(null);

export default function FeatureFlagsAndActiveUserProvider({ children }: { children: ReactNode }) {
  const session = useSessionContext();

  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsType | null>(null);
  const [activeUser, setActiveUser] = useState<User>();
  const [ignoreInReporting, setIgnoreInReporting] = useState<boolean>(false);
  const [isSessionActive, setIsSessionActive] = useState(false);

  const isFeatureEnabled = (feature: FeatureFlag) => {
    return featureFlags?.[feature] ?? false;
  };

  useEffect(() => {
    setIsSessionActive(!session.loading && session.doesSessionExist);
  }, [session]);

  const { data, isError, isLoading } = useQuery({
    queryKey: [getActiveUser.name],
    queryFn: getActiveUser,
    enabled: isSessionActive,
  });

  useEffect(() => {
    if (data?.featureFlags) {
      setFeatureFlags(data.featureFlags);
    }
    if (data?.user) {
      setActiveUser(data.user);
    }
    if (data?.ignoreInReporting) {
      setIgnoreInReporting(data.ignoreInReporting);
    }
  }, [data?.featureFlags, data?.ignoreInReporting, data?.user, isSessionActive]);

  if (isError || isLoading || session.loading) {
    return null;
  }

  return (
    <ActiveUserContext.Provider value={{ activeUser, session, ignoreInReporting }}>
      <FeatureFlagsContext.Provider value={{ isFeatureEnabled }}>
        {children}
      </FeatureFlagsContext.Provider>
    </ActiveUserContext.Provider>
  );
}

export const useFeatureFlags = (feature: FeatureFlag) => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlagContext must be used within a FeatureFlagsProvider');
  }
  return context.isFeatureEnabled(feature);
};

export const useActiveUser = (): { activeUser: User; ignoreInReporting: boolean } => {
  const context = useContext(ActiveUserContext);

  if (!context) {
    throw new Error('useActiveUserContext must be used within a ActiveUserProvider');
  }
  if (!context.activeUser) {
    const error = new Error(`No active user found`);
    Sentry.withScope(scope => {
      scope.setExtra('session', context.session);
      scope.setExtra('activeUser', context.activeUser);
      Sentry.captureException(error);
    });
    throw error;
  }

  return {
    activeUser: context.activeUser,
    ignoreInReporting: context.ignoreInReporting,
  };
};
